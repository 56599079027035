import { AccountInfo, PublicClientApplication, AuthenticationResult, RedirectRequest, SilentRequest } from '@azure/msal-browser';
import { head } from 'lodash';
import { REDIRECT_URL , TENANT , INSTANCE, SIGN_IN_POLICY , RESET_PASSWORD_POLICY, CLIENT_ID } from '../urls';

interface MsalConfiguration {
  auth: {
    clientId: string;
    redirectUri: string;
    authority: string;
    knownAuthorities: string[];
    postLogoutRedirectUri: string;
    validateAuthority?: boolean;
  };
  cache: {
    storeAuthStateInCookie: boolean;
    cacheLocation?: 'localStorage' | 'sessionStorage' | undefined;
  };
}

const shouldStoreAuthInCoockie = (): boolean => {
  // Browser check variables
  // If you support IE, our recommendation is that you sign-in using Redirect APIs
  // If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
  const ua: string = window.navigator.userAgent;
  const msie: number = ua.indexOf('MSIE ');
  const msie11: number = ua.indexOf('Trident/');
  const msedge: number = ua.indexOf('Edge/');
  const firefox: number = ua.indexOf('Firefox');
  const isIE: boolean = msie > 0 || msie11 > 0;
  const isEdge: boolean = msedge > 0;
  const isFirefox: boolean = firefox > 0; // Only needed if you need to support the redirect flow in Firefox incognito

  return isIE || isEdge || isFirefox;
};

const instance: string = `https://${INSTANCE}`;
const tenant: string = TENANT;
export const signInPolicy: string = SIGN_IN_POLICY;
export const resetPasswordPolicy: string = RESET_PASSWORD_POLICY;
const clientId: string = CLIENT_ID;


// Config object to be passed to Msal on creation
export const msalConfig: MsalConfiguration = {
  auth: {
    clientId,
    redirectUri: REDIRECT_URL,
    authority: `${instance}/${tenant}/${signInPolicy}`,
    knownAuthorities: [INSTANCE],
    postLogoutRedirectUri: '/'
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: shouldStoreAuthInCoockie()
  }
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest: { scopes: string[] } = {
  scopes: [`https://${tenant}/lueg/read`]
};

export const forgotPasswordRequest: { authority: string } = {
  authority: `${instance}/${tenant}/${resetPasswordPolicy}`
};

export const msalInstance: PublicClientApplication = new PublicClientApplication(msalConfig);

class AuthService {
  private getAccount(): AccountInfo | null {
    let account: AccountInfo | null = msalInstance.getActiveAccount();
    if (!account) {
      const accounts: AccountInfo[] = msalInstance.getAllAccounts();
      const isExist = head(accounts);
      if (isExist === undefined) {
        account = null;
      }
    }

    return account;
  }

  public async loginRedirect(): Promise<void> {
    let account: AccountInfo | null = this.getAccount();
    let isAccountUndefined = account === null ? undefined : account;

    const request: RedirectRequest | SilentRequest = { account: isAccountUndefined, scopes: [...loginRequest.scopes] };
    if (!account) {
      await msalInstance.acquireTokenRedirect(request);
    }
  }

  public async getAuthHeader(): Promise<string> {
    const token = await this.acquireTokenAsync();
    return `Bearer ${token}`;
  }

  public async acquireTokenAsync(): Promise<string> {
    let account: AccountInfo | null = this.getAccount();
    let isAccountUndefined = account === null ? undefined : account;

    const request: RedirectRequest | SilentRequest = { account: isAccountUndefined, scopes: [...loginRequest.scopes] };
    if (!account) {
      await msalInstance.acquireTokenRedirect(request);
    }

    try{
      const token: AuthenticationResult = await msalInstance.acquireTokenSilent(request);
      return token.idToken as string;
    } catch(err){
      await msalInstance.acquireTokenRedirect(request);
    }

    return '';
  }
}

const authService = new AuthService();

export default authService;
