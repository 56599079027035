import { combineReducers } from 'redux';

import appReducer from './app-reducer';
import profileReducer from './profile-reducer';
import editReducer from './edit-reducer';
import locationsReducer from './locations-reducer';

const rootReducer = combineReducers({
  app: appReducer,
  profile: profileReducer,
  edit: editReducer,
  calendar:locationsReducer
});

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
