export class Pagination {
  public start: number;
  public number: number;
  public totalItemCount: number;
  public numberOfPages: number;

  constructor(
    start: number = 1,
    number: number = 10,
    totalItemCount: number = 0,
    numberOfPages: number = 0
  ) {
    this.start = start;
    this.number = number;
    this.totalItemCount = totalItemCount;
    this.numberOfPages = numberOfPages;
  }
}

export class Sort {
  public predicate?: string;
  public reverse?: boolean;
  constructor(predicate?: string, reverse?: boolean) {
    this.predicate = predicate;
    this.reverse = reverse;
  }
}

type PredicateObject = { [key: string]: any };

export class Search {
  public predicateObject: PredicateObject;
  constructor(obj: PredicateObject) {
    this.predicateObject = obj;
  }
}

export class SmartTableParam {
  public pagination: Pagination;
  public search?: Search;
  public sort?: Sort;
  constructor(pagination: Pagination, search?: Search, sort?: Sort) {
    this.pagination = pagination;
    this.search = search; // {key:"value",key2:"value2"}
    this.sort = sort;
  }
}

export interface SmartTableResult<T> {
  items: T[];
  totalRecord: number;
  numberOfPages: number;
}

export function getDefaultSmartTableResult<T>(): SmartTableResult<T> {
  return {
    items: [],
    totalRecord: 0,
    numberOfPages: 0,
  };
}
