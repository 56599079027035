import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  fullWidth: {
    width: '100%'
  },
  container: {
    height: '100%',
    overflow: 'auto',
    backgroundColor: '#f3f2f1',
  },
  formContainer: {
    flexGrow:1,
    height:'fit-content',
  },
  formWrapper:{
    display:'flex',
    margin: '6px 32px 6px 32px',
  },
  documents_container:{
    display:'flex',
    justifyContent:'flex-start',
    alignItems:'flex-start',
    flexGrow:5,
    marginTop: 10,
    marginLeft:10
  },
  titleBar: {
    backgroundColor: 'f5f5f5',
    borderBottomColor: '#ebebeb',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',
    height: '60px',
    minHeight: '60px',
    padding: '0 32px',
    borderBottomWidth: '1px',
    borderBottomStyle: 'solid',
  },
  subject: {
    color: '#242424',
    fontWeight: 'bold',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    padding: 0,
    marginLeft: '12px',
    marginRight: '4px',
    background: 'transparent',
    boxShadow: 'none',
    border: 'none',
    whiteSpace: 'nowrap',
    cursor: 'text',
    height: '32px',
    lineHeight: '32px',
    fontSize: '18px',
    textAlign: 'left',
    minWidth: '20px',
    maxWidth: 'calc(100% - 40px)',
  },
  appSvg: {
    height: '32px',
    width: '32px',
  },
  calendarIcon: {
    backgroundColor: '#6264a7',
    width: '32px',
    height: '32px',
    borderRadius: '3px',
  },
  bridge: {
    flex: '1 1 auto',
  },
  dateIcon: {
    '&>g': {
      stroke: 'black',
      fill: 'transperent'
    }
  },
  saveBtn: {
    marginRight: '8px',
  },
  locationRow: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  locationSelectContainer: {
    width: '100%',
  },
  auditorTypeSelectContainer: {
    marginTop: '10px',
  }
});

export default useStyles;
