import { FunctionComponent, ReactElement, useEffect, useState } from "react";
import { TextField, Grid } from "@material-ui/core";
import { noop } from "lodash";
import orderService from "../../services/order-service";

interface AvailableFromProps {
  id: string;
}

interface State {
  info: string;
  loading: boolean;
}

const AvailableFrom: FunctionComponent<AvailableFromProps> = (
  props
): ReactElement | null => {
  const { id } = props;
  const [state, setState] = useState<State>({ info: '', loading: true });


  useEffect(() => {
    (async () => {
      const response = await orderService.getAvailabilityInformation(id);
      setState({
        loading: false,
        info: response || ''
      });
    })();
  }, [id, setState]);

  if(!state.loading && !state.info){
    return null;
  }

  return (
    <TextField
      label="Verfügbar ab"
      fullWidth
      value={state.info}
      onChange={noop}
    />
  );
};

export default AvailableFrom;
