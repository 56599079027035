export const HIDE_BACKDROP = 'HIDE_BACKDROP';
export const hideBackdrop = () => ({
  type: HIDE_BACKDROP
});

export const SHOW_BACKDROP = 'SHOW_BACKDROP';
export const showBackdrop = (message: string) => ({
  type: SHOW_BACKDROP,
  payload: message
});
