import { FunctionComponent, ReactElement, useState , ChangeEvent} from "react";
import { Typography, Button, Paper, Box, FormControl, InputLabel, Select, MenuItem, CircularProgress } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Check from '@material-ui/icons/CheckCircleOutline';
import SaveIcon from '@material-ui/icons/Save';
import { makeStyles } from '@material-ui/core/styles';
import * as Labels from '../../unit/labels';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Calendar from '@material-ui/icons/DateRange';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import {Points} from '../../services/order-service';
import OrderService from '../../services/order-service';
import { format, getHours, getMinutes, getSeconds } from "date-fns";
import deLocale from "date-fns/locale/de";
import { useErrorPage } from "../../hooks";
import {RepairType , Statuses} from '../../unit/constants';


const useStyles = makeStyles((theme) => ({
    paper:{
      display: 'flex', 
      flexDirection: 'column', 
      height: 210, 
      alignItems:'center'
    },
    box_1:{
      display: 'flex', 
      justifyContent: 'space-between', 
      height:50, 
      width: '100%'
    },
    picker:{
      width: 300
    },
    select:{
      width: 300, 
      marginTop: 10
    },
    box_2:{
      flexGrow:1, 
      display: 'flex', 
      justifyContent: 'center', 
      alignItems:'center'
    }
  }));

interface CarJockeyFormProps{
    formCloseHandler:()=> void,
    targetPoints: Points[],
    status: string,
    type:string,
    orderId: string,
    vehicleId: string
}

const setTimeBeforehand = ():Date=>{
  const halfAnHour = 30 * 60 * 1000;
  const now = Date.now();
  return new Date(now + halfAnHour);
}


const getDestinationTarget = (type:number, points:Points[], status:string):string=>{
  return RepairType.Werkstatt === type ? 
  ( (status === Statuses.PlanningIsPending || status === Statuses.IsPlanned) ? 
      points.find( target => target.value === 528970012)?.text! :
    (status === Statuses.InProcess || status === Statuses.Done) ? 
      points.find( target => target.value === 528970004)?.text! : ''
  ) :
     RepairType.Twinner === type ? 
  ( (status === Statuses.PlanningIsPending || status === Statuses.IsPlanned) ?
      points.find( target => target.value === 528970011)?.text! : ''
  ) :
     RepairType.Aufbereitung === type ?
  (
    (status === Statuses.PlanningIsPending || status === Statuses.IsPlanned) ?
      points.find( target => target.value === 528970001)?.text! : ''
  ) :
    RepairType.SmartRepair === type ? 
  (
    (status === Statuses.PlanningIsPending || status === Statuses.IsPlanned) ?
      points.find( target => target.value === 528970009)?.text! : ''
  ) : '';
    
}


export const CarJockeyForm:FunctionComponent<CarJockeyFormProps> = ({formCloseHandler, targetPoints, status, type, orderId, vehicleId}):ReactElement=>{
    const [selectedDate, handleDateChange] = useState<MaterialUiPickersDate>(setTimeBeforehand());
    const [minDateTime] = useState<Date>(setTimeBeforehand());
    const [open, setOpen] = useState<boolean>(false);
    const [selectedPoint, setSelectedPoint] = useState<string>(getDestinationTarget(+type, targetPoints, status));
    const [loading , setLoading] = useState<boolean>(false);
    const [success , setSuccess] = useState<boolean>(false);
    const navigateToErrorPage = useErrorPage();
    const classes = useStyles();
    
    const handleClose = ()=>{
        formCloseHandler()
    }

    const adjustTime = (value: Date | null, keyboardInputValue?: string | undefined):void=>{
      if(value){
        handleDateChange(value)
      }   
    }

    const closeDropdown = ():void=>{
        setOpen(false)
    }

    const openDropdown = ():void=>{
        setOpen(true)
    }

    const handleChange = (event:ChangeEvent<{name?:string | undefined, value: unknown }>):void=>{
        setSelectedPoint(event.target.value as string)
    }

    const transformDate = (date:Date)=>{
        const addZero = (number:string):string=>{
          if(number.length === 1){
             return '0' + number
          }
          return number
        }
        
        return `${format(date, "yyyy-MM-dd", { locale: deLocale })}T${addZero(String(getHours(date)))}:${addZero(String(getMinutes(date)))}:${addZero(String(getSeconds(date)))}`
    }

    const callback = ():Promise<unknown>=>{
        return new Promise((resolve:(value: unknown) =>void, reject:(reason?:any)=>void)=> resolve(setTimeout(()=>{ setSuccess(false) }, 3000)))
    }


    const missed = selectedPoint === Labels.Select || selectedPoint === ''

    const handleCarJockey = async()=>{
        if(missed){
         return
        }

        try{
        setLoading(true)
        await OrderService.createCarJockey({
           zielpinkt: targetPoints.filter(target => target.text === selectedPoint)[0].value,
           planedDateTime: transformDate(new Date(selectedDate!)),
           vehicleId,
           orderId
         })
         setSuccess(true)
         setLoading(false)
         callback()
        }catch(err:unknown){
          console.log(err)
          setLoading(false)
          navigateToErrorPage((err as Error).message, `/auftrage-an/${orderId}`)
        }
    }
    
    const ifConditionsEqual = loading || success;
    return(
           
        <Paper className={classes.paper}>
            <Box className={classes.box_1}>
                <Button disabled={loading} color="secondary" onClick={handleClose}>
                    <CloseIcon color="secondary" style={{marginRight: '5px'}}/>
                    <Typography variant="subtitle1">{Labels.Close}</Typography>
                </Button>
                <Button disabled={loading} color="primary" onClick={handleCarJockey}>
                    <Typography style={{marginRight: '5px'}} variant="subtitle1">{Labels.Create}</Typography>
                    <SaveIcon />
                </Button>
            </Box>
            {ifConditionsEqual && (
                <Box className={classes.box_2}>
                     {
                       loading && !success ? <CircularProgress size={50}/> : <Check style={{color: 'green', fontSize: 50}} />
                     }  
                </Box>
             )
            }
            {
              !ifConditionsEqual && (
                <>
                <div className={classes.picker}>
                <LocalizationProvider dateAdapter={AdapterDateFns} >
                   <MobileDateTimePicker
                     value={selectedDate}
                     onChange={adjustTime}
                     renderInput={(props:any) => <TextField 
                      {...props} 
                      InputProps={{
                        endAdornment: <InputAdornment position="end"><Calendar/></InputAdornment>,
                      }}/>
                     }
                     ampm={false}
                     label="Fälligkeit"
                     disablePast
                     onError={console.log}
                     inputFormat='dd/MM/yyyy HH:mm'
                     minDateTime={minDateTime}
                    />
                    </LocalizationProvider>
               </div>
               <FormControl className={classes.select}>
                <InputLabel id="points-label">Zielpunkt</InputLabel>
                    <Select
                        labelId="points-label"
                        id="points"
                        open={open}
                        onClose={closeDropdown}
                        onOpen={openDropdown}
                        value={selectedPoint}
                        onChange={handleChange}
                    >
                    {
                        targetPoints.map( point=> 
                         <MenuItem key={point.value} value={point.text}>{point.text}</MenuItem>   
                        )
                    }
                    </Select>
                    {missed && <Typography color="error" variant="subtitle2">Dieses Feld wird benötigt</Typography>}
               </FormControl>
               </>
              )
            }
        </Paper>
    )
}