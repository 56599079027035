import { Action } from '../types';

export const PROFILE_REQUESTED = 'PROFILE_REQUESTED';
export const PROFILE_FETCH_SUCCEEDED = 'PROFILE_FETCH_SUCCEEDED';
export const PROFILE_FETCH_FAILURE = 'PROFILE_FETCH_FAILURE';

export const SET_PROFILE_LOADING = 'SET_PROFILE_LOADING';

export const setProfileLoading = (loading: boolean): Action => ({
  type: SET_PROFILE_LOADING,
  payload: {
    loading
  }
});

export const fetchProfile = (): Action => ({
  type: PROFILE_REQUESTED
});

export const fetchProfileSucceeded = (roles: number[], logo: string = '', name:string, userId:string): Action => ({
  type: PROFILE_FETCH_SUCCEEDED,
  payload: {
    roles,
    logo,
    name,
    userId
  }
});
