import { AnyAction } from 'redux';
import { HIDE_BACKDROP, SHOW_BACKDROP } from '../actions/app';

interface InitialState {
  redirectTo: string;
  backdrop: {
    open: boolean;
    message: string;
  };
  exception: {
    hasError: boolean;
    message: '';
  };
}

const INITIAL_STATE: InitialState = {
  redirectTo: '',
  backdrop: {
    open: false,
    message: ''
  },
  exception: {
    hasError: false,
    message: ''
  }
};

// TODO: add types for action
const appReducer = (state: InitialState = INITIAL_STATE, action: AnyAction): InitialState => {
  switch (action.type) {
    case SHOW_BACKDROP:
      return { ...state, backdrop: { open: true, message: action.payload } };
    case HIDE_BACKDROP:
      return { ...state, backdrop: { open: false, message: '' } };
    default:
      return state;
  }
};

export default appReducer;
