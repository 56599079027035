import {
  LinearProgress,
  makeStyles,
  Divider,
  Tabs,
  Tab,
  Typography,
  Button,
} from "@material-ui/core";
import { FunctionComponent, ReactElement, useState, useEffect } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import orderService, { OrderDetails, Points } from "../../services/order-service";
import VehicleDamagesTable from "./VehicleDamagesTable";
import OrderForm from "./OrderForm";
import Documents from "./Documents";
import TabPanel from "./TabPanel";
import {getErrorMessage} from '../../unit/utils';
import CarJockey from './CarJockeyButton';
import StartOrderWorkButton from "./StartOrderWorkButton";
import FinishOrderWorkButton from "./FinishOrderWorkButton";
import * as labels from "../../unit/labels";
import { useErrorPage } from "../../hooks";
import CreateDamage from "./CreateDamage";
import FormContainer from "../../components/FormContainer";
import * as Labels from '../../unit/labels';
import {RepairType , Statuses} from '../../unit/constants';
import {CarJockeyForm} from './CarJockeyForm';
import { DetermineConnectionType } from "../../components/DetermineConnectionType";
import { AzureMapsProvider } from "react-azure-maps";

const useStyles = makeStyles({
  root: {
    padding: "20px 20px 40px 20px",
  },
  toolbar: {
    paddingLeft: 0,
    paddingRight: 0,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",

    "@media (min-width:600px)": {
      flexDirection: "row",
    },
  },
  btnContainer: {
    "&>*": {
      marginLeft: "5px",
    },
  }
});

interface RouteParams {
  id: string;
}

interface State {
  loading: boolean;
  order: OrderDetails | null;
}

const Order: FunctionComponent = (): ReactElement => {
  const { id } = useParams<RouteParams>();
  const { search } = useLocation();
  const classes = useStyles();
  const history = useHistory();
  const [tabIndex, setTabIndex] = useState(0);
  const [carJockeyVisible, setCarJockeyVisisble] = useState<boolean>(false);
  const [points , setPoints] = useState<Points[]>([]);
  const navigateToErrorPage = useErrorPage();

  const [state, setState] = useState<State>({
    loading: true,
    order: null,
  });

  const tabIndexChangeHanlder = (
    event: React.ChangeEvent<{}>,
    newValue: number
  ): void => {
    setTabIndex(newValue);
  };

  const updateOrder = (order: OrderDetails): void => {
    setState({
      loading: false,
      order,
    });
  };

  const getParam = (search: string):string | null=>{
    return new URLSearchParams(search).get("ext");
  }

  const convertFromBase64 = (base64: string): string => {
    const decode = window.atob(base64);
    return decode;
  };

  useEffect(() => {
    const load = async (): Promise<void> => {
      try {
        const qrCodeParams = getParam(search);
        if(qrCodeParams){
          const isApproved = await orderService.approveQrCodeValue(qrCodeParams);
          if(!isApproved){
            throw new Error(labels.QrCodeError);
          }
        }
        const response = await orderService.getOrderByIdAsync(!!qrCodeParams ? convertFromBase64(qrCodeParams) : id);
        const points = await orderService.getTargetPoints();
        setPoints(points);
        setState({
          loading: false,
          order: response,
        });
      } catch (err:any) {
        console.log(err);
        navigateToErrorPage(getErrorMessage(err) , `/auftrage-an/${id}`, err?.response?.status);
      }
    };
    load();
  }, [setState, id, navigateToErrorPage, search]);


  const errorHandler = (message:string)=>{
    navigateToErrorPage( message , `/auftrage-an/${id}`);
  }

  const estimateOrderHandler = async (date: Date): Promise<void> => {
    try {
      const order = await orderService.estimateOrder({ id, date });
      updateOrder(order);
    } catch (error:any) {
      navigateToErrorPage(getErrorMessage(error) , `/auftrage-an/${id}`);
    }
  };

  if (state.loading) {
    return <LinearProgress />;
  }

  const vehicleId = state.order?.vehicleId;
  const library = state.order?.number;

  const estimated = !!state.order?.estimatedDate;
  const started = !!state.order?.startDate;
  const ended = !!state.order?.endDate;
  const disabled = state.order?.status === Statuses.Done || state.order?.isRejected;
  const type = state.order?.type ?? '0';

  return (
    <FormContainer
      title={state.order?.number}
      rightElement={
        <div className={classes.btnContainer}>
          { RepairType.Lackierung !== +type && !carJockeyVisible && (
             <CarJockey clickHandler={()=> setCarJockeyVisisble(true)}/>
          )}
          {!disabled && !carJockeyVisible  && (
            <>
              <StartOrderWorkButton
                id={id}
                visible={estimated && !started}
                onStart={updateOrder}
              />
              <FinishOrderWorkButton
                id={id}
                visible={started && !ended}
                onFinish={updateOrder}
              />
              <Button
                variant="contained"
                onClick={(): void => history.push(`/auftrage-an/?type=${state.order?.type}`)}
              >
                {Labels.Back}
              </Button>
            </>
          )}
        </div>
      }
    >
      {
      carJockeyVisible ?
       (<CarJockeyForm
          status={state.order?.status ?? ''}
          type={state.order?.type ?? ''}
          targetPoints={points}
          formCloseHandler={()=>setCarJockeyVisisble(false)}
          orderId={id}
          vehicleId={vehicleId!}
        />)
                      :
       <>
        <Tabs
          value={tabIndex}
          onChange={tabIndexChangeHanlder}
          indicatorColor="primary"
          textColor="primary"
          scrollButtons="on"
          variant="scrollable"
        >
          <Tab label="Allgemein" />
          <Tab label="Dokumente" />
          {vehicleId && <Tab label="Alle Fahrzeug Schäden" />}
          <Tab label='Map'/>
        </Tabs>
        <TabPanel value={tabIndex} index={0}>
          <OrderForm
            {...state.order!}
            onEstimatedDateChange={estimateOrderHandler}
          />
          {vehicleId && state.order?.type !== "528970005" && (
            <>
              <Divider style={{ margin: "50px" }} />
              <Typography variant="h6" component="div">
                {labels.Damage}
              </Typography>
              {<VehicleDamagesTable orderId={id} vin={state.order?.vin!} />}
            </>
          )}
        </TabPanel>
        <TabPanel value={tabIndex} index={1}>
          {library && (
            <Documents entityId={state.order!.id} entityName="lueg_auftrag_an" />
          )}
        </TabPanel>
        <TabPanel value={tabIndex} index={2}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
            }}
          >
            <CreateDamage vehicleId={vehicleId!} vin={state.order!.vin!} />
          </div>
          {vehicleId && (
            <VehicleDamagesTable vehicleId={vehicleId!} vin={state.order?.vin!} />
          )}
        </TabPanel>
        <TabPanel value={tabIndex} index={3}>
          <AzureMapsProvider>
            <DetermineConnectionType
             errorObserver={errorHandler}
             vehicleId={vehicleId ?? null}
             recordRoute={`/auftrage-an/${id}`}
            />
          </AzureMapsProvider>
        </TabPanel>
       </>
    }
    </FormContainer>
  );
};

export default Order;
