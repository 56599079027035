import { Component, PropsWithChildren } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../reducers';

interface ErrorBoundaryState {
  hasError: boolean;
}

interface ErrorBoundaryProps {
  hasError: boolean;
  message: string;
}

const mapStateToProps = (state: RootState): ErrorBoundaryProps => ({
  hasError: state.app.exception.hasError,
  message: state.app.exception.message
});

const connector = connect(mapStateToProps, {});

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsWithChildren<PropsFromRedux> 

class ErrorBoudndary extends Component<Props, ErrorBoundaryState> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
  }

  render() {
    const hasError = this.state.hasError || this.props.hasError;
  
    if (hasError) {
      // You can render any custom fallback UI
      return <h1>Something went wrong.</h1>;
    }

    return this.props.children;
  }
}

export default connector(ErrorBoudndary);
