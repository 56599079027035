import { FunctionComponent, ReactElement } from 'react';
import { makeStyles, Select, SelectProps } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  select: {
    background: '#ffffff !important',
    cursor: 'pointer',
    padding: '2px 12px 2px',

    '& div:focus': {
      background: '#ffffff !important'
    },

    '&&&:before': {
      borderBottom: 'none'
    },
    '&&:after': {
      borderBottom: 'none'
    },
    '&::before': {
      border: 'none !important'
    },

    '&:hover::before': {
      border: 'none !important'
    },

    '&:hover::after': {
      border: 'none !important'
    },

    '& input': {
      fontSize: '14px',
      cursor: 'pointer'
    }
  }
}));

const SelectStyled: FunctionComponent<SelectProps> = (props): ReactElement => {
  const classes = useStyles();
  return (
    <Select {...props} className={classes.select}>
      {props.children}
    </Select>
  );
};

export default SelectStyled;
