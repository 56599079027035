import { FunctionComponent, ReactElement } from 'react';
import { Select, makeStyles, SelectProps } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  select: {
    width: '150px',
    marginRight: '10px',
    marginLeft: '10px',
    color: 'rgba(37, 36, 35, 0.75)',
    fontSize: '14px',
    cursor: 'pointer',
    fontStyle: 'normal',

    '&&&:before': {
      borderBottom: 'none'
    },
    '&&:after': {
      borderBottom: 'none'
    },
    '&::before': {
      border: 'none !important'
    },

    '&:hover::before': {
      border: 'none !important'
    },

    '&:hover::after': {
      border: 'none !important'
    },

    '& input': {
      color: 'rgba(37, 36, 35, 0.75)',
      fontSize: '14px',
      cursor: 'pointer'
    }
  }
}));

const LocationSelect: FunctionComponent<SelectProps> = (props): ReactElement => {
  const classes = useStyles();
  return (
    <Select className={classes.select} {...props}>
      {props.children}
    </Select>
  );
};

export default LocationSelect;
