import { FunctionComponent, ReactElement } from 'react';
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { intersection } from 'lodash';

export interface DrawerListItemLink {
  label: string;
  url: string;
  icon: JSX.Element;
  authenticated: boolean;
  requiredRoles: number[];
}

interface DrawerListItemProps {
  isAuthenticated: boolean;
  userRoles: number[];
  link: DrawerListItemLink;
}

const DrawerListItem: FunctionComponent<DrawerListItemProps> = (props): ReactElement | null => {
  const { isAuthenticated, link, userRoles } = props;

  if (link.authenticated && !isAuthenticated) {
    return null;
  }

  if (link.authenticated && link.requiredRoles && link.requiredRoles.length && intersection(link.requiredRoles, userRoles).length === 0) {
    return null;
  }

  return (
    <ListItem button component={Link} to={link.url}>
      <ListItemIcon>{link.icon}</ListItemIcon>
      <ListItemText primary={link.label} />
    </ListItem>
  );
};

export default DrawerListItem;
