import { useState , FunctionComponent, ReactElement } from "react";
import { makeStyles, Button, Drawer, Theme } from "@material-ui/core";
import { createStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import {RampCreationForm} from './ramp-creation-form';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      width: "100%",
      flexShrink: 0,
    },
    drawerPaper: {
      padding: theme.spacing(3),
      width: "100%",
      maxWidth: "500px",
    },
  })
);

interface CreateRampProps{
  onRefetchHandler():Promise<void>;
  id:string;
}

const CreateRamp:FunctionComponent<CreateRampProps> = ({id, onRefetchHandler}):ReactElement=>{
    const [open, setOpen] = useState<boolean>(false);
    const classes = useStyles();

    const openHandler = (): void => {
      setOpen(true);
    };

    const closeHandler = (): void => {
      setOpen(false);
    };

    return (
      <>
        <Button startIcon={<AddIcon />} onClick={openHandler}>
          Create ramp
        </Button>
        <Drawer
          className={classes.drawer}
          anchor="right"
          variant={"temporary"}
          open={open}
          onClose={closeHandler}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <RampCreationForm onSoftRefetch={onRefetchHandler} onCloseHandler={closeHandler} LocationId={id}/>
        </Drawer>
      </>
    );
}

export default CreateRamp;