import {FunctionComponent, ReactElement} from 'react';
import {Dialog , DialogActions, DialogContent, DialogContentText, DialogTitle, Button, Typography , makeStyles} from '@material-ui/core';
import * as Labels from '../unit/labels';

interface ApproveDialogProps{
    isOpen: boolean,
    disAgreeHandler: ()=> void,
    agreeHandler: ()=>void
}

const useStyles = makeStyles({
   title:{
     color: '#171414',
     fontWeight:500
   },
   message:{
     color: '#171414'
   }
});

export const ApproveDialog: FunctionComponent<ApproveDialogProps> = (props):ReactElement=>{
     const {isOpen, disAgreeHandler, agreeHandler} = props;
     const classes = useStyles();

    return (
        <Dialog
            open={isOpen}
            aria-labelledby="approve-dialog-title"
            aria-describedby="approve-dialog-description"
        >
        <DialogTitle id="approve-dialog-title">
             <Typography className={classes.title}>{Labels.Attention}</Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="approve-dialog-description">
             <Typography className={classes.message}>Die eingegebenen Daten gehen verloren. Möchten Sie Änderungen speichern?</Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={disAgreeHandler}>{Labels.Decline}</Button>
          <Button color="primary" onClick={agreeHandler} autoFocus>
           {Labels.Save}
          </Button>
        </DialogActions>
      </Dialog>
    )
}