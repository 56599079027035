import * as signalR from '@microsoft/signalr';
import AuthService from './auth-service';
import { CALENDAR_API_URL } from '../urls';
import { Task } from 'calendar/dist';

class TaskHubMethod {
    private readonly _value: string;

    private constructor(value: string) {
      this._value = value;
    }

    public static readonly TaskUpdate = new TaskHubMethod('TaskUpdate');
    public static readonly TaskDelete = new TaskHubMethod('TaskDelete');
    public static readonly TaskCreate = new TaskHubMethod('TaskCreate');

    public toString(): string {
      return this._value;
    }
}

class SignalRHub {
    private _signalRHubBuilder:signalR.HubConnectionBuilder;
    private _signalRHubConnection:signalR.HubConnection | null = null;

    constructor(){
      this._signalRHubBuilder = new signalR.HubConnectionBuilder();
    }

    public get isConnected():signalR.HubConnectionState | null{
      return this._signalRHubConnection?.state ?? null;
    }

    public async connect(errorHandler:(message:string)=>void, successHandler:(state:signalR.HubConnectionState)=>void):Promise<void>{
        try{
            const token = await AuthService.getAuthHeader();
            const connection = this._signalRHubBuilder.withUrl(`${CALENDAR_API_URL}/hub`, {
                accessTokenFactory:() => token.split(' ')[1]
            })
            .build();
            await connection.start()
            .then(()=>{
                this._signalRHubConnection = connection;
                successHandler(this._signalRHubConnection.state);
            })
            .catch((error)=>{
                errorHandler(error?.message || 'Failed to complete negotiation with the server ...');
            });
        }catch(error:any){
            errorHandler(error?.message || 'Failed to complete negotiation with the server ...');
        }
    }

    public async disconnect():Promise<void>{
       await this._signalRHubConnection?.stop();
    }

    public async onclose(closeHandler:(error:Error | undefined)=>Promise<void>):Promise<void>{
        this._signalRHubConnection?.onclose(closeHandler);
    }


    public async onTaskUpdateEvent(updateTaskHandler:(task:Task)=>void){
        if(this._signalRHubConnection){
            this._signalRHubConnection.on(TaskHubMethod.TaskUpdate.toString(),updateTaskHandler);
        }
    }

    public async offTaskUpdateEvent(){
        if(this._signalRHubConnection){
            this._signalRHubConnection.off(TaskHubMethod.TaskUpdate.toString());
        }
    }

    public async onTaskDeleteEvent(deleteTaskHandler:(taskId:string)=>void){
        if(this._signalRHubConnection){
            this._signalRHubConnection.on(TaskHubMethod.TaskDelete.toString(), deleteTaskHandler);
        }
    }

    public async offTaskDeleteEvent(){
        if(this._signalRHubConnection){
            this._signalRHubConnection.off(TaskHubMethod.TaskDelete.toString());
        }
    }

    public async onTaskCreateEvent(createTaskHandler:(task:Task)=>void){
        if(this._signalRHubConnection){
            this._signalRHubConnection.on(TaskHubMethod.TaskCreate.toString(), createTaskHandler);
        }
    }

    public async offTaskCreateEvent(){
        if(this._signalRHubConnection){
            this._signalRHubConnection.off(TaskHubMethod.TaskCreate.toString());
        }
    }
}

const newConnectionHub = new SignalRHub();

export default newConnectionHub;