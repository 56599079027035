import {useState, useEffect, FunctionComponent, ReactElement } from "react";
import {useParams , useLocation } from 'react-router-dom';
import { Divider, Grid, TextField, LinearProgress, Fab, useTheme, useMediaQuery,Tabs, Tab } from "@material-ui/core";
import {useFormik} from 'formik';
import {isSameDay} from 'date-fns';
import FormContainer from "../../components/FormContainer";
import { useErrorPage } from "../../hooks";
import VehicleDetails from './form-component/VehicleDetails';
import Address from './form-component/Address';
import LogisticService , {LogistikRecordData} from '../../services/logistic-service';
import useUserRoles from '../../hooks/useUserRoles';
import {Roles} from '../../roles';
import {ApproveDialog} from '../../components/Dialog';
import useStyles from './use-styles';
import * as Labels from '../../unit/labels';
import {extractErrorMessage} from '../../unit/utils';
import { navigateToLogisticList } from '../navigate';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Undo from '@material-ui/icons/Undo';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import StopIcon from '@material-ui/icons/Stop';
import clsx from 'clsx';
import { DatePicker } from '@material-ui/pickers';
import { get, isEmpty , isEqual} from 'lodash';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import Driver from './form-component/Driver';
import {setLogistikChanges} from '../../actions/edit';
import {useDispatch} from 'react-redux';
import TabPanel from '../order/TabPanel';
import { DetermineConnectionType } from "../../components/DetermineConnectionType";
import { AzureMapsProvider } from "react-azure-maps";


type LogistikDetailsProps = {};

interface LocationState{
    changedValue:any;
    logistikId:string;
    redirectTo: string;
}
export interface InitialValues {
  unloadingDate: MaterialUiPickersDate;
  loadingDate: MaterialUiPickersDate;
  loadingContactPerson: string | null;
  unloadingContactPerson: string | null;
  note: string | null;
  driverId: string | null;
}

const getFormikInitialValues = (data?:LogistikRecordData | null):  InitialValues => {
  const getDate = (name: string): Date | null => {
    const dateString = get(data, name, null);
    const date = dateString ? new Date(dateString): null;
    return date;
  }

  return {
    loadingDate: getDate('loadingInfo.loadingDate'),
    loadingContactPerson: get(data, 'loadingInfo.contactPerson', null),
    unloadingContactPerson: get(data, 'unloadingInfo.contactPerson', null),
    unloadingDate:  getDate('unloadingInfo.unloadingDate'),
    note: get(data, 'note', ''),
    driverId: get(data, 'driverId', null)
  }
}


const LogisticDetails: FunctionComponent<LogistikDetailsProps> = (props:LogistikDetailsProps): ReactElement => {
  const [loading, setLoading] = useState<boolean>(true);
  const [formData , setFormData] = useState<LogistikRecordData | null>(null);
  const [agreeFrameVisible, setAgreeFrameVisible] = useState<boolean>(false);
  const [error , setError] = useState<string | null>(null);
  const { id }= useParams<{id:string}>();
  const navigateToErrorPage = useErrorPage();
  const roles = useUserRoles();
  const classes = useStyles();
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up('sm'));
  const dispatch = useDispatch();
  const [tabIndex, setTabIndex] = useState<number>(0);
  const {state}:{state:LocationState} = useLocation();

  const tabIndexChangeHanlder = (event:React.ChangeEvent<{}>, newValue: number):void=>{
      setTabIndex(newValue);
  }

  useEffect(()=>{
     if(state?.changedValue && !isEmpty(state.changedValue) && error === null){
       const comparable = compareHandler(formData!, state.changedValue);
       if(state.logistikId === id){
        if(comparable){
          clearHandler();
        }else{
          setAgreeFrameVisible(true);
        }
       }
     }
  }, [state?.changedValue])

  const errorCatcher = (message:string)=>{
      clearHandler();
      setLoading(false);
      setError(message);
  }

  const clearHandler = ()=>{
    dispatch(setLogistikChanges( {logistikId: '' , changedValue: {} }));
  }

  const disAgreeHandler = ()=>{
    clearHandler();
    setAgreeFrameVisible(false);
  }

  const agreeHandler = async ()=>{
    setAgreeFrameVisible(false);
    setLoading(true);
    try{
      await onSubmit(state.changedValue as InitialValues);
      clearHandler();
      setLoading(false);
    }catch(error:any){
      console.log(error);
      const {errorMessage} = extractErrorMessage(error);
      errorCatcher(errorMessage);
    }
  }

  const validateTimePickerDate = (date:MaterialUiPickersDate):MaterialUiPickersDate=>{
    const hours = date?.getHours();
    if(!!hours){
      return date;
    }
    date?.setHours(12);
    return date;
  }

  const onSubmit = async (values: InitialValues)=>{
    const updatedData = {
       unloadingDate: validateTimePickerDate(values.unloadingDate),
       loadingDate: validateTimePickerDate(values.loadingDate),
       loadingContactPerson: values.loadingContactPerson,
       unloadingContactPerson: values.unloadingContactPerson,
       note: values.note,
       driverId: values.driverId
    }
    try{
      await LogisticService.update(id , updatedData);
    }catch(error){
      throw error;
    }
  }

  const formik = useFormik<InitialValues>({
    initialValues: getFormikInitialValues(formData),
    onSubmit
  })

  const isTheSameDayHandler = (formDataDate:MaterialUiPickersDate | null, formikDate:MaterialUiPickersDate | null):boolean=>{
    if(!formDataDate && !formikDate){
        return true;
    }
    else if(!formDataDate && formikDate){
      return false;
    }
    else if(!formikDate && formDataDate){
      return false;
    }

    return isSameDay(formDataDate!.getTime(), formikDate!.getTime());
  }

  const compareHandler = (formData:LogistikRecordData, formikData:InitialValues):boolean=>{
    const {loadingDate:initialLoadingDate, unloadingDate:initialUnloadingDate, ...other} = getFormikInitialValues(formData);
    const {loadingDate, unloadingDate, ...rest} = formikData;
    const objectsEqual = isEqual({...other}, {...rest});
    const loadingDatesEqual = isTheSameDayHandler(initialLoadingDate, loadingDate);
    const unloadingDatesEqual = isTheSameDayHandler(initialUnloadingDate, unloadingDate);

    if(objectsEqual && loadingDatesEqual && unloadingDatesEqual){
       return true;
    }
    return false;
  }

  const applyChangesHandler = async ()=>{
    if(!Object.keys(formik.touched).length){
       return;
    }

    try{
      if(!compareHandler(formData! , formik.values)){
        await onSubmit(formik.values);
      }
      clearHandler();
    }catch(error:any){
      throw error;
    }
  }

  const backHandler = async ()=>{
      setLoading(true);
    try{
      await applyChangesHandler();
      setLoading(false);
      navigateToLogisticList();
    }catch(error:any){
      console.log(error);
      const {errorMessage} = extractErrorMessage(error);
      errorCatcher(errorMessage);
    }
  }

  const dropDates = async ()=>{
      setLoading(true);
      try{
        await applyChangesHandler();
        const response = await LogisticService.dropDates(id);
        setFormData(response);
        setLoading(false);
      }catch(error: any){
        console.log(error);
        const {errorMessage} = extractErrorMessage(error);
        errorCatcher(errorMessage);
      }
  }

  useEffect(()=>{
    const touchedListNotEmpty = Object.keys(formik.touched).length > 0;
    if(!touchedListNotEmpty){
      return;
    }
    if(formik?.values && touchedListNotEmpty){
      dispatch(
        setLogistikChanges({
          logistikId:id,
          changedValue:formik.values
        })
      )
    }
  },[formik?.values, formik.touched])


  const startAndFinishActions = async(hasAlreadyStarted: boolean, isInactive: boolean):Promise<void>=>{
    setLoading(true);
    try{
      await applyChangesHandler();
      if(hasAlreadyStarted && !isInactive){
        await LogisticService.triggerStopLogistic(id);
      }
      if(!hasAlreadyStarted){
        await LogisticService.triggerStartLogistic(id);
      }
      const updatedFormState:LogistikRecordData = await LogisticService.getRecordInfoById(id);
      setFormData(updatedFormState);
      setLoading(false);
    }catch(error:any){
        console.log(error);
        const {errorMessage} = extractErrorMessage(error);
        errorCatcher(errorMessage);
    }
  }

  useEffect(()=>{
    if(error){
      navigateToErrorPage(error, `/logistik/${id}`);
    }
  }, [error]);


  useEffect(()=>{
    const getRecordInfo = async()=>{
        try{
         const response:LogistikRecordData = await LogisticService.getRecordInfoById(id);
         formik.setValues(getFormikInitialValues(response));
         setFormData(response);
         setLoading(false);
        }catch(error: any){
          console.log(error);
          const {errorMessage} = extractErrorMessage(error);
          setError(errorMessage);
        }
    };
    getRecordInfo();
  },[id])

  const errorLocationHandler = (message:string)=>{
     setError(message);
  }

  if(loading){
    return <LinearProgress />;
  }

   const isInactive = formData!.endDate !== null && formData!.startDate !== null;
   const isStarted = formData!.startDate !== null;
   const isUndоActive = formData!.startDate !== null && formData!.endDate === null;
   const accessForEdit = isInactive ? true : roles.includes(Roles.LogistikAuftragnehmer) ? false : true;
   const isVisible = (roles.indexOf(Roles.LogisticDriver) > -1 || roles.indexOf(Roles.LogistikAuftragnehmer) > -1) && formData!.status !=='';
   const fabSize =  smUp ? 'medium': 'small';

  return (
   <>
    <FormContainer title={get(formData, 'name', '---')} 
      rightElement={
        tabIndex === 1 &&
        <Fab
          variant="extended"
          size={fabSize}
          aria-label="back"
          onClick={backHandler}
        >
          <ArrowBackIcon />
          {Labels.Back}
        </Fab>
      }>
      <Tabs
        value={tabIndex}
        onChange={tabIndexChangeHanlder}
        indicatorColor="primary"
        textColor="primary"
        >
          <Tab label="Allgemein" />
          <Tab label="Map" />
      </Tabs>
      <TabPanel value={tabIndex} index={0}>
        <form onSubmit={formik.handleSubmit} className={classes.form}>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    label="Fahrzeug"
                    disabled
                    fullWidth
                    defaultValue={get(formData, 'vehicle.vin', '---')}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Fahrzeug verkehrssicher?"
                    disabled
                    fullWidth
                    defaultValue={formData!.isVehicleRoadworthy ? 'Ja' : 'Nein'}
                  />
                </Grid>
                {roles.indexOf(Roles.LogisticDriver) === -1 &&
                <Grid item xs={12}>
                  <TextField
                    label="Kostenstelle"
                    disabled
                    fullWidth
                    defaultValue={get(formData, 'costCentre', "---")}
                  />
                </Grid>}
                <Grid item xs={12}>
                  <TextField
                    label="Transporttyp"
                    disabled
                    fullWidth
                    defaultValue={get(formData, 'transportType')}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Zusätzliche Dokumente"
                    disabled
                    fullWidth
                    defaultValue={get(formData, 'additionalDocuments', "---")}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <VehicleDetails vehicleDetails={formData!.vehicle} driverRole={roles.includes(Roles.LogisticDriver)} value={get(formData, 'vehicle.antrieb', "---")}/>
            </Grid>
            <Grid item xs={12}>
              <Divider className={classes.divider}/>
            </Grid>

            <Grid item xs={6}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    label="Auftragnehmer"
                    disabled
                    fullWidth
                    defaultValue={get(formData, 'contractor',  "---")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Beladestelle"
                    disabled
                    fullWidth
                    defaultValue={get(formData, 'loadingInfo.loadingPoint.name', "---")}
                  />
                </Grid>
                <Grid item xs={12}>
                <Address
                    location={get(formData, 'loadingInfo.loadingPoint.location', null)}
                    postCode={get(formData, 'loadingInfo.loadingPoint.postCode', null)}
                    street={get(formData, 'loadingInfo.loadingPoint.street', null)}
                    title="Beladestelle Adresses"
                  />
                </Grid>
                <Grid item xs={12}>
                <TextField
                      disabled={accessForEdit}
                      label='Ansprechpartner'
                      value={formik.values.loadingContactPerson}
                      onChange={(val:any) => {
                        formik.setFieldValue("loadingContactPerson", val.target.value);
                        formik.setFieldTouched("loadingContactPerson", true)
                      }}
                      fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                  <DatePicker
                    disableToolbar
                    error={undefined}
                    minDateMessage={''}
                    fullWidth
                    disablePast
                    format="dd.MM.yyyy"
                    variant="inline"
                    disabled={accessForEdit}
                    value={formik.values.loadingDate}
                    onChange={date=>{
                      date?.setHours(12,0,0);
                      formik.setFieldValue("loadingDate", date);
                      formik.setFieldTouched("loadingDate", true)
                    }}
                    label="Beladestelle Datum"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Driver
                    contractorId={get(formData, 'contractorId')}
                    value={formik.values.driverId}
                    disabled={accessForEdit}
                    onChange={value=>{
                      formik.setFieldValue("driverId", value);
                      formik.setFieldTouched("driverId", true);
                    }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                      label="Entladestelle"
                      disabled
                      fullWidth
                      defaultValue={get(formData, 'unloadingInfo.unloadingPoint.name', "---")}
                    />
                </Grid>
                <Grid item xs={12}>
                  <Address
                    location={get(formData, 'unloadingInfo.unloadingPoint.location', null)}
                    postCode={get(formData, 'unloadingInfo.unloadingPoint.postCode', null)}
                    street={get(formData, 'unloadingInfo.unloadingPoint.street', null)}
                    title="Entladestelle Adresses"
                  />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                      disabled={accessForEdit}
                      label='Ansprechpartner'
                      value={formik.values.unloadingContactPerson}
                      onChange={(val:any) => {
                        formik.setFieldValue("unloadingContactPerson", val.target.value);
                        formik.setFieldTouched("unloadingContactPerson", true)
                      }}
                      fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                  <DatePicker
                    disableToolbar
                    fullWidth
                    disablePast
                    error={undefined}
                    minDateMessage={''}
                    format="dd.MM.yyyy"
                    variant="inline"
                    disabled={accessForEdit}
                    value={formik.values.unloadingDate}
                    onChange={date=>{
                      date?.setHours(12,0,0);
                      formik.setFieldValue("unloadingDate", date);
                      formik.setFieldTouched("unloadingDate", true)
                    }}
                    label="Entladestelle Datum"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <TextField
                value={formik.values.note}
                onChange={(val:any) => {
                          formik.setFieldValue("note", val.target.value);
                          formik.setFieldTouched("note", true)
                        }}
                multiline
                rows={5}
                fullWidth
                label='Notiz'
                disabled={isInactive ? true : roles.indexOf(Roles.LogisticDriver) > -1 || roles.indexOf(Roles.LogistikAuftragnehmer) > -1 ? false : true}
              />
            </Grid>
            <div className={classes.btn_container}>
            {<Fab
              variant="extended"
              size={fabSize}
              color='secondary'
              disabled={!isUndоActive}
              onClick={dropDates}
              >
                <Undo />
                {Labels.Undo}
            </Fab>}
              <Fab
              disabled={isInactive}
              className={clsx({'d-none':!isVisible})}
              variant="extended"
              size={fabSize}
              color={isStarted ? 'secondary' : 'primary'}
              onClick={() => !isInactive ? startAndFinishActions(isStarted,isInactive) : null}
              >
                { isInactive ? '' : isStarted ? <StopIcon /> : <PlayArrowIcon />}
                <div>{ isInactive ? Labels.Inactive : isStarted ? Labels.Stop : Labels.Start}</div>
              </Fab>
              <Fab
                variant="extended"
                size={fabSize}
                aria-label="back"
                onClick={backHandler}
              >
                <ArrowBackIcon />
                {Labels.Back}
              </Fab>
            </div>
         </Grid>
        </form>
      </TabPanel>
      <TabPanel value={tabIndex} index={1}>
        <AzureMapsProvider>
           <DetermineConnectionType
             errorObserver={errorLocationHandler}
             vehicleId={formData?.vehicle.id ?? null}
             recordRoute={`/logistik/${id}`}
           />
        </AzureMapsProvider>
      </TabPanel>
      {agreeFrameVisible && <ApproveDialog agreeHandler={agreeHandler} disAgreeHandler={disAgreeHandler} isOpen={agreeFrameVisible}/>}
    </FormContainer>
    </>
  );
};

export default LogisticDetails;
