import history from "../history";

export const navigateToCalendar = (): void => {
  history.push("/kalendar");
};

export const navigateToErrorPage = (message: string): void => {
  history.push(`/error?message=${message}`);
};

export const navigateToEventPage = (locationId: string): void => {
  history.push(`/kalendar/event?locationId=${locationId}`);
};

export const navigateToLogisticList = (): void => {
  history.push("/logistik");
};

export const navigateToLogisticDetails = (logistikId: string): void => {
  history.push(`/logistik/${logistikId}`);
};

export const navigateToDekraLocation = (dekraLocationId:string):void=>{
  history.push(`/location/${dekraLocationId}`);
}
