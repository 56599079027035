import { useQuery } from 'react-query';
import  CalendarService, { RapmsSchedule } from '../services/calendar-service';



const useRamps = (locationId: string, setRamps:(ramps:RapmsSchedule)=>void) => {
  
  return useQuery(
    ['ramps', locationId],
    async () => {
     return await CalendarService.getRampsSchedule(locationId);
    },
    {
      onSuccess:(data:RapmsSchedule)=>{
        setRamps(data)
      },
      initialData: [],
      refetchOnWindowFocus:false
    }
  );
};

export default useRamps;