import AuthService from './auth-service';
import axios, { AxiosResponse } from 'axios';
import {DEKRA_LOCATIONS_URL} from '../urls';

export interface RampWorkingHoursResponse{
  fridayFrom: number;
  fridayTo: number;
  mondayFrom: number;
  mondayTo: number;
  rampId: string;
  rampName: string;
  saturdayFrom: number | null;
  saturdayTo: number | null;
  status: RampStatus;
  sundayFrom: number | null;
  sundayTo: number | null;
  thursdayFrom: number;
  thursdayTo: number;
  tuesdayFrom: number;
  tuesdayTo: number;
  wednesdayFrom: number;
  wednesdayTo: number;
};

export interface RampWorkingHoursToDecimal{
  Id:string;
  Name:string;
  LocationId:string;
  MondayFrom: number | null;
  MondayTo:number | null;
  TuesdayFrom:number | null;
  TuesdayTo:number | null;
  WednesdayFrom:number | null;
  WednesdayTo:number | null;
  ThursdayFrom:number | null;
  ThursdayTo:number | null;
  FridayFrom:number | null;
  FridayTo:number | null;
  SaturdayFrom:number | null;
  SaturdayTo:number | null;
  SundayFrom:number | null;
  SundayTo:number | null;
  LunchFrom:number | null;
  LunchTo:number | null;
};

export type NewRampSaveModel = Omit<RampWorkingHoursToDecimal, 'Id'>;

export interface RampWorkingHoursStringFormat{
  fridayFrom: string | null;
  fridayTo: string | null;
  mondayFrom: string | null;
  mondayTo: string | null;
  rampId?: string;
  locationId:string;
  rampName: string;
  saturdayFrom: string | null;
  saturdayTo: string | null;
  status: RampStatus;
  sundayFrom: string | null;
  sundayTo: string | null;
  thursdayFrom: string | null;
  thursdayTo: string | null;
  tuesdayFrom: string| null;
  tuesdayTo: string | null;
  wednesdayFrom: string | null;
  wednesdayTo: string | null;
  lunchFrom:string | null;
  lunchTo:string | null;
};

export interface DekraLocation{
  id:string;
  name:string;
  dekraCode:string;
  parentLocationName:string;
};

export type RampStatus = 'Aktiv' | 'Inaktiv';
export interface Ramp{
  id:string;
  name:string;
  status:RampStatus;
};

interface UpdateLocationNamePayload{
  LocationId:string;
  Name:string;
};

class DekraLocationsService {

    public async getDekraLocations(): Promise<DekraLocation[]> {
      const token = await AuthService.getAuthHeader();
      const response:AxiosResponse<DekraLocation[]> = await axios.get(`${DEKRA_LOCATIONS_URL}/api/locations/getlocations`, {
        headers: {
          Authorization:token
        }
      });
      return response.data;
    }

    public async getLocationById(locationId:string):Promise<DekraLocation>{
      const token = await AuthService.getAuthHeader();
      const response:AxiosResponse<DekraLocation> = await axios.get(`${DEKRA_LOCATIONS_URL}/api/locations/getlocation`, {
        params:{
          locationId
        },
        headers: {
          Authorization: token
        }
      });
      return response.data;
    }

    public async getRampsByLocationId(LocationId:string){
      const token = await AuthService.getAuthHeader();
      const response = await axios({
        method:'GET',
        url:`${DEKRA_LOCATIONS_URL}/api/locations/getramps`,
        params:{
          LocationId
        },
        headers: {
          Authorization: token
        }
      })
      return response.data;
    }

    public async updateLocationName(payload:UpdateLocationNamePayload){
      const token = await AuthService.getAuthHeader();
      const response = await axios({
        method:'POST',
        url:`${DEKRA_LOCATIONS_URL}/api/locations/updatelocation`,
        data:{
          ...payload
        },
        headers: {
          Authorization: token
        }
      })

      return response.data;
    }

    public async getRampById(RampId:string){
      const token = await AuthService.getAuthHeader();
      const response = await axios({
        method:'GET',
        url:`${DEKRA_LOCATIONS_URL}/api/locations/getramp`,
        params:{
          RampId
        },
        headers: {
          Authorization: token
        }
      })
      return response.data;
    }

    public async updateRampStatus(isActive:boolean , rampId:string):Promise<void>{
       const token = await AuthService.getAuthHeader();
       await axios({
        method:'POST',
        url:`${DEKRA_LOCATIONS_URL}/api/locations/updaterampstate`,
        data:{
          Deactivate:isActive ? false : true,
          RecordId:rampId
        },
        headers: {
          Authorization: token
        }
      })
    }

    public async updateRamp(ramp:RampWorkingHoursToDecimal | NewRampSaveModel):Promise<void>{
      const token = await AuthService.getAuthHeader();
      await axios({
       method:'POST',
       url:`${DEKRA_LOCATIONS_URL}/api/locations/updateramp`,
       data:{
         Ramp:{
          ...ramp
         }
       },
       headers: {
         Authorization: token
       }
     })
    }

    public async createRamp(newRamp:NewRampSaveModel):Promise<void>{
      await this.updateRamp(newRamp);
    }
}

const dekraLocationsService = new DekraLocationsService();
export default dekraLocationsService;