import { FunctionComponent, ReactElement, SVGProps } from 'react';

const SvgDocumentIcon: FunctionComponent<SVGProps<SVGSVGElement>> = (props): ReactElement => {
  return (
    <svg height="40px" width="40px" viewBox="0 0 512 512" stroke='#6264a7' strokeWidth={5}>
      <polygon fill='#F8F8F9' points="345.6,0 51.2,0 51.2,512 460.8,512 460.8,115.2 "/>
      <polygon fill='#EDEDED' points="345.6,115.2 345.6,0 256,0 256,512 460.8,512 460.8,115.2 "/>
      <rect x="51.2" y="230.4" fill='#6264a7' width="409.6" height="204.8"/>
      <rect x="256" y="230.4" fill='#6264a7' width="204.8" height="204.8"/>
      <g>
	      <path fill='#FFFFFF' d="M230.711,289.408l19.238,58.061l19.008-58.061h19.93L257.898,371.2h-15.898l-31.334-81.792
		        L230.711,289.408L230.711,289.408z"/>
	      <path fill='#FFFFFF' d="M355.474,363.136c-6.221,5.76-13.403,8.64-21.542,8.64c-22.506,0-39.283-18.496-39.283-42.049
            c0-23.808,18.938-40.895,40.781-40.895c16.091,0,26.996,7.127,32.718,17.855l-14.054,10.598
            c-4.426-8.006-11.466-11.635-19.354-11.635c-11.633,0-20.966,10.349-20.966,24.769c0,13.74,8.854,24.538,21.772,24.538
            c7.375,0,14.016-3.418,19.931-10.254v-2.533h-15.899v-13.71h31.566v42.74h-15.667v-8.064H355.474z"/>
	      <path fill='#FFFFFF' d="M193.157,313.37c-6.81-5.13-16.09-7.834-21.773-7.834c-6.758,0-10.138,2.267-10.138,6.797
            c0,5.988,8.239,7.588,17.165,10.022c18.054,4.925,25.459,10.925,25.459,24.768c0,13.731-9.523,25-30.643,25
            c-13.44,0-24.881-3.562-34.906-10.139l8.293-16.474c7.172,4.89,16.211,9.563,26.958,9.563c6.682,0,10.022-2.035,10.022-6.106
            c0-4.486-5.249-6.966-11.924-8.755c-22.38-5.998-29.893-11.492-29.893-24.883c0-13.568,9.048-26.61,30.182-26.61
            c11.427,0,20.387,3.922,29.491,8.986L193.157,313.37z"/>
      </g>
    </svg>
  );
};

export default SvgDocumentIcon;