import { FunctionComponent, ReactElement } from 'react';
import { Button, ButtonProps, createStyles, makeStyles, Theme } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles<Theme, ButtonProps>(theme =>
  createStyles({
    btn: {
      backgroundColor: props => (props.color === 'primary' ? 'rgb(98, 100, 167)' : 'rgb(255, 255, 255)'),
      color: props => (props.color === 'primary' ? 'rgb(255, 255, 255)' : 'rgb(36, 36, 36)'),
      border: props => (props.color === 'primary' ? '1px solid rgb(98, 100, 167)' : '1px solid rgb(209, 209, 209)'),
      textTransform: 'none',
      boxShadow: 'none',
      '&:hover': {
        backgroundColor: props => (props.color === 'primary' ? 'rgb(73, 75, 131)' : 'rgb(245, 245, 245)'),
        borderColor: props => (props.color === 'primary' ? '1px solid rgb(73, 75, 131)' : 'rgb(189, 189, 189)'),
        boxShadow: 'none'
      }
    }
  })
);

const ButtonStyled: FunctionComponent<ButtonProps> = (props): ReactElement => {
  const classes = useStyles(props);

  return (
    <Button {...props} className={clsx(props.className, classes.btn)}>
      {props.children}
    </Button>
  );
};

export default ButtonStyled;
