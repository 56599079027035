import { TextField, Grid } from "@material-ui/core";
import { FunctionComponent, ReactElement } from "react";
import { OrderDetails } from "../../services/order-service";
import { DatePicker } from "@material-ui/pickers";
import { makeStyles } from "@material-ui/styles";
import { format } from "date-fns";
import { noop } from 'lodash';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import AvailableFrom from './AvailableFrom';
import {RepairType} from '../../unit/constants';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  plug:{},
  highlight_red: {
     '& #verkauft_order_field':{
       color: 'red'
     }
  },
}));

interface OrderFormProps extends OrderDetails {
  onEstimatedDateChange(date: MaterialUiPickersDate): Promise<void>
}

const OrderForm: FunctionComponent<OrderFormProps> = (props): ReactElement => {
  const { vin, orderComment, status, color, colorCode, type, startDate, endDate, estimatedDate, onEstimatedDateChange, licensePlate, sapNumber, vehicleManufacturer, vehicleModel, isRejected, id, varkauf  } = props;
  const classes = useStyles()

  const disabled = status  === 'Abgeschlossen' || isRejected;
  
  return (
    <>
    <Grid container spacing={3}>
      <Grid item xs={12}>
          <DatePicker
            disableToolbar
            fullWidth
            disablePast
            error={undefined}
            minDateMessage={''}
            format="dd.MM.yyyy"
            variant="inline"
            disabled={disabled}
            value={estimatedDate? new Date(estimatedDate) : null}
            onChange={onEstimatedDateChange}
            label="Fertigstellung"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Fahrzeug"
            disabled
            fullWidth
            defaultValue={vin || "---"}
          />
        </Grid>
        <Grid item xs={12}>
          <div className={clsx(classes.plug, {[classes.highlight_red]: varkauf === 'Verkauf'})}>
            <TextField
              error={varkauf === 'Verkauf'}
              id='verkauft_order_field'
              label="Verkauft"
              disabled
              fullWidth
              defaultValue={varkauf || "---"}
            />
          </div>
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="SAP-Nummer"
            disabled
            fullWidth
            defaultValue={sapNumber || "---"}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Kennzeichen"
            disabled
            fullWidth
            defaultValue={licensePlate || "---"}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Hersteller"
            disabled
            fullWidth
            defaultValue={vehicleManufacturer || "---"}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Verkaufsbezeichnung"
            disabled
            fullWidth
            defaultValue={vehicleModel || "---"}
          />
        </Grid>
        {
          Number(type) === RepairType.Lackierung &&
          <>
            <Grid item xs={12}>
              <TextField
                label="Farbe"
                disabled
                fullWidth
                defaultValue={color || "---"}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Farbcode"
                disabled
                fullWidth
                defaultValue={colorCode || "---"}
              />
            </Grid>
          </>
        }
        <Grid item xs={12}>
          <TextField
            label="Statusgrund"
            disabled
            fullWidth
            onChange={noop}
            value={status}
          />
        </Grid>
        <Grid item xs={12}>
          <AvailableFrom id={id} />
        </Grid>
        <Grid item xs={12}>
            <TextField
              value={orderComment ?? ''}
              multiline 
              rows={5} 
              fullWidth 
              label='Kommentar' 
              disabled={true}
            />
        </Grid>
        {startDate && (
          <Grid item xs={12}>
            <TextField
              label="Startdatum und -uhrzeit"
              disabled
              fullWidth
              defaultValue={format(new Date(startDate), "dd.MM.yyyy")}
            />
          </Grid>
        )}
        {endDate && (
          <Grid item xs={12}>
            <TextField
              label="Enddatum und -uhrzeit"
              disabled
              fullWidth
              defaultValue={format(new Date(endDate), "dd.MM.yyyy")}
            />
          </Grid>
        )}
    </Grid>
    </>
  );
};

export default OrderForm;
