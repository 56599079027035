import { FunctionComponent, ReactElement } from "react";
import { makeStyles, Typography } from '@material-ui/core';
import AccessDeniedIcon from '../assets/svg/access-denied-icon.svg';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'center'
  },
  header:{
    fontSize: 22,
    fontFamily: 'Roboto , Times New Roman , Arial, sans-serif',
    fontWeight:300 
  },
  box:{
    display:'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 10
  },
  image_container:{
    width: 200,
    height:200,
    "& img":{
      width: '100%', 
      height: '100%'
    }
  }
});

const AccessDenied: FunctionComponent = (): ReactElement => {
  const classes = useStyles()

  return (
  <div className={classes.container}>
     <div className={classes.box}>
        <Typography className={classes.header}>Access Denied!</Typography>
        <div className={classes.image_container}>
          <img  src={AccessDeniedIcon}/>
        </div>
     </div>
  </div>
  )
};

export default AccessDenied;
