import {FunctionComponent , ReactElement} from 'react';
import { makeStyles } from "@material-ui/core";
import LogoutIcon from '@material-ui/icons/ExitToApp';
import {
    Fab,
    useMediaQuery,
    useTheme
  } from "@material-ui/core";
import { useBackdrop } from '../hooks';
import { useMsal } from '@azure/msal-react';
import SignalRHub from '../services/signalR-service';
import * as Labels from '../unit/labels';

const useStyles = makeStyles({
    image:{
        marginRight:5, 
        width: 20, 
        height:20
    },
    fab:{
        cursor: 'pointer'
    }
})


export const LogoutBtn:FunctionComponent= ():ReactElement=>{
    const {instance} = useMsal();
    const [showBackdrop] = useBackdrop();
    const classes = useStyles();
    const theme = useTheme();
    const smUp = useMediaQuery(theme.breakpoints.up('sm'))
    const fabSize =  smUp ? 'medium': 'small';

    const logoutHandler = async ()=>{
        if(SignalRHub.isConnected === 'Connected'){
          await SignalRHub.disconnect();
        }
        showBackdrop(`${Labels.Logout}...`);
        await instance.logout();
    }

    return (
    <Fab
        className={classes.fab}
        variant="extended"
        size={fabSize}
        aria-label="logout"
        onClick={logoutHandler}
        >
          <LogoutIcon fontSize='medium'/>
          {Labels.Logout}
    </Fab>
    )
}