import { FunctionComponent, ReactElement } from "react";
import { TableCell, TableRow } from "@material-ui/core";
import { Damage } from "../../../services/damage-service";
import ImageCell from "../ImageCell";
import {withSnackbar, SnackbarProps} from '../../../hoc/withSnackbar';

type TableRowWrapperProps = {
  vin: string;
  showRepairType: boolean;
  vehicleId?:string;
} & Damage & SnackbarProps;

const TableRowWrapper: FunctionComponent<TableRowWrapperProps> = (
  props
): ReactElement => {
  const { component, damage, description, vin, id, imageId, purchaseOrder, showRepairType, repairType, vehicleId, showSnackbar } = props;
  return (
    <TableRow>
      <ImageCell showSnackbar={showSnackbar} id={id} vin={vin} imageName={damage} imageId={imageId} purchaseOrder={purchaseOrder}  vehicleId={vehicleId}/>
      <TableCell align="center">{component}</TableCell>
      <TableCell align="right">{damage}</TableCell>
      <TableCell align="right">{description}</TableCell>
      {showRepairType && <TableCell align="right">{repairType}</TableCell>}
    </TableRow>
  );
};

export default withSnackbar(TableRowWrapper);
