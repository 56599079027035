import {useState , useEffect} from 'react';
import { HubConnection } from '@microsoft/signalr';
import * as signalR from '@microsoft/signalr';

export interface LocationResponse{
  liberkeeLat: number | null;
  liberkeeUpdateTime: string | null;
  liberkiLon: number | null;
  merakiLat: number | null;
  merakiLon: number | null;
  merakiUpdateTime: string | null;
  vehicleId: string;
}

interface VehicleCoordinates{
  liberkeeLat:number | null;
  liberkeeLon:number | null;
}

interface VehicleCoordinatesAfterJoined{
  liberkeeVehicleLat: number | null;
  liberkeeVehicleLon: number | null;
}

type VehicleCoordinatesWithHub = {
  hubConnection: HubConnection | null;
  afterJoinedCoords:VehicleCoordinatesAfterJoined;
} & VehicleCoordinates;

class FileHubMethod {
    private readonly _value: string;

    private constructor(value: string) {
      this._value = value;
    }

    public static readonly UpdateVehicle = new FileHubMethod('updatevehicle');
    public static readonly Joined = new FileHubMethod('joined');
    public static readonly GetLocation = new FileHubMethod('getlocation');

    public toString(): string {
      return this._value;
    }
  }

const setBeforeJoined = ()=>({
  liberkeeVehicleLat: null,
  liberkeeVehicleLon: null
})


export const useSignalR = (
           url: string ,
           id:string | undefined | null,
          ): VehicleCoordinatesWithHub=>{
    const [hubConnection, setHubConnection] = useState<HubConnection | null>(null);
    const [incomingCoords, setIncomingCoords] = useState<VehicleCoordinates>({liberkeeLat:null, liberkeeLon:null});
    const [vehicleCoordsAfterJoined , setVehicleCoordsAfterJoined ] = useState<VehicleCoordinatesAfterJoined>(setBeforeJoined());


    useEffect(()=>{
      if(id){
        const connect = async (): Promise<void> => {
          const connection = new signalR.HubConnectionBuilder()
              .withUrl(url)
              .build();
              await connection.start();
              await connection.send('join', id);
              setHubConnection(connection);
              console.log('SignalR connected');
        };
        connect();

        return (): void => {
          const unsubscribe = async (): Promise<void> => {
            if (hubConnection) {
              await hubConnection.stop();
            }
          };
          unsubscribe();
        };
      }
    },[setHubConnection, url, id]);

     const vehicleUpdateHandler = (coordinates:LocationResponse)=>{
       if(!!coordinates.liberkeeLat && !!coordinates.liberkiLon){
         setIncomingCoords(prev => ({...prev , liberkeeLat:coordinates.liberkeeLat , liberkeeLon:coordinates.liberkiLon}));
       }
     }

     const getCurrentLocationHandler = (coordinates:LocationResponse)=>{
      if(!!coordinates.liberkeeLat && !!coordinates.liberkiLon){
        setIncomingCoords(prev => ({...prev , liberkeeLat:coordinates.liberkeeLat , liberkeeLon:coordinates.liberkiLon}));
      }
     }

    const joinHandler = (coordinates:LocationResponse)=>{
      if(!!coordinates.liberkeeLat && !!coordinates.liberkiLon){
        setVehicleCoordsAfterJoined(prev => ({ ...prev , liberkeeVehicleLat:coordinates.liberkeeLat , liberkeeVehicleLon:coordinates.liberkiLon }));
      }
    }


    useEffect(()=>{
     if(hubConnection){
        hubConnection.on(FileHubMethod.UpdateVehicle.toString(), vehicleUpdateHandler);
        hubConnection.on(FileHubMethod.GetLocation.toString(), getCurrentLocationHandler);
        hubConnection.on(FileHubMethod.Joined.toString(), joinHandler);
        const unsubscribe = async (): Promise<void> => {
          if (hubConnection) {
            await hubConnection.stop();
          }
        };

      return (): void => {
        unsubscribe();
      };
     }
    },[hubConnection])

    return { ...incomingCoords ,  hubConnection , afterJoinedCoords:vehicleCoordsAfterJoined };
}