import { makeStyles } from "@material-ui/core";
import { FunctionComponent, ReactElement , ReactSVGElement} from "react";
import { useLocation } from "react-router-dom";
import * as Labels from '../unit/labels';
import { Fab, useMediaQuery, useTheme} from '@material-ui/core';
import {LogoutBtn} from '../components/LogoutBtn';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import history from '../history';

const useStyles = makeStyles({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexGrow:1,
    gap:10
  },
  error: {},
  message:{
    maxWidth: 300
  },
  image_container:{
    width:200,
    height:200,
  },
  btnsContainer:{
    width:'100%', 
    display:'flex',
  }
});

const ErrorIcon:FunctionComponent = ():ReactElement<ReactSVGElement>=>{
  return (
    <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" >
      <path d="M12 2C17.523 2 22 6.478 22 12C22 17.522 17.523 22 12 22C6.477 22 2 17.522 2 12C2 6.478 6.477 2 12 2ZM12 3.667C7.405 3.667 3.667 7.405 3.667 12C3.667 16.595 7.405 20.333 12 20.333C16.595 20.333 20.333 16.595 20.333 12C20.333 7.405 16.595 3.667 12 3.667ZM11.9987 14.5022C12.5502 14.5022 12.9973 14.9494 12.9973 15.5009C12.9973 16.0524 12.5502 16.4996 11.9987 16.4996C11.4471 16.4996 11 16.0524 11 15.5009C11 14.9494 11.4471 14.5022 11.9987 14.5022ZM11.9945 7C12.3742 6.9997 12.6882 7.2816 12.7381 7.64764L12.7451 7.7494L12.7487 12.251C12.749 12.6652 12.4135 13.0013 11.9993 13.0016C11.6196 13.0019 11.3055 12.72 11.2556 12.354L11.2487 12.2522L11.2451 7.7506C11.2447 7.33639 11.5802 7.00033 11.9945 7Z" fill="#F05D53"/>
    </svg>
  )
}

const useErrorMessage = (): { message: string | null, state: any } => {
  const { search, state } = useLocation();

  return { message: new URLSearchParams(search).get("message"), state };
};

const ErrorPage: FunctionComponent = (): ReactElement => {
  const classes = useStyles();;
  const { message , state} = useErrorMessage();
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up('sm'));

 const backHandler = ()=>{
    history.push(state.from);
 };

 const fabSize =  smUp ? 'medium': 'small';
 const errorCode = (state && state?.code) || '';
 const notification = (state && state?.error) || '';
 const unAuthorized = errorCode === 401;

 return(
  <div className={classes.container}>
      <div className={classes.error}>
        <div className={classes.message}>
          <p><b>Ruh-roh, something just isn't right...</b></p>
          <p><b>Error code:</b>{errorCode}</p>
          <p><b>Error appeared:</b>{notification}</p>
          <p><b>Message:</b> {message}</p>
        </div>
        <div className={classes.btnsContainer} style={{justifyContent:unAuthorized ? 'space-between' : 'center', gap:10}}>
        { state && state?.from &&
           (<Fab
              variant="extended"
              size={fabSize}
              aria-label="back"
              onClick={backHandler}
            >
                <ArrowBackIcon />
                {Labels.Back}
            </Fab>)
        }
        {
          unAuthorized &&
          (
           <LogoutBtn />
          )
        }
        </div>
      </div>
      <div className={classes.image_container}>
        <ErrorIcon />
      </div>
  </div>
 )
};

export default ErrorPage;
