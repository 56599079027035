import { Location } from 'history';
import { useEffect, useState } from 'react';
import {useLocation} from 'react-router-dom';
import history from '../history';

const useBrowserBackStack = ():Location<any>[] => {
  const [backStack, setBackStack] = useState<Location[]>([useLocation()]);


  useEffect(() => {
    history.listen((location, action) => {
      setBackStack(backStack => {
        backStack = backStack.length > 100 ? backStack.slice(50) : backStack
        switch (action) {
          case 'POP':
            return backStack.slice(0, backStack.length - 1);
          case 'PUSH':
            return [...backStack, location];
          case 'REPLACE':
            return [...backStack.slice(0, backStack.length - 1), location];
        }
      });
    });
  }, [setBackStack]);

  return backStack;
};

export default useBrowserBackStack;