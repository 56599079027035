import { FunctionComponent, ReactElement, SVGProps } from 'react';

const RightArrowIcon: FunctionComponent<SVGProps<SVGSVGElement>> = (props): ReactElement => {
  return (
    <svg viewBox='-6 -6 32 32' role='presentation' {...props}>
      <path d='M12.846 5.47306L12.9038 5.40381C13.0774 5.23024 13.3468 5.21096 13.5417 5.34595L13.6109 5.40381L17.8824 9.67788L17.9112 9.71534L17.9533 9.78871L17.9762 9.8471L17.9889 9.89476L18 10L17.9921 10.0888L17.9695 10.1724L17.9458 10.2267L17.9207 10.2704L17.8836 10.3208L17.8536 10.3536L13.6109 14.5962C13.4157 14.7915 13.0991 14.7915 12.9038 14.5962C12.7302 14.4226 12.711 14.1532 12.846 13.9583L12.9038 13.8891L16.292 10.4994L2.5 10.5C2.25454 10.5 2.05039 10.3231 2.00806 10.0899L2 10C2 9.75454 2.17688 9.55039 2.41012 9.50806L2.5 9.5L16.292 9.49936L12.9038 6.11092C12.7302 5.93735 12.711 5.66793 12.846 5.47306L12.9038 5.40381L12.846 5.47306Z' />
    </svg>
  );
};

export default RightArrowIcon;
