import { FunctionComponent, ReactElement } from "react";
import { Button } from "@material-ui/core";
import Create from "@material-ui/icons/PostAdd";

interface CarJockeyProps{
    clickHandler:()=>void
}

const CarJockey:FunctionComponent<CarJockeyProps> = ({clickHandler}):ReactElement=>{

    return (
        <Button
          variant="contained"
          color="primary"
          startIcon={true ? <Create /> : null}
          onClick={clickHandler}
        >
          CarJockey
        </Button>
      );
}

export default CarJockey