import { FunctionComponent, ReactElement } from 'react';
import { Alert, AlertProps } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import clsx from 'clsx';

const useStyles = makeStyles({
  alert: {
    paddingLeft: '32px',
    paddingRight: '32px'
  },
  close_icon:{
    cursor:'pointer!important'
  }
});

interface Props extends AlertProps {
  visible: boolean;
  onErrorHandler():void;
}

const AlertStyled: FunctionComponent<Props> = (props): ReactElement | null => {
  const classes = useStyles();
  const { severity = 'error', visible, onErrorHandler, ...rest } = props;

  const onClickHandler = ()=>{
    onErrorHandler();
  }

  if (visible) {
    return (
      <Alert 
        action={
        <div className={classes.close_icon} onClick={onClickHandler}>
          <CloseIcon />
        </div>}
        className={clsx(classes.alert, props.className)} 
        severity={severity} 
        {...rest}
      >
        {props.children}
      </Alert>
    );
  }

  return null;
};

export default AlertStyled;
