import { FunctionComponent, ReactElement, useState } from "react";
import { Button, CircularProgress } from "@material-ui/core";
import orderService, { OrderDetails } from "../../services/order-service";
import { useErrorPage } from "../../hooks";
import { getErrorMessage } from "../../unit/utils";
import StopIcon from "@material-ui/icons/Stop";
import * as Labels from '../../unit/labels';

interface FinishOrderWorkButtonProps {
  visible: boolean;
  id: string;
  onFinish(order: OrderDetails): void;
}

const FinishOrderWorkButton: FunctionComponent<FinishOrderWorkButtonProps> = (
  props
): ReactElement | null => {
  const { visible, id, onFinish } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const navigateToErrorPage = useErrorPage();

  if (!visible) {
    return null;
  }

  const clickHandler = async (): Promise<void> => {
    try {
      setLoading(true);
      const order = await orderService.finishOrderWork(id);
      onFinish(order);
      setLoading(false);
    } catch (err) {
      navigateToErrorPage(getErrorMessage(err));
    }
  };

  return (
    <Button
      variant="contained"
      color="secondary"
      onClick={clickHandler}
      startIcon={loading ? <CircularProgress size={19} /> : <StopIcon />}
    >
      {Labels.Stop}
    </Button>
  );
};

export default FinishOrderWorkButton;
