import { FunctionComponent, ReactElement, useState } from "react";
import { TableCell, Avatar, CircularProgress } from "@material-ui/core";
import { useQuery } from "react-query";
import sharepointService from "../../services/sharepoint-service";
import IconButtonForUpload from "./VehicleDamagesTable/IconButtonForUpload";
import {SnackbarProps} from '../../hoc/withSnackbar';
import { Skeleton } from "@material-ui/lab";
import * as Labels from '../../unit/labels';

interface ImageCellProps extends SnackbarProps{
  imageId: string | null;
  id: string;
  vin: string;
  imageName: string | null;
  purchaseOrder: { id: string; name: string } | null;
  vehicleId?:string;
};

const ImageCell: FunctionComponent<ImageCellProps> = (props): ReactElement => {
  const { id, imageName, vehicleId, showSnackbar } = props;
  const [imageId, setImageId] = useState<string | null>(props.imageId);
  const [imagePreviewError, setImagePreviewError] = useState<boolean>(false);
  const [isBrowsingFile, setIsBrowsingFile] = useState<boolean>(false);
  const entityName = "lueg_fahrzeug";

  const { isLoading, error, data } = useQuery(
    ["imagePreview", imageId],
    () => {
      if (imageId) {
        return sharepointService.fetchFilePreview(imageId);
      }
      return Promise.resolve(null);
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  const imageIdChangeHandler = (id: string) => {
    setImagePreviewError(false);
    setImageId(id);
  };

  const imageErrorHandler = (): void => {
    setImagePreviewError(true);
  };

  const preveiwClickHandler = async (): Promise<void> => {
    if (isBrowsingFile) {
      return;
    }
    setIsBrowsingFile(true);
    try{
      await sharepointService.browseFile(imageId!);
    }catch(error){
      showSnackbar(Labels.FileOpenError, 'top', 'center');
    }finally{
      setIsBrowsingFile(false);
    }
  };

  const renderCellContent = (): ReactElement => {
    if (
      !imageId ||
      imagePreviewError ||
      error ||
      (!isLoading && (!data || !data?.thumbnail))
    ) {
      return (
        <IconButtonForUpload
          damageId={id}
          entityName={entityName}
          vehicleId={vehicleId}
          onImageIdChange={imageIdChangeHandler}
          showSnackbar={showSnackbar}
        />
      );
    }

    if (isLoading) {
      return <Skeleton variant="rect" width={50} height={50} />;
    }

    return (
      <Avatar variant='square' style={{width: 50, height: 50}} onClick={preveiwClickHandler} className="pointer" >
        {isBrowsingFile ? (
          <CircularProgress size={25} />
        ) : (
          <img
            src={`data:image/png;base64, ${data!.thumbnail}`}
            alt={`${imageName}`}
            onError={imageErrorHandler}
            style={{width: 50, height: 50}}
          />
        )}
      </Avatar>
    );
  };

  return (
    <TableCell component="th" scope="row">
      {renderCellContent()}
    </TableCell>
  );
};

export default ImageCell;
