import { FunctionComponent, ReactElement } from "react";
import { Box, makeStyles } from '@material-ui/core';

interface TabPanelProps {
  index: any;
  value: any;
}

const useStyles = makeStyles({
  box: {
    paddingTop: '24px'
  }
})

const TabPanel: FunctionComponent<TabPanelProps> = (props): ReactElement => {
  const { children, value, index } = props;
  const classes = useStyles();

  return (
    <div role="tabpanel" hidden={value !== index}>
      {value === index && <Box className={classes.box}>{children}</Box>}
    </div>
  );
};

export default TabPanel;
