import { makeStyles, createStyles, Theme } from "@material-ui/core";


const useStyles = makeStyles( (theme: Theme) =>
  createStyles ({
    divider: {
        margin: '7px 0px',
        padding: '1px 0px'
    },
    btn_container: {


      '& button': {
        marginLeft: '10px',
        marginBottom: '10px',

      },
      [theme.breakpoints.up('sm')]: {
        top: '78px',
      },
      [theme.breakpoints.down('xs')]: {
        bottom: 0,
        left: 0,
        justifyContent:'space-between',
      },
      right: 0,

      position: 'fixed',
      zIndex: 10,

      display: 'flex',
      flexDirection: 'row',
      margin: '20px',

    },
    form: {
      [theme.breakpoints.down('md')]: {
        marginBottom: '40px'
      }

    },
    error_container: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '&>section':{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center'
        }
      },
      alert:{
          fontSize: 15,
          margin: theme.spacing(2)
      }
 })
);

export default useStyles
