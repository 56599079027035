import {FunctionComponent , ReactElement, useRef, useEffect } from "react";
import {useQuery} from 'react-query';
import {LinearProgress} from '@material-ui/core';
import {makeStyles} from "@material-ui/core";
import Toolbar from "@material-ui/core/Toolbar";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import DekraLocationsService , {DekraLocation} from '../../services/dekra-locations-service';
import {isEmpty} from 'lodash';

import {BackBtn} from '../../components/BackBTn';
import EmptyRow from "../../components/EmptyRow";
import LocationRow from './location-row';
import { useErrorPage } from "../../hooks";
import { extractErrorMessage } from "../../unit/utils";

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
  toolbar:{
    display: 'flex',
    justifyContent: 'flex-end',
    height: '50px'
  }
});


const DekraLocations:FunctionComponent = ():ReactElement=>{
    const didMount = useRef(false);
    const classes = useStyles();
    const navigate =  useErrorPage();

    const {data, isLoading } = useQuery(
        ['locations-request', didMount.current],
        async ({queryKey})=>{
          if(queryKey[1]){
            return DekraLocationsService.getDekraLocations();
          }
        },
        {
         refetchOnWindowFocus:false,
         cacheTime:0,
         onError:(error:any)=>{
          const message = extractErrorMessage(error);
          navigate(message.errorMessage , `/dekra-locations`, message.statusCode);
         }
        }
    );

  useEffect(() => {
    if (!didMount.current) {
      didMount.current = true;
    }
  }, [didMount]);


  if(isLoading){
    return <LinearProgress />
  }

    return(
      <div className={classes.root}>
        <div />
        <TableContainer>
          <Toolbar className={classes.toolbar}>
            <BackBtn />
          </Toolbar>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell>
                  Name
                </TableCell>
                <TableCell align='center'>
                  DEKRA Code
                </TableCell>
                <TableCell align="center">
                  Parent Location
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isEmpty(data) && <EmptyRow colSpan={7} />}
              {data && data.map((location:DekraLocation) => (
                <LocationRow key={location.id} location={location} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    )

}

export default DekraLocations;