import {useState, useEffect, FunctionComponent, ReactElement , useRef} from "react";
import { useSelector , useDispatch} from "react-redux";
import { setActiveTab } from "../../actions/edit";
import { RootState } from "../../reducers";
import { InitialState } from "../../reducers/edit-reducer";
import { useParams } from "react-router-dom";
import FormContainer from '../../components/FormContainer';
import TabPanel from './TabPanel';
import { useQuery } from "react-query";
import {
    LinearProgress,
    Tabs,
    Tab,
  } from "@material-ui/core";
import LocationPanel from './location-panel';
import RampsPanel from './ramps-panel';
import DekraLocationsService , {DekraLocation , Ramp} from '../../services/dekra-locations-service';
import {BackBtn} from '../../components/BackBTn';
import { useErrorPage } from "../../hooks";
import { extractErrorMessage } from "../../unit/utils";


interface LocationParams{
    id:string
}

const LocationDetails:FunctionComponent = ():ReactElement | null=>{
    const {id} = useParams<LocationParams>();
    const {activeLocationTab} = useSelector<RootState , InitialState>( state => state.edit);
    const [result , setResult] = useState<[DekraLocation | null, Ramp[]]>([null , []]);
    const [updateInBackground , setUpdateInBackground] = useState<boolean>(false);
    const [tabIndex, setTabIndex] = useState<number>(0);
    const navigate = useErrorPage();
    const dispatch = useDispatch();
    const didMount = useRef(false);

    const { isLoading, isFetching , refetch} = useQuery(
        ['get-location-by-id_&_get_ramps', id, didMount.current], 
        async ({queryKey})=>{
        if(queryKey[2]){
            return Promise.all([
                DekraLocationsService.getLocationById(id),
                DekraLocationsService.getRampsByLocationId(id)
            ])
        }
    },{
        refetchOnWindowFocus:false,
        onSuccess:(data:[DekraLocation, Ramp[]])=>{
          if(data){
            setResult(data);
          }
        },
        onError:(error:any)=>{
          const message = extractErrorMessage(error);
          navigate(message.errorMessage , `/location/${id}`, message.statusCode);
        }
      }
    );

    useEffect(() => {
      if (!didMount.current) {
          didMount.current = true;
      }
    }, [didMount]);

    useEffect(()=>{
      if(didMount.current){
        if(activeLocationTab){
            setTabIndex(activeLocationTab);
            dispatch(setActiveTab(0));
        }
      }
    },[activeLocationTab , dispatch, didMount])


    const tabIndexChangeHanlder = ( event: React.ChangeEvent<{}>, newValue: number)=>{
        setTabIndex(newValue);
    }

    const saveLocationNameHandler = (name:string):void=>{
        setResult( res => {
            let location = res[0]!;
            return [{...location , name}, [...res[1]]];
        })
    }

    const onRefetchAfterRampCreation = async ():Promise<void>=>{
        setUpdateInBackground(true);
        try {
           await refetch();
           setUpdateInBackground(false);
        }catch(error){
          console.log(error);
          setUpdateInBackground(false);
        }
    }


    if((isLoading || isFetching) && !updateInBackground){
      return <LinearProgress />;
    }

    const [location , ramps]:[DekraLocation | null, Ramp[]] = result;

    return(
            <FormContainer
              title={location?.name || ''}
              rightElement={
               <div>
                 <BackBtn />
               </div>
              }
            >
            <Tabs
              value={tabIndex}
              onChange={tabIndexChangeHanlder}
              indicatorColor="primary"
              textColor="primary"
              scrollButtons="on"
              variant="scrollable"
            >
              <Tab label="Location" />
              <Tab label="Ramps" />
            </Tabs>
            <TabPanel value={tabIndex} index={0}>
              <LocationPanel location={location} onLocationNameSaveHandler={saveLocationNameHandler}/>
            </TabPanel>
            <TabPanel value={tabIndex} index={1}>
              <RampsPanel ramps={ramps} dekraLocation={location?.name || ''} onRefetch={onRefetchAfterRampCreation} locationId={id}/>
            </TabPanel>
          </FormContainer>
    )
}

export default LocationDetails;