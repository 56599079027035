import React, {useState , useMemo, useEffect, useCallback} from 'react'
import {
    AzureMap,
    AzureMapDataSourceProvider,
    AzureMapFeature,
    AzureMapLayerProvider,
    AzureMapPopup,
    IAzureMapControls,
    IAzureMapFeature,
    IAzureMapOptions,
    useAzureMaps
  } from "react-azure-maps";
  import { AuthenticationType, data, ControlPosition, AuthenticationOptions } from "azure-maps-control";
  import {observeCoordsChanges , callNavigate, NavigateResponse, ErrorMessage} from './navigate/coords-observer';
  import AzureLocationService from '../services/azure-location-service';
  import {useMqttBroker} from '../hooks/use-mqttBroker';
  import {MqttConnectionData , MqttGeoLocationPoint} from '../types';
  import { extractErrorMessage } from '../unit/utils';
  import {LocationResponse, useSignalR} from '../hooks/use-signalR';
  import * as Labels from '../unit/labels';
  import {makeStyles , Tooltip, IconButton} from "@material-ui/core";
  import MyLocationIcon from '@material-ui/icons/MyLocation';
  import CarIcon from '@material-ui/icons/DirectionsCar';
  import {SIGNAL_R_CONNECTION_URL} from '../urls';

  const useStyles = makeStyles({
    btns_container:{
      display: 'flex',
      height: '100%',
      width: '100%' ,
      margin: '10px 10px',
      justifyContent: 'flex-between',
    },
    user_icon:{
      color:'red',
      display: 'flex',
      alignItems:'center',
      justifyContent:'center',
      backgroundColor: '#ededeb',
      outline: '1px solid red',
      margin: '3px',
      "&:hover":{
        backgroundColor: '#fce6e8'
      }
    },
    car_icon:{
      color:'#46a4eb',
      display: 'flex',
      alignItems:'center',
      justifyContent:'center',
      backgroundColor: '#ededeb',
      outline: '1px solid #46a4eb',
      margin: '3px',
      "&:hover":{
        backgroundColor: '#e6f3fc'
      }
    }
  });

interface LocationProps{
   vehicleId:string | null;
   errorObserver:(message:string)=> void;
   mqttConfig:MqttConnectionData | null;
   liberkeeConfig:LocationResponse | null;
}

export interface CoordsResponse{
  x:number;
  y:number;
  position?:GeolocationPosition | null;
  id?:string;
}

interface TrafficOptions{
  incidents?: boolean;
  flow: "relative" | "none" | "absolute" | "relative-delay" | undefined;
}

interface MarkersView{
  vehicleLocation: IAzureMapFeature | null;
  personLocation:IAzureMapFeature | null;
  pointsDistance:IAzureMapFeature | null;
  circleArea:IAzureMapFeature | null;
}

interface RenderData{
  id:string,
  position?:{longitude: number, latitude: number};
  positions?:Array<[number, number]>;
  geoLocation?:GeolocationPosition | null;
  radius?:number;
}

const inferVehicleRelevantCoords = (initialConfig:MqttConnectionData | LocationResponse | null):CoordsResponse=>{
  const initialCoords = {x: 0 , y:0};
  if(!initialConfig){
    return initialCoords;
  }
  if((initialConfig as MqttConnectionData)?.geolocation?.length){
    const {latitude , longitude} = (initialConfig as MqttConnectionData)!.geolocation![0];
    return {x:latitude , y:longitude};
  }

  const liberkeeLat = (initialConfig as LocationResponse).liberkeeLat;
  const liberkeeLon = (initialConfig as LocationResponse).liberkiLon;
  if(!!liberkeeLat && !!liberkeeLon){
     return {x:liberkeeLat , y:liberkeeLon};
  }

  return initialCoords;
}

const inferDeviationValue = (mqttConfig:MqttConnectionData | null): number | null=>{
   if(!mqttConfig){
     return null;
   }

   if(mqttConfig.geolocation?.length){
    const {margin} = mqttConfig.geolocation[0];
    return margin;
   }

   return null;
}

const getGeolocationPropertyName = (mqttData:MqttConnectionData | null, property:'zoneName' | 'siteName')=>{
  if(!mqttData){
    return null;
  }
  const {type, geolocation} = mqttData;
  if(type === 'asset' && geolocation){
    const key = property as keyof MqttGeoLocationPoint;
    return geolocation[0][key] as any;
  }
  return null;
};


export const CarLocation:React.FunctionComponent<LocationProps> = (props:LocationProps):JSX.Element=>{
    const [key] = useState<string>('nLjj-AX5hsSoulCa6A9l8PkNTeSUY-XPJdVZT7WpxR4');
    const [popupOptions, setPopupOptions] = useState({});
    const [readyForUser, setReadyForUser] = useState<boolean>(false);
    const [cameraCapture , setCameraCapture] = useState<boolean>(false);
    const [loading , setLodaing] = useState<boolean>(false);
    const {vehicleId , errorObserver , mqttConfig, liberkeeConfig} = props;
    const [vehicleCoords , setVehicleCoords] = useState<CoordsResponse>(inferVehicleRelevantCoords(mqttConfig ?? liberkeeConfig));
    const [userCoords , setUserCoords] = useState<CoordsResponse>({x: 0 , y:0});
    const [zoneName , setZoneName] = useState<string | null>(getGeolocationPropertyName(mqttConfig, 'zoneName'));
    const [siteName, setSiteName] = useState<string | null>(getGeolocationPropertyName(mqttConfig, 'siteName'));
    const [deviationValue , setDeviationValue] = useState<number | null>(inferDeviationValue(mqttConfig));

    const { liberkeeLat, liberkeeLon , hubConnection , afterJoinedCoords } =
       useSignalR(`${SIGNAL_R_CONNECTION_URL}/location` , !mqttConfig ? vehicleId : null);
    const { mqttLat, mqttLon, deviation , site, zone} =
       useMqttBroker(!!mqttConfig ? `wss://${mqttConfig.mqttConfig!.url}/mqtt` : '', mqttConfig);

    const {liberkeeVehicleLat , liberkeeVehicleLon} = afterJoinedCoords;
    const coordsListener = useMemo(()=> observeCoordsChanges(), []);
    const { mapRef, isMapReady } = useAzureMaps();
    const classes = useStyles();
    const ZOOM = 17;

    const renderLineString = (data:RenderData):IAzureMapFeature =>{
       //render line which connecting 2 markers
      return <AzureMapFeature
                key={data.id}
                id={data.id}
                type="LineString"
                variant='shape'
                coordinates={data?.positions ?? undefined}
                properties={{
                  id: data.id
                //popUpProp: data,
                }}
                setCoords={data?.positions ?? undefined}
             />
    }

    const renderPoint = (data:RenderData):IAzureMapFeature => {
        //render the marker itself
      return <AzureMapFeature
              key={data.id}
              id={data.id}
              type="Point"
              variant='shape'
              coordinate={data?.position ? [data.position.longitude, data.position.latitude] : undefined}
              properties={{
                id: data.id
               //popUpProp: data,
              }}
              setCoords={data?.position ? [data.position.longitude, data.position.latitude] : undefined}
              setProperties={data?.geoLocation ? data.geoLocation : undefined}
            />
      };

      const renderCircle = (data:RenderData):IAzureMapFeature=>{
        // render circle as possible deviation radius
        return <AzureMapFeature
                  key={data.id}
                  id={data.id}
                  type="Point"
                  variant='shape'
                  coordinate={data?.position ? [data.position.longitude, data.position.latitude] : undefined}
                  properties={{
                    id: data.id,
                    subType: 'Circle',
                    radius: data.radius //meters
                    //popUpProp: data,
                  }}
                  setCoords={data?.position ? [data.position.longitude, data.position.latitude] : undefined}
                  setProperties={data?.geoLocation ? data.geoLocation : undefined}
               />
      };


      const updateUserCoordsHandler = useCallback((coords:NavigateResponse)=>{
        const error = (coords as ErrorMessage)?.message ?? null;
        if(error === Labels.DENIED_GEOLOCATION && !vehicleCoords.x && !vehicleCoords.y){
          return errorObserver(Labels.GEOLOCATION_UNDETERMINED);
        }
        if(error && error !== Labels.DENIED_GEOLOCATION){
          return errorObserver(error);
        }
        setUserCoords( prevCoords => ({...prevCoords , x:(coords as CoordsResponse).x , y:(coords as CoordsResponse).y}));
      }, [vehicleCoords]);


      useEffect(()=>{
        if(readyForUser){
          const getUserCoords = async ()=>{
            const userCoordsResult:NavigateResponse = await callNavigate();
            const error = (userCoordsResult as ErrorMessage)?.message ?? null;
            if(error === Labels.DENIED_GEOLOCATION && !vehicleCoords.x && !vehicleCoords.y){
              return errorObserver(Labels.GEOLOCATION_UNDETERMINED);
            }
            if(error && error !== Labels.DENIED_GEOLOCATION){
              return errorObserver(error);
            }
            if( !!(userCoordsResult as CoordsResponse).x && !!(userCoordsResult as CoordsResponse).y){
              setUserCoords(prev => ({...prev, x:(userCoordsResult as CoordsResponse).x , y:(userCoordsResult as CoordsResponse).y}) );
              setCameraCapture(true);
            }
          }
          getUserCoords();
          coordsListener.subscribe(updateUserCoordsHandler);

          return ()=>{
            coordsListener.unsubscribe();
          }
        }
      },[readyForUser]);

      const setCamera = (y:number , x:number)=>{
        mapRef!.setCamera({center:[y, x]});
      }

      // establish user position if mqtt either liberkee coordinates absent
      useEffect(()=>{
         if(cameraCapture && mapRef && isMapReady ){
            if(!!liberkeeVehicleLat && !!liberkeeVehicleLon && !mqttConfig){
               setVehicleCoords( prev => ({...prev , x:liberkeeVehicleLat , y:liberkeeVehicleLon}));
               return setCamera(liberkeeVehicleLon , liberkeeVehicleLat);
            }
            if(!!mqttConfig && !!vehicleCoords.x && !!vehicleCoords.y){
              return setCamera(vehicleCoords.y , vehicleCoords.x);
            }
            setCamera(userCoords.y , userCoords.x);
         }
      }, [cameraCapture , mapRef, isMapReady, liberkeeVehicleLat , liberkeeVehicleLon, mqttConfig]);

      useEffect(()=>{
        if(!!liberkeeLat && !!liberkeeLon){
          setVehicleCoords( prevCoords => ({...prevCoords , x:liberkeeLat , y:liberkeeLon}) );
        }
      }, [liberkeeLat, liberkeeLon]);

      useEffect(()=>{
        if(!!mqttLat && !!mqttLon){
          setVehicleCoords( prevCoords => ({...prevCoords , x:mqttLat , y:mqttLon}) );
        }
      }, [mqttLat, mqttLon]);

      useEffect(()=>{
        if(deviation){
          setDeviationValue(deviation);
        }
      }, [deviation]);


      const trafficOptions:TrafficOptions= useMemo(()=>{
        return {
          flow: 'none'
        };
      }, []);

      const styleOptions = {
        style: "satellite_road_labels" ,
        renderWorldCopies: true,
        showBuildingModels: true
      };

      const option:AuthenticationOptions = useMemo(()=>{
        return {
          authOptions: {
            authType: AuthenticationType.subscriptionKey,
            subscriptionKey: key
          },
          zoom: ZOOM,
          view: "Auto",
          showLabels: true,
        }
      }, []);


      const controls : IAzureMapControls[]= [
        {
          controlName: 'CompassControl',
          options: {
            position: ControlPosition.TopLeft
          }
        },
        {
          controlName: 'ZoomControl',
          options: {
           position: ControlPosition.BottomRight
          }
         },
        {
          controlName: 'TrafficControl',
          options: {
            position: ControlPosition.TopRight
          }
        },
        {
          controlName: 'TrafficLegendControl',
          options: {
            position: ControlPosition.BottomLeft
          }
        },
        {
          controlName: 'PitchControl',
          options: {
            position: ControlPosition.TopRight
          }
        }
    ];

    const getUserLocation = async ()=>{
      setLodaing(true);
      const userCoords:NavigateResponse = await callNavigate();
      const x = !!(userCoords as CoordsResponse)?.x;
      const y = !!(userCoords as CoordsResponse)?.y;
      if( x && y){
        const {x:userX, y:userY} = (userCoords as CoordsResponse);
        setUserCoords(prev => ({...prev, x:userX , y:userY}));
        setCamera(userY , userX);
        setLodaing(false);
        return;
      }
      if((userCoords as ErrorMessage)?.message){
        setLodaing(false);
        errorObserver((userCoords as ErrorMessage).message);
      }
    }

    const getVehicleLocation = async ()=>{
      setLodaing(true);
      try{
        if(!mqttConfig && vehicleId){
          await hubConnection?.send('getlocation' , vehicleId);
        }
        else if(!!mqttConfig && vehicleId){
          const {geolocation} = await AzureLocationService.determineMqttPresence(vehicleId);
          if(geolocation){
            const {latitude , longitude, margin} = geolocation[0];
            if(latitude && longitude && margin){
              setVehicleCoords( prevCoords => ({...prevCoords , x:latitude , y:longitude}) );
              setDeviationValue(margin);
              setCamera(longitude , latitude);
            }
          }
        }
        setLodaing(false);
      }catch(error:any){
        console.log(error);
        setLodaing(false);
        const {errorMessage} = extractErrorMessage(error);
        errorObserver(errorMessage || Labels.CoordinatesError);
      }
    }



    const markers:MarkersView = useMemo(()=>{

      const availableVehicleCoords = vehicleCoords.x && vehicleCoords.y;
      const availableUserCoords = userCoords.x && userCoords.y;

      return {
         vehicleLocation: availableVehicleCoords ? renderPoint(
           {
            id: 'Vehicle',
            position: {longitude: vehicleCoords.y, latitude: vehicleCoords.x},
            geoLocation: null
           }
         ) : null,
         personLocation: availableUserCoords ? renderPoint(
           {
            id: 'Person',
            position: {longitude: userCoords.y, latitude: userCoords.x},
            geoLocation: null
           }
         ) : null,
         pointsDistance: availableVehicleCoords && availableUserCoords ? renderLineString(
           {
            id: 'Points Distance',
            positions: [[ vehicleCoords.y, vehicleCoords.x],[ userCoords.y, userCoords.x]]
           }
         ) : null,
         circleArea:(typeof deviationValue === 'number' && !!mqttConfig) ? renderCircle(
          {
            id:'Circle Area',
            position:{longitude: vehicleCoords.y, latitude: vehicleCoords.x},
            radius:deviationValue
          }
        ) : null
      }
    }, [vehicleCoords, userCoords, deviationValue, mqttConfig]);

    const propertiesAccess = (mqttConfig?.mqtt && mqttConfig?.type === 'asset');

    useEffect(()=>{
      if(propertiesAccess){
        if(typeof zone === 'string' && typeof site === 'string'){
          setZoneName(zone);
          setSiteName(site);
        }
      }
    },[propertiesAccess, site, zone]);


    const rightBar:JSX.Element = useMemo(()=>{
     return (
           <div style={{visibility: propertiesAccess ? 'visible' : 'hidden'}}>
               <div>Site: {<label>{!!siteName ? siteName : Labels.KEINE_DATEN}</label>}</div>
               <div>Zone: {<label>{!!zoneName ? zoneName : Labels.KEINE_DATEN}</label>}</div>
           </div>
           );
    }, [propertiesAccess, zoneName, siteName]);

      return (
        <div>
          <div className={classes.btns_container}>
            <div>
              {  ( Boolean(userCoords.y) && Boolean(userCoords.x) ) &&
                <Tooltip placement='bottom' title="mein Standort">
                  <IconButton disabled={loading} className={classes.user_icon} onClick={getUserLocation}>
                    <MyLocationIcon />
                  </IconButton>
                </Tooltip>}
              {
                ( Boolean(vehicleCoords.y) && Boolean(vehicleCoords.x) ) &&
                <Tooltip placement='bottom' title="Standort des Fahrzeugs" onClick={getVehicleLocation}>
                  <IconButton disabled={loading} className={classes.car_icon}>
                    <CarIcon />
                  </IconButton>
                </Tooltip>
              }
            </div>
            {rightBar}
          </div>
            {
                <div style={{ height: '100vh', width: '100%' , margin: '10px 10px'}}>
                  <AzureMap
                    options={option as IAzureMapOptions}
                    controls={controls}
                    trafficOptions={readyForUser ? trafficOptions : undefined}
                    styleOptions={readyForUser ? styleOptions : undefined}
                    cameraOptions={{minZoom: 0, maxZoom: 24}}
                    events={{
                      ready:(e:any)=>{
                        setReadyForUser(true);
                      }
                    }}
                  >
                    <AzureMapDataSourceProvider
                     id={`SinglePoint AzureMapDataSourceProvider Circle`}
                    >
                      <AzureMapLayerProvider
                        id={`SinglePoint AzureMapLayerProvider Circle`}
                        options={{
                          fillOpacity: 0.45,
                          fillColor: '#aed7f5',
                          iconOptions: {
                          }
                        }}
                        events={{
                          mousemove: (e:any) => {
                           if (e.shapes && e.shapes.length > 0) {
                            const prop = e.shapes[0];
                            // Set popup options
                            setPopupOptions({
                                //...popupOptions,
                                position: new data.Position(
                                prop.data.geometry.coordinates[0],
                                prop.data.geometry.coordinates[1]
                                ),
                                pixelOffset: [0, -18]
                            });
                            // if (prop.data.properties)
                            // Set popup properties from Feature Properties that are declared on create Feature

                            //   setPopupProperties({
                            //     ...prop.data.properties.popUpProp,
                            //   });
                           }
                          }
                        }}
                        type="PolygonLayer"
                      />
                        {markers.circleArea}
                    </AzureMapDataSourceProvider>

                    <AzureMapDataSourceProvider
                      id={`MultiplePoint AzureMapDataSourceProvider Vehicle`}
                    >
                     <AzureMapLayerProvider
                      id={`MultiplePoint AzureMapLayerProvider Vehicle`}
                      options={{
                      iconOptions: {
                          image: "pin-blue"
                      }
                      }}
                      events={{
                      mousemove: (e:any) => {
                          if (e.shapes && e.shapes.length > 0) {
                          const prop = e.shapes[0];
                          // Set popup options
                          setPopupOptions({
                              //...popupOptions,
                              position: new data.Position(
                              prop.data.geometry.coordinates[0],
                              prop.data.geometry.coordinates[1]
                              ),
                              pixelOffset: [0, -18]
                          });
                          // if (prop.data.properties)
                          //   // Set popup properties from Feature Properties that are declared on create Feature
                          //   setPopupProperties({
                          //     ...prop.data.properties.popUpProp,
                          //   });
                          }
                      }
                      }}
                      type="SymbolLayer"
                    />
                       {markers.vehicleLocation}
                    </AzureMapDataSourceProvider>

                    <AzureMapDataSourceProvider
                      id={`MultiplePoint AzureMapDataSourceProvider Person`}
                    >
                      <AzureMapLayerProvider
                        id={`MultiplePoint AzureMapLayerProvider Person`}
                        options={{
                        iconOptions: {
                            image: "pin-red"
                        }
                        }}
                        events={{
                        mousemove: (e:any) => {
                            if (e.shapes && e.shapes.length > 0) {
                            const prop = e.shapes[0];
                            // Set popup options
                            setPopupOptions({
                                //...popupOptions,
                                position: new data.Position(
                                prop.data.geometry.coordinates[0],
                                prop.data.geometry.coordinates[1]
                                ),
                                pixelOffset: [0, -18]
                            });
                            // if (prop.data.properties)
                            //   // Set popup properties from Feature Properties that are declared on create Feature
                            //   setPopupProperties({
                            //     ...prop.data.properties.popUpProp,
                            //   });
                            }
                        }
                        }}
                        type="SymbolLayer"
                      />
                         {markers.personLocation}
                    </AzureMapDataSourceProvider>

                    <AzureMapDataSourceProvider
                      id={`MultiplePoint AzureMapDataSourceProvider Line`}
                    >
                      <AzureMapLayerProvider
                          id={`MultiplePoint AzureMapLayerProvider Line`}
                          options={{
                            strokeWidth:2,
                            lineCap: 'round',
                            strokeDashArray: [2 , 3 , 5]
                          }}
                          type="LineLayer"
                      />
                        {markers.pointsDistance}
                   </AzureMapDataSourceProvider>

                    <AzureMapPopup // here displayed information above marker , disabled default
                        // change by props, but not globally
                        isVisible={false}
                        options={popupOptions}
                        popupContent={
                            <div>
                                {/*popup content */}
                            </div>
                        }
                      />
                  </AzureMap>
                </div>
              }
           </div>
      );
}










