import { FunctionComponent, ReactElement } from "react";
import { Redirect, useLocation } from "react-router-dom";
import { RepairType } from "../../unit/constants";
import Orders from "./OrderList";

const getType = (search: string): number | null => {
  const type = new URLSearchParams(search).get("type");
  if (type !== null && !!RepairType[parseInt(type)]) {
    return parseInt(type);
  }

  return null;
};

const OrdersContainer: FunctionComponent = (): ReactElement => {
  const { search } = useLocation();
  const type = getType(search);
  if (type === null) {
    return <Redirect to="not-found" />;
  }
  return <Orders type={type} />;
};

export default OrdersContainer;
