import { useCallback } from "react";
import { useHistory } from "react-router-dom";

type Func = (message: string, to?:string, code?:string | undefined) => void;

const useErrorPage = (): Func => {
  const history = useHistory();

  const navigateToErrorPage: Func = useCallback((message: string, to?: string, code?:string | undefined): void => {
    history.push(`/error?message=${message}`, {
      from: to || '',
      error:'',
      code: code || 0
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return navigateToErrorPage;
};

export default useErrorPage;
