import { FunctionComponent, ReactElement } from 'react';
import { makeStyles, TextField, TextFieldProps } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  textField: {
    background: '#ffffff',

    '& div::before': {
      borderBottom: 'none !important'
    },

    '& div::after': {
      borderBottom: 'none !important'
    },

    '& input': {
      padding: '0px 12px 2px',
      height: '32px',
      textOverflow: 'ellipsis'
    }
  }
}));

const Input: FunctionComponent<TextFieldProps> = (props: TextFieldProps): ReactElement => {
  const classes = useStyles();
  return <TextField className={classes.textField} {...props} />;
};

export default Input;
