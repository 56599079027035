import { ChangeEvent, FunctionComponent, ReactElement, useState } from "react";
import {
  makeStyles,
  Button,
  Grid,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  CircularProgress,
  List,
} from "@material-ui/core";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import { isImgExtension , readFileAsBase64Async, removeBase64Prefix} from "../../unit/utils";
import damageService from "../../services/damage-service";
import SharepointService , { FileModel , SharepointFile } from "../../services/sharepoint-service";
import * as Labels from "../../unit/labels";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import ImageIcon from "@material-ui/icons/Image";
import DeleteIcon from "@material-ui/icons/Delete";
import CloseIcon from '@material-ui/icons/Close';
import { Alert } from "@material-ui/lab";
import { head } from "lodash";
import { useRef } from "react";

interface FormState {
  vehicleId: string;
  repairType: string;
  component: string;
  damage: string;
  description: string;
  calculationCost: string;
  imageId: string;
}

interface DamageFormProps {
  vehicleId: string;
  folderName: string;
  onCancel(): void;
}

const useStyles = makeStyles({
  grid_container:{
    position:'relative',
  },
  alert_grid:{
    position:'absolute',
    top:0,
    left:0,
    width:'100%',
    zIndex:10000,
  },
  btnContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    paddingTop: "20px",
    "& > button": {
      marginLeft: "20px",
    },
  },
});

const onlyImageMessage = "Nur Bild erlaubt!";

const DamageForm: FunctionComponent<DamageFormProps> = (
  props
): ReactElement => {
  const classes = useStyles();
  const { vehicleId, onCancel } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const [form, setForm] = useState<FormState>({
    vehicleId,
    repairType: "528970001",
    component: "",
    damage: "",
    description: "",
    calculationCost: "0",
    imageId: "",
  });

  // file state
  const [fileError, setFileError] = useState<string>("");
  const [file, setFile] = useState<File>();
  const [uploadErrorMessage , setUploadErrorMessage] = useState<string>("");
  const inputRef = useRef<HTMLInputElement>(null);

  const changeHandler = (
    event: ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ): void => {
    const name = event.target.name;
    if (!name) {
      return;
    }
    const value = event.target.value;
    if(name === "calculationCost" && value && !/^\d+\.?\d{0,2}$/g.test(value as string)){
      return;
    }

    setForm((form) => ({ ...form, [name]: value }));
  };

  const inputChangeHandler = (event: ChangeEvent<HTMLInputElement>): void => {
    const file = head(event.target.files);
    if (!file || !isImgExtension(file.name)) {
      setFileError(onlyImageMessage);
      return;
    }
    setFile(file);
    inputRef.current!.value = "";
  };

  const transformToUploadFileModel = async (file:File):Promise<FileModel>=>{
    const base64 = await readFileAsBase64Async(file);
    return  {
      id:null,
      folders: [{recordId:vehicleId!, entityName:"lueg_fahrzeug"}],
      document: {
        documentTypeId: 'F1E0BCC8-94AE-4D14-A3FA-0849D631674E',
        comment:'',
        isVisible: true,
        isCustomersPortalVisible: false,
        isEnvelopeCreated: false,
        isSigned: false,
        isSignedWithKabema: false,
      },
      file: {
        body: removeBase64Prefix(base64.body),
        name: base64.name,
      },
      forceUpload: false,
    };
  }

  const submitHandler = async (): Promise<void> => {
    if(!file){
      return setUploadErrorMessage(Labels.FileMissingError);
    }
    setLoading(true);
    try {
      const fileModel = await transformToUploadFileModel(file);
      const {retainedFile , statusCode} = await damageService.uploadImage(fileModel);
      if(statusCode === 422 || statusCode === 500){
        return setUploadErrorMessage(statusCode === 422 ? Labels.DocumentDownloadError : Labels.ContactItSupportError);
      }
      let currentFile = retainedFile;
      if(statusCode === 409){
        const { documentId } = currentFile as SharepointFile;
        const {file, ...rest} = fileModel as FileModel;
        const response = await SharepointService.uploadDocument({...rest, id:documentId }, false);
        currentFile = response.retainedFile;
      }
      const imageId = currentFile.documentId;
      await damageService.create({ ...form, imageId });
      window.dispatchEvent(new Event("refresh-vehicle-table"));
      onCancel();
    } catch (error) {
      setUploadErrorMessage(Labels.FileUploadingError);
    }finally{
      setLoading(false);
    }
  };

  const removeFile = (): void => {
    setFile(undefined);
    inputRef.current!.value = "";
  };

  return (
    <>
      <Grid container spacing={3} className={classes.grid_container}>
        {
         uploadErrorMessage &&
         <Grid item xs={12} className={classes.alert_grid}>
           <Alert
              style={{borderRadius:0}}
              severity="error"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setUploadErrorMessage('');
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
             {uploadErrorMessage}
            </Alert>
         </Grid>
        }
        <Grid item xs={12}>
          <TextField
            name="component"
            label="Bauteil"
            fullWidth
            value={form.component}
            onChange={changeHandler}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="damage"
            label="Schaden"
            fullWidth
            value={form.damage}
            onChange={changeHandler}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="description"
            label="Beschreibung"
            fullWidth
            value={form.description}
            onChange={changeHandler}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="calculationCost"
            label="Kosten (Berechnung)"
            fullWidth
            value={form.calculationCost}
            onChange={changeHandler}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl style={{ width: "100%" }}>
            <InputLabel id="repair-type">Reparaturweg</InputLabel>
            <Select
              labelId="repair-type"
              id="repair-type"
              name="repairType"
              onChange={changeHandler}
              value={form.repairType}
              fullWidth
            >
              <MenuItem value="528970001">Smart Repair</MenuItem>
              <MenuItem value="528970002">Lackierung</MenuItem>
              <MenuItem value="528970003">Werkstatt</MenuItem>
              <MenuItem value="528970004">Aufbereitung</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          {file && (
            <List dense>
              <ListItem>
                <ListItemAvatar>
                  <Avatar>
                    <ImageIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={file.name} />
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={removeFile}
                  >
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            </List>
          )}
          {fileError && <Alert severity="error">{fileError}</Alert>}
          <Button component="label" startIcon={<AttachFileIcon />}>
            Datei hochladen
            <input
              ref={inputRef}
              type="file"
              hidden
              accept="image/*"
              onChange={inputChangeHandler}
            />
          </Button>
        </Grid>
      </Grid>
      <div className={classes.btnContainer}>
        <Button
          disabled={loading}
          variant="contained"
          color="primary"
          onClick={submitHandler}
          startIcon={loading ? <CircularProgress size={19} /> : null}
        >
          {Labels.Save}
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={onCancel}
          disabled={loading}
        >
          Abbrechen
        </Button>
      </div>
    </>
  );
};

export default DamageForm;
