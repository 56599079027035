import React, { FunctionComponent, ReactElement, useState } from "react";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import { Button, CircularProgress } from "@material-ui/core";
import orderService, { OrderDetails } from "../../services/order-service";
import { useErrorPage } from "../../hooks";
import { getErrorMessage } from "../../unit/utils";
import * as Labels from '../../unit/labels';

interface StartOrderWorkButtonProps {
  visible: boolean;
  id: string;
  onStart(order: OrderDetails): void;
}

const StartOrderWorkButton: FunctionComponent<StartOrderWorkButtonProps> = (
  props
): ReactElement | null => {
  const { visible, id, onStart } = props;
  const navigateToErrorPage = useErrorPage();
  const [loading, setLoading] = useState<boolean>(false);


  if (!visible) {
    return null;
  }



  const clickHandler = async (): Promise<void> => {
    try {
      // TODO: Update order
      setLoading(true);
      const order = await orderService.startOrderWork(id);
      onStart(order);
      setLoading(false);
    } catch (err) {
      navigateToErrorPage(getErrorMessage(err));
    }
  };
  return (
    <Button
      variant="contained"
      color="primary"
      onClick={clickHandler}
      startIcon={loading ? <CircularProgress size={19} /> : <PlayCircleOutlineIcon />}
    >
      {Labels.Start}
    </Button>
  );
};

export default StartOrderWorkButton;
