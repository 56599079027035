import { FunctionComponent, ReactElement, useCallback, useState, ReactNode} from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import Header from './header';
import AppNavDrawer from './app-nav-drawer';
import ErrorBoudary from './error-boundary';
import {withRouter , RouteComponentProps} from 'react-router-dom';


type AppFrameProps = {
  children: ReactNode
} & RouteComponentProps

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      height: '100%'
    },
    content: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      width: '100%'
    },
    headerSpacer: theme.mixins.toolbar
  })
);

const AppFrame: FunctionComponent<AppFrameProps> = (props: AppFrameProps): ReactElement => {
  const {children} = props
  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleNavDrawerOpen = (): void => {
    setMobileOpen(true);
  };

  const handleNavDrawerClose = useCallback(() => {
    setMobileOpen(false);
  }, []);

  return (
    <div className={classes.root}>
      <Header onOpen={handleNavDrawerOpen} />
      <AppNavDrawer onClose={handleNavDrawerClose} mobileOpen={mobileOpen} />
      <main className={classes.content}>
        <ErrorBoudary>
          <div className={classes.headerSpacer} />
          {children} 
        </ErrorBoudary>
      </main>
    </div>
  );
};

export default withRouter(AppFrame);
