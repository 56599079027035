import { FunctionComponent, ReactElement } from "react";
import {
  TableCell,
  TableRow,
  Typography,
  TableCellProps,
} from "@material-ui/core";

interface ErrorRowProps {
  message: string;
  tableCellProps?: TableCellProps;
}

const ErrorRow: FunctionComponent<ErrorRowProps> = (
  props
): ReactElement | null => {
  const { message, tableCellProps } = props;
  if (!message) {
    return null;
  }

  return (
    <TableRow>
      <TableCell align='center' {...tableCellProps}>
        <Typography>{message}</Typography>
      </TableCell>
    </TableRow>
  );
};

export default ErrorRow;
