import { FunctionComponent, ReactElement } from "react";
import { makeStyles } from "@material-ui/core";
import bgVideo from '../videos/future_vision.mp4';
import {useSelector} from 'react-redux';
import {RootState} from '../reducers';

const useStyles = makeStyles({
  main:{
     display: 'flex',
     flexGrow:1,
     margin:0,
     padding:0,
     fontFamily: 'Impact , Werdana , sans-serif',
     position: 'relative',
     '& video':{
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      margin:0,
      padding:0,
     }
  },
  content:{
    position: 'absolute',
    left: 0,
    top: 0,
    right:0,
    bottom: 0,
    display: 'flex',
    justifyContent:'center',
    fontSize: 27,
    alignItems: 'flex-start',
    color: 'white'
  }
});

const Home: FunctionComponent = (): ReactElement => {
 const fullName = useSelector<RootState , string>( state => state.profile.fullName);
 const classes = useStyles();

  return <div className={classes.main}>
           <video src={bgVideo} autoPlay loop muted />
           <div className={classes.content}>{`Willkommen , ${fullName}`}</div>
         </div>;
};

export default Home;
