import { useSelector } from "react-redux";
import { RootState } from "../reducers";

const useUserRoles = (): number[] => {
  const roles = useSelector<RootState, number[]>(
    (state: RootState) => state.profile.roles
  );
  

  return roles;
};

export default useUserRoles;
