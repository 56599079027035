import axios  from 'axios';
import authService from './auth-service';
import { AZURE_MAPS_LOCATION } from '../urls';
import { LocationResponse } from '../hooks/use-signalR';
import { MqttConnectionData } from '../types';


class AzureLocationService {

    public async getVehicleCoordinates(vehicleId:string):Promise<LocationResponse>{
      const authHeader = await authService.getAuthHeader();
      const result =  await axios({
        url: `${AZURE_MAPS_LOCATION}/api/location`,
        method: 'GET',
        params:{
          vehicleId
        },
        headers: {
          Authorization: authHeader,
        }
      });
      return result.data;
    }

    public determineMqttPresence = async(vehicleId:string):Promise<MqttConnectionData>=>{
      const authHeader = await authService.getAuthHeader();
      const response = await axios({
        url: `${AZURE_MAPS_LOCATION}/api/smartmakers/geolocation`,
        method: 'POST',
        data:{
          entityName:'lueg_fahrzeug',
          recordId:vehicleId,
          currentUserId:''
        },
        headers: {
            Authorization: authHeader,
        },
      });
      return response.data;
    }

}

const azureLocationService = new AzureLocationService();
export default azureLocationService;