import { Action } from '../types';
import {Location , DefaultLocationResponse, Services} from '../services/calendar-service';
import { Task } from "calendar/dist";
import { SET_CALENDAR_CONFIG , SET_ACTIVE_LOCATION , SET_DEFAULT_LOCATION, SET_WEEK_START_DATE, SET_EVENTS} from '../actions/locations';
import { startOfWeek } from "date-fns";
import { de } from "date-fns/locale";

export interface LocationsState {
  weekStartDate:Date;
  locations:Location[];
  events: Task[];
  activeLocation:Location | null;
  defaultLocation:DefaultLocationResponse | null;
  services:Services[];
};

const INITIAL_STATE: LocationsState = {
    weekStartDate:startOfWeek(new Date(), { locale: de }),
    locations: [],
    events:[],
    activeLocation: null,
    defaultLocation:null,
    services:[]
};

const locationsReducer = (state: LocationsState = INITIAL_STATE, action: Action): LocationsState => {
  switch (action.type) {
    case SET_CALENDAR_CONFIG:
        return {...state , ...action.payload};
    case SET_ACTIVE_LOCATION:
        return {...state , activeLocation:action.payload.activeLocation};
    case SET_DEFAULT_LOCATION:
        return {...state , defaultLocation:action.payload.defaultLocation};
    case SET_WEEK_START_DATE:
        return {...state , weekStartDate:action.payload.weekStartDate};
    case SET_EVENTS:
        return {...state , events:[...action.payload.events]}
    default:
      return state;
  }
};

export default locationsReducer;