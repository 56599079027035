import { FunctionComponent, ReactElement } from "react";
import { useHistory } from "react-router-dom";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {DekraLocation} from '../../services/dekra-locations-service';

import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles({
    row:{
      cursor:'pointer'
    }
  });

interface LocationRowProps{
    location:DekraLocation
}

const LocationRow: FunctionComponent<LocationRowProps> = ({location}): ReactElement => {
  const { id, name, dekraCode, parentLocationName  } = location;
  const history = useHistory();
  const classes = useStyles();
  
  const clickHandler = (): void => {
    history.push(`/location/${id}`);
  };

  return (
    <TableRow hover role="checkbox" tabIndex={-1} onClick={clickHandler} className={classes.row}>
      <TableCell align='left'>{name}</TableCell>
      <TableCell align="center">{dekraCode}</TableCell>
      <TableCell align="center">{parentLocationName}</TableCell>
    </TableRow>
  );
};

export default LocationRow;
