import { FunctionComponent, ReactElement } from "react";
import {
  TableCell,
  TableRow,
  TableCellProps,
  CircularProgress,
} from "@material-ui/core";

interface LoadingRowProps {
  loading: boolean;
  tableCellProps?: TableCellProps;
}

const LoadingRow: FunctionComponent<LoadingRowProps> = (
  props
): ReactElement | null => {
  const { loading, tableCellProps } = props;

  if (!loading) {
    return null;
  }

  return (
    <TableRow>
      <TableCell {...tableCellProps} align="center">
        <CircularProgress />
      </TableCell>
    </TableRow>
  );
};

export default LoadingRow;
