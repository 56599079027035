import { FunctionComponent, ReactElement } from "react";
import {LogistikRecord} from '../../services/logistic-service';
import { navigateToLogisticDetails } from "../navigate";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import { format } from "date-fns";
import deLocale from "date-fns/locale/de";
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import clsx from 'clsx';


export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    plug:{cursor:'pointer'},
    highlight_red:{
      backgroundColor: '#fcaeae'
    }
  })
);

const Row: FunctionComponent<LogistikRecord> = (props): ReactElement => {
  const { id, name, loadingPoint, unloadingPoint, status, loadingDate, sapNumber, varkauf } = props;
  const classes = useStyles();

  const clickHandler = (): void => {
    navigateToLogisticDetails(id)
  };

  return (
    <TableRow hover role="checkbox" tabIndex={-1} onClick={clickHandler} className={clsx(classes.plug, {[classes.highlight_red]: varkauf === 'Verkauf'})}>
      <TableCell>{name}</TableCell>
      <TableCell>{!!sapNumber ? sapNumber : ''}</TableCell>
      <TableCell>{loadingPoint}</TableCell>
      <TableCell>{unloadingPoint}</TableCell>
      <TableCell>{varkauf}</TableCell>
      <TableCell align="center">{status}</TableCell>
      <TableCell align='right'>
        { loadingDate ? format(new Date(loadingDate), "dd.MM.yyyy", { locale: deLocale }) : ''}
     </TableCell>
    </TableRow>
  );
};

export default Row;
