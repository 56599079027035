import { ReactElement, FunctionComponent, SVGProps } from "react";

const Calendar: FunctionComponent<SVGProps<SVGSVGElement>> = (
  props
): ReactElement => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      {...props}
    >
      <path
        d="M23.1727 6.4173V3.70747C23.1727 3.16551 22.8114 2.8042 22.2694 2.8042C21.7274 2.8042 21.3661 3.16551 21.3661 3.70747V6.4173H10.5268V3.70747C10.5268 3.16551 10.1655 2.8042 9.62355 2.8042C9.08159 2.8042 8.72028 3.16551 8.72028 3.70747V6.4173C6.91373 6.77861 5.46849 8.22385 5.10718 10.0304H26.7858C26.4245 8.22385 24.9792 6.77861 23.1727 6.4173Z"
        fill="#5F6466"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.07514 11.9166H26.9434V26.8599C26.9434 28.1624 25.8875 29.2183 24.585 29.2183H7.43351C6.13102 29.2183 5.07514 28.1624 5.07514 26.8599V11.9166ZM12.088 17.818C12.088 18.8159 11.279 19.6248 10.2812 19.6248C9.28329 19.6248 8.47436 18.8159 8.47436 17.818C8.47436 16.8202 9.28329 16.0112 10.2812 16.0112C11.279 16.0112 12.088 16.8202 12.088 17.818ZM16.0093 19.6249C17.0072 19.6249 17.8161 18.8159 17.8161 17.818C17.8161 16.8202 17.0072 16.0112 16.0093 16.0112C15.0114 16.0112 14.2025 16.8202 14.2025 17.818C14.2025 18.8159 15.0114 19.6249 16.0093 19.6249ZM23.5442 17.818C23.5442 18.8159 22.7353 19.6249 21.7374 19.6249C20.7395 19.6249 19.9306 18.8159 19.9306 17.818C19.9306 16.8202 20.7395 16.0112 21.7374 16.0112C22.7353 16.0112 23.5442 16.8202 23.5442 17.818ZM10.2812 25.1262C11.279 25.1262 12.088 24.3173 12.088 23.3194C12.088 22.3215 11.279 21.5126 10.2812 21.5126C9.28329 21.5126 8.47436 22.3215 8.47436 23.3194C8.47436 24.3173 9.28329 25.1262 10.2812 25.1262ZM17.8161 23.3194C17.8161 24.3173 17.0072 25.1262 16.0093 25.1262C15.0114 25.1262 14.2025 24.3173 14.2025 23.3194C14.2025 22.3215 15.0114 21.5126 16.0093 21.5126C17.0072 21.5126 17.8161 22.3215 17.8161 23.3194ZM21.7374 25.1262C22.7353 25.1262 23.5442 24.3173 23.5442 23.3194C23.5442 22.3215 22.7353 21.5126 21.7374 21.5126C20.7395 21.5126 19.9306 22.3215 19.9306 23.3194C19.9306 24.3173 20.7395 25.1262 21.7374 25.1262Z"
        fill="#5F6466"
      />
    </svg>
  );
};

export default Calendar;
