import { ReactElement, FunctionComponent, SVGProps } from 'react';

const CalendarIcon: FunctionComponent<SVGProps<SVGSVGElement>> = (props): ReactElement => {
  return (
    <svg viewBox='-6 -6 32 32' role='presentation' focusable='false' {...props}>
      <g fill='#fff'>
        <g>
          <path d='M5.5 3C4.11929 3 3 4.11929 3 5.5V6H17V5.5C17 4.11929 15.8807 3 14.5 3H5.5Z' />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M17 7H3V14.5C3 15.8807 4.11929 17 5.5 17H14.5C15.8807 17 17 15.8807 17 14.5V7ZM8 10C8 10.5523 7.55228 11 7 11C6.44772 11 6 10.5523 6 10C6 9.44771 6.44772 9 7 9C7.55228 9 8 9.44771 8 10ZM7 14C7.55228 14 8 13.5523 8 13C8 12.4477 7.55228 12 7 12C6.44772 12 6 12.4477 6 13C6 13.5523 6.44772 14 7 14ZM11 10C11 10.5523 10.5523 11 10 11C9.44771 11 9 10.5523 9 10C9 9.44771 9.44771 9 10 9C10.5523 9 11 9.44771 11 10ZM10 14C10.5523 14 11 13.5523 11 13C11 12.4477 10.5523 12 10 12C9.44771 12 9 12.4477 9 13C9 13.5523 9.44771 14 10 14ZM14 10C14 10.5523 13.5523 11 13 11C12.4477 11 12 10.5523 12 10C12 9.44771 12.4477 9 13 9C13.5523 9 14 9.44771 14 10Z'
          />
        </g>
      </g>
    </svg>
  );
};

export default CalendarIcon;
