import { FunctionComponent, ReactElement } from "react";
import { OrderTo } from "../../services/order-service";
import { useHistory } from "react-router-dom";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import { format } from "date-fns";
import deLocale from "date-fns/locale/de";
import clsx from 'clsx';
import {useStyles} from '../logistic-list/Row';

interface RowProps{
  order:OrderTo
}

const Row: FunctionComponent<RowProps> = ({order}): ReactElement => {
  const { id, number, status, createdOn, vehicleManufacturer, vehicleModel, sapNumber, varkauf } = order;
  const history = useHistory();
  const classes = useStyles();
  
  const clickHandler = (): void => {
    history.push(`/auftrage-an/${id}`);
  };

  return (
    <TableRow hover role="checkbox" tabIndex={-1} onClick={clickHandler} className={clsx(classes.plug, {[classes.highlight_red]: varkauf === 'Verkauf'})}>
      <TableCell>{number}</TableCell>
      <TableCell>{!!sapNumber ? sapNumber : ''}</TableCell>
      <TableCell>{vehicleManufacturer}</TableCell>
      <TableCell>{vehicleModel}</TableCell>
      <TableCell>{varkauf}</TableCell>
      <TableCell>{status}</TableCell>
      <TableCell align="right">
        {format(new Date(createdOn), "dd.MM.yyyy", { locale: deLocale })}
      </TableCell>
    </TableRow>
  );
};

export default Row;
