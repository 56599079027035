import axios from "axios";

import authorizationService from "./auth-service";
import { SHAREPOINT_API_URL, IDENTITY_SERVER_URL } from "../urls";

export interface FileModel{
  id: string | null,
  folders: {recordId:string, entityName:string}[],
  document: {
    documentTypeId: string,
    comment: string,
    isVisible: boolean,
    isCustomersPortalVisible: boolean,
    isEnvelopeCreated: boolean,
    isSigned: boolean,
    isSignedWithKabema?: boolean,
    isLuegShared?: boolean,
    modifiedOn?: string,
    modifiedBy?: string,
    externalReference?: string,
  },
  file?: {
    body: string,
    name: string,
  },
  forceUpload?: boolean;
};

export interface SharepointFile {
  documentId: string;
  externalReference: null | string;
  isVisible: boolean;
  isCustomersPortalVisible: boolean;
  isSigned: boolean;
  isEnvelopeCreated: boolean;
  isSignedWithKabema: boolean;
  createdBy?: string;
  createdOn?: string;
  modifiedBy: string;
  modifiedOn: string;
  comment: string | null;
  documentType: string;
  documentTypeId: string;
  extension: string;
  thumbnail?:string;
};

export interface SharepointFileState{
  retainedFile:SharepointFile;
  statusCode:number;
};

// TODO: Interfaces and test this method
// TODO: Documentation
class SharepointService {
  /**
   * Returns list of documents inside of the sharepoint's folder
   * @param library - name of folder(library), name of folder is stored inside of attribute (Example: ANP2021362L035-001)
   * @param entityName - entity logical name of connected entity(Example: lueg_auftrag_an)
   */
  public async fetchDocuments(
    entityId: string,
    entityName: string
  ): Promise<SharepointFile[]> {
    const authorizationHeaderValue = await authorizationService.getAuthHeader();
    const response = await axios({
      method: "GET",
      url: `${SHAREPOINT_API_URL}/api/documents/${entityName}/${entityId}`,
      headers: {
        Authorization: authorizationHeaderValue,
      },
    });
    return response.data;
  }



  /**
   * Uploads sharepoint document do specified folder
   * @param file -  sharepoint document
   */
  public async uploadDocument(file: FileModel, validation:boolean): Promise<SharepointFileState> {
    return await new Promise(async (resolve, reject)=>{
      const authorizationHeaderValue = await authorizationService.getAuthHeader();
      const response = await axios.post(`${SHAREPOINT_API_URL}/api/documents/upload`, file, {
        headers: {
          Authorization: authorizationHeaderValue
        },
        validateStatus:()=> true
      });
      if(validation && [409, 200, 500, 422].indexOf(response.status) > -1){
        return resolve({
                retainedFile:response.data,
                statusCode:response.status
              });
      }
      if(!validation && response.status === 200){
        return resolve({
                retainedFile:response.data,
                statusCode:response.status
              });
      }

      return reject({
        retainedFile:response.data,
        statusCode:response.status
      });

    })
}

  /*
   * Opens protected with authentication file in new browser tab
   * @param id - file id which will be opned
   * @param name - file name.  used as tab name in browser
   */
  public async browseFile(id: string): Promise<void> {
    const token = await this.acquireFileToken(id);
    window.open(
      `${SHAREPOINT_API_URL}/api/documents/file/${token}`,
      "_blank"
    );
  }

  /**
   * Gets short living jwt-token which used for accessing sharepoint files
   * @param id - sharepoint file id
   * @returns short living file's jwt-token for accessing sharepoint file
   */
  private async acquireFileToken(id: string): Promise<string> {
    const authorizationHeaderValue = await authorizationService.getAuthHeader();
    const response = await axios.get(
      `${IDENTITY_SERVER_URL}/api/token?id=${id}`,
      {
        headers: {
          Authorization: authorizationHeaderValue,
        },
      }
    );

    return response.data;
  }

  /**
   * Returns base64 representations of small file. For pdf, words, exels, it's image of first page
   * For images it's just small sized image
   * @param id - file id
   * @param entityLogicalName - entity logical name of folder
   * @returns {PreviewResponse} image base64 representation and file id
   */
  public async fetchFilePreview(
    documentId: string
  ): Promise<SharepointFile> {
    const authorizationHeaderValue = await authorizationService.getAuthHeader();
    const response = await axios.get(
      `${SHAREPOINT_API_URL}/api/documents/${documentId}`,
      {
        params:{
          withThumbnail:true,
        },
        headers: {
          Authorization: authorizationHeaderValue,
        },
      }
    );

    return response.data;
  }
}

const sharepointService = new SharepointService();
export default sharepointService;
