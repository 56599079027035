import { FunctionComponent , ReactElement} from 'react'
import { Vehicle} from '../../../services/logistic-service';
import { Grid, TextField} from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  plug:{},
  highlight_red: {
     '& #allradantrieb-text':{
        color: 'red'
     },
     '& #verkauft_logistic_field':{
       color: 'red'
     }
  },
}));


interface VehicleDetailsProps{
    vehicleDetails: Vehicle,
    driverRole: boolean,
    value: string | null
}

const VehicleDetails: FunctionComponent<VehicleDetailsProps> = (props:VehicleDetailsProps): ReactElement => {
    const { branch, broad, weight, height, length, manufacturer, model, varkauf} = props.vehicleDetails
    const classes = useStyles()


    return (
      <Grid  container spacing={3}>
        <Grid item xs={12}>
          <TextField label="Hersteller" disabled fullWidth defaultValue={manufacturer || "---"} />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Verkaufsbezeichnung"
            disabled
            fullWidth
            defaultValue={model || "---"}
          />
        </Grid>
        <Grid item xs={12}>
         <div className={clsx(classes.plug, {[classes.highlight_red]: varkauf === 'Verkauf'})}>
          <TextField
            error={varkauf === 'Verkauf'}
            id='verkauft_logistic_field'
            label="Verkauft"
            disabled
            fullWidth
            defaultValue={varkauf || "---"}
          />
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.highlight_red}>
            <TextField
              error
              fullWidth
              disabled
              id='allradantrieb-text'
              label="Allradantrieb"
              defaultValue={props.value === 'A' ? 'Ja' : 'Nein'}
            />
          </div>
        </Grid>
        <Grid item xs={12}>
          {!props.driverRole && <TextField label="Sparte" disabled fullWidth defaultValue={branch || "---"} />}
        </Grid>
        <Grid item xs={12}>
          <TextField label="Höhe (mm)" disabled fullWidth defaultValue={height || "---"} />
        </Grid>
        <Grid item xs={12}>
          <TextField label="Länge (mm)" disabled fullWidth defaultValue={length || "---"} />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Breite (mm)"
            disabled
            fullWidth
            defaultValue={broad || "---"}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField label="Gewicht (unbeladen; kg)" disabled fullWidth defaultValue={weight || "---"} />
        </Grid>
      </Grid>
    );
  };

  export default VehicleDetails;