import { AnyAction } from 'redux';
import { SET_LOGISTIK_CHANGES , SET_RAMP_CHANGES, SET_ACTIVE_TAB} from '../actions/edit';

interface ModifiedDictionary{
  [key:string]:any;
}
export interface LogistikEditableParams{
    logistikId: string;
    changedValue: ModifiedDictionary;
}

export interface RampEditableParams{
    rampId:string;
    changedValue: ModifiedDictionary;
}

export interface InitialState {
  editableLogistik : LogistikEditableParams;
  editableRamp:RampEditableParams;
  activeLocationTab:number;
}

const INITIAL_STATE: InitialState = {
  editableLogistik :{
    logistikId: '',
    changedValue: {}
  },
  editableRamp:{
    rampId:'',
    changedValue: {}
  },
  activeLocationTab:0
};


const editReducer = (state: InitialState = INITIAL_STATE, action: AnyAction): InitialState => {
  switch (action.type) {
    case SET_LOGISTIK_CHANGES:
      return { ...state, editableLogistik: {...state.editableLogistik , logistikId: action.payload.logistikId , changedValue: action.payload.changedValue}};
    case SET_RAMP_CHANGES:
      return { ...state , editableRamp: {...state.editableRamp , rampId:action.payload.rampId , changedValue: action.payload.changedValue}};
    case SET_ACTIVE_TAB:
      return { ...state , activeLocationTab:action.payload };
    default:
      return state;
  }
};

export default editReducer;
