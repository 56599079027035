import { ReactElement, FunctionComponent, SVGProps } from "react";

const Twinner: FunctionComponent<SVGProps<SVGSVGElement>> = (
  props
): ReactElement => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      {...props}
    >
      <ellipse cx="16" cy="17.7231" rx="4.53941" ry="4.53941" fill="#5F6466" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.99285 8.70849C8.31312 8.70849 8.61402 8.55508 8.80215 8.29588L10.886 5.42475C11.0741 5.16555 11.375 5.01215 11.6953 5.01215H20.6135C20.9532 5.01215 21.2696 5.18458 21.4538 5.47002L23.2477 8.25061C23.4319 8.53605 23.7483 8.70849 24.088 8.70849H28.7574C29.3097 8.70849 29.7574 9.15621 29.7574 9.70849V25.9878C29.7574 26.5401 29.3097 26.9878 28.7574 26.9878H3.24258C2.6903 26.9878 2.24258 26.5401 2.24258 25.9878V9.70849C2.24258 9.15621 2.6903 8.70849 3.24258 8.70849H7.99285ZM22.8646 17.723C22.8646 21.5142 19.7912 24.5876 16 24.5876C12.2088 24.5876 9.13536 21.5142 9.13536 17.723C9.13536 13.9318 12.2088 10.8583 16 10.8583C19.7912 10.8583 22.8646 13.9318 22.8646 17.723ZM24.3008 11.0365C23.8502 11.0365 23.4848 11.4019 23.4848 11.8526C23.4848 12.3033 23.8502 12.6686 24.3008 12.6686H25.6911C26.1418 12.6686 26.5071 12.3033 26.5071 11.8526C26.5071 11.4019 26.1418 11.0365 25.6911 11.0365H24.3008Z"
        fill="#5F6466"
      />
    </svg>
  );
};

export default Twinner;
