import React, { useState, ComponentType, ReactElement } from 'react';
import { Button, Snackbar , makeStyles} from '@material-ui/core';

type HorizontalPlacement = 'center' | 'left' | 'right';
type VerticalPlacement = 'top' | 'bottom';

interface SnackbarState {
  open: boolean;
  message: string;
  vertical: VerticalPlacement;
  horizontal: HorizontalPlacement;
};

const snackbarDefaultState: SnackbarState = {
  open: false,
  message: '',
  vertical: 'bottom',
  horizontal: 'right'
};

export interface SnackbarProps {
  showSnackbar(message: string, vertical?:VerticalPlacement, horizontal?:HorizontalPlacement): void;
};

const useStyles = makeStyles({
  snackbar:{
    backgroundColor:'#fcde9d',
    color:'black'
  }
});

export function withSnackbar<P>(Component: ComponentType<P>) {
  return function (props: Omit<P, 'showSnackbar'>): ReactElement {
    const [snackbarState, setSnackbarState] = useState<SnackbarState>(snackbarDefaultState);

    const hideSnackbar = (event: React.SyntheticEvent | React.MouseEvent, reason?: string): void => {
      if (reason === 'clickaway') {
        return;
      }
      closeSnackbar();
    };

    const showSnackbar = (message: string, vertical?:VerticalPlacement, horizontal?:HorizontalPlacement): void => {
      setSnackbarState({ open: true, message, vertical:vertical ?? 'bottom', horizontal: horizontal ?? 'right'});
    };

    const closeSnackbar = (): void => {
      setSnackbarState( prevState => ({...prevState , ...snackbarDefaultState}));
    };

    return (
      <>
        <Snackbar
          open={snackbarState.open}
          anchorOrigin={snackbarState.open ?
            {
            vertical: snackbarState.vertical,
            horizontal: snackbarState.horizontal
           } : undefined
          }
          autoHideDuration={10000}
          transitionDuration={{
            exit:0,
            appear:0
          }}
          ContentProps={{
            className:useStyles().snackbar
          }}
          onClose={hideSnackbar}
          message={snackbarState.message}
          action={
            <>
              <Button style={{color:'#875d03'}} size='small' onClick={closeSnackbar}>
                Schließen
              </Button>
            </>
          }
        />
         <Component {...(props as P)} showSnackbar={showSnackbar} />
      </>
    );
  };
}
