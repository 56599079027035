import { ReactElement, FunctionComponent, SVGProps } from "react";

const Locations: FunctionComponent<SVGProps<SVGSVGElement>> = (
  props
): ReactElement => {
  return (
    <svg 
      fill="#000000" 
      height="32px" 
      width="32px" 
      version="1.1" 
      id="Layer_1" 
	  viewBox="0 0 424.172 424.172"
      {...props}
     >
      <g>
        <path d="M383.75,229.503c0,3.555-0.123,7.113-0.357,10.66c10.52,1.213,20.425,4.465,29.305,9.366
            c0.697-6.64,1.053-13.34,1.053-20.026c0-38.629-11.551-75.798-33.403-107.488c-19.242-27.904-45.254-49.922-75.679-64.181
            c-0.358,10.368-2.752,20.575-6.972,29.992C350.445,115.086,383.75,169.133,383.75,229.503z"/>
        <path d="M151.572,87.429c-4.172-9.448-6.505-19.687-6.8-30.076c-33.253,15.37-61.478,40.182-81.042,71.229
            c10.372,1.802,20.062,5.602,28.662,10.998C107.461,117.537,127.857,99.582,151.572,87.429z"/>
        <path d="M308.387,365.065c-25.212,15.732-54.039,23.989-84.189,23.989c-61.719,0-117.555-35.421-144.003-90.769l-20.314,25.79
            c33.555,58.333,95.893,94.979,164.317,94.979c36.886,0,72.112-10.408,102.703-30.21l-15.516-19.697
            C310.336,367.82,309.346,366.452,308.387,365.065z"/>
        <path d="M224.725,135.877l39.221-49.791c7.058-8.925,10.779-19.625,10.779-30.968c0-27.57-22.43-50-50-50s-50,22.43-50,50
            c0,11.343,3.721,22.043,10.762,30.945L224.725,135.877z M224.725,40.213c8.219,0,14.905,6.686,14.905,14.905
            c0,8.219-6.686,14.905-14.905,14.905c-8.219,0-14.905-6.686-14.905-14.905C209.82,46.899,216.506,40.213,224.725,40.213z"/>
        <path d="M100,207.378c0-27.57-22.43-50-50-50c-27.57,0-50,22.43-50,50c0,11.343,3.721,22.044,10.761,30.946L50,288.137
            l39.221-49.791C96.279,229.421,100,218.72,100,207.378z M50,222.283c-8.219,0-14.905-6.687-14.905-14.905
            c0-8.219,6.686-14.905,14.905-14.905c8.219,0,14.905,6.686,14.905,14.905C64.905,215.596,58.218,222.283,50,222.283z"/>
        <path d="M374.172,269.615c-27.57,0-50,22.43-50,50c0,11.343,3.721,22.043,10.762,30.945l39.238,49.813l39.221-49.791
            c7.058-8.925,10.779-19.625,10.779-30.968C424.172,292.045,401.742,269.615,374.172,269.615z M374.172,334.52
            c-8.219,0-14.905-6.686-14.905-14.905s6.686-14.905,14.905-14.905c8.219,0,14.905,6.686,14.905,14.905
            S382.391,334.52,374.172,334.52z"/>
      </g>
    </svg>
  );
};

export default Locations;
