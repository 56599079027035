import axios, { AxiosResponse } from "axios";
import { formatISO } from "date-fns";
import { SmartTableParam, SmartTableResult } from "../models/smart-table";
import { convertToDateFormat } from '../unit/utils';
import { GeoLocation } from "../types";
import authService from "./auth-service";

export const repairType = {
  SmartRepair: "Smart Repair",
  Lackierung: "Lackierung",
  Werkstatt: "Werkstatt",
  Aufbereitung: "Aufbereitung",
  Twinner: "Twinner",
};

export interface OrderTo {
  id: string;
  number: string | null;
  status: string;
  createdOn: string;
  vehicleManufacturer: string | null;
  vehicleModel: string | null;
  sapNumber: string | null;
  varkauf:string;
}

export interface OrderDetails {
  color: string | null;
  colorCode: string | null;
  geoLocation: GeoLocation | null;
  number: string;
  id: string;
  vehicleId: string;
  vin: string | null;
  startDate: string | null;
  endDate: string | null;
  estimatedDate: string | null;
  status: string;
  vehicleModel: string | null;
  vehicleManufacturer: string | null;
  sapNumber: string | null;
  licensePlate: string | null;
  isRejected: boolean;
  type: string | null;
  orderComment?: string | null;
  varkauf:string;
}

export interface Points{
  value:number,
  text:string
}

export interface AvailabilityInformation {
  type: number;
  contractor: string;
  deliveredDate: string | null;
  repairType: string | null;
  providedDate: string | null;
}

export interface carJockeyParam{
    zielpinkt: number,
    planedDateTime: string,
    vehicleId: string,
    orderId: string
}


enum AvailableFromTypes
{
    /// <summary>
    /// logistic !=null
    /// </summary>
    Delivered = 1,
    /// <summary>
    /// logistic == null && order priority == 1
    /// </summary>
    Provided = 2,
    /// <summary>
    /// logistic ==null && previous service order available
    /// </summary>
    Done = 3
}

export interface EstimateOrderCommand {
  id: string;
  date: Date;
}

class OrderService {
  public async getOrdersAsync(param: SmartTableParam): Promise<SmartTableResult<OrderTo>> {
    const authHeaderValue = await authService.getAuthHeader();
    const response = await axios.post<SmartTableResult<OrderTo>>(
      "/api/orders",
      param,
      {
        headers: {
          Authorization: authHeaderValue,
        },
      }
    );
    return response.data;
  }

  public async getTargetPoints(): Promise<Points[]> {
    const authHeaderValue = await authService.getAuthHeader();
    const response = await axios.get<Points[]>(
      "/api/orders/zielpunkts",
      {
        headers: {
          Authorization: authHeaderValue,
        },
      }
    );

    return response.data
  }

  public async createCarJockey(carJockeyParam:carJockeyParam): Promise<{id: string}> {
    const authHeaderValue = await authService.getAuthHeader();
    const response = await axios.post<{id:string}>(
      "/api/orders/create-carjockey",
      {
        carJokey:carJockeyParam
      },
      {
        headers: {
          Authorization: authHeaderValue,
        },
      }
    );

    return response.data
  }

  public async approveQrCodeValue(OrderId:string):Promise<boolean>{
    const authHeaderValue = await authService.getAuthHeader();
    const response = await axios.post<boolean>(
      "/api/orders/assign-order",
      {
        OrderId
      },
      {
        headers: {
          Authorization: authHeaderValue,
        },
      }
    );

    return response.data
  }

  public async getOrderByIdAsync(id: string): Promise<OrderDetails | null> {
    const authHeaderValue = await authService.getAuthHeader();
    const response = await axios.get("/api/orders", {
      params: { id },
      headers: { Authorization: authHeaderValue },
    });
    return response.data;
  }

  public async startOrderWork(id: string): Promise<OrderDetails> {
    const authHeaderValue = await authService.getAuthHeader();
    const response = await axios.post(
      "/api/orders/start-order",
      {
        id,
      },
      {
        headers: { Authorization: authHeaderValue },
      }
    );

    return response.data;
  }

  public async estimateOrder(
    command: EstimateOrderCommand
  ): Promise<OrderDetails> {
    const authenticationHeaderValue = await authService.getAuthHeader();
    const response = await axios.post(
      "/api/orders/estimate",
      {
        id: command.id,
        date: formatISO(command.date, { representation: "date" }),
      },
      {
        headers: { Authorization: authenticationHeaderValue },
      }
    );

    return response.data;
  }

  public async finishOrderWork(id: string): Promise<OrderDetails> {
    const authHeaderValue = await authService.getAuthHeader();
    const response = await axios.post(
      "/api/orders/finish-order",
      {
        id,
      },
      {
        headers: { Authorization: authHeaderValue },
      }
    );

    return response.data;
  }

  public async getAvailabilityInformation(orderId: string): Promise<string | null> {
    try{
      const authenticationHeaderValue = await authService.getAuthHeader();
      const response:AxiosResponse<AvailabilityInformation> = await axios.get('/api/orders/available-from-info', {
        params: {
          orderId
        },
        headers: { Authorization: authenticationHeaderValue },
      });

      if(!response.data){
        return null;
      }

      if(response.data.type === AvailableFromTypes.Delivered){
        return `Fahrzeug wird geliefert von ${response.data.contractor} am ${convertToDateFormat(response.data.deliveredDate)}`;
      }

      if(response.data.type === AvailableFromTypes.Provided){
        return `Fahrzeug wird bereitgestellt von ${response.data.repairType} am ${convertToDateFormat(response.data.providedDate)}`;
      }

      return "Fahrzeug befindet sich bereits am Standort";
    } catch{
      return null;
    }
  }

  public async rejectOrder(orderId: string): Promise<OrderDetails> {
    const authenticationHeaderValue = await authService.getAuthHeader();
    const response = await axios.post(
      "/api/orders/reject",
      { id: orderId },
      {
        headers: { Authorization: authenticationHeaderValue },
      }
    );

    return response.data;
  }
}

const orderService = new OrderService();
export default orderService;
