import {FunctionComponent, ReactElement} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { VisibilityOff } from '@material-ui/icons';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { SharepointFile } from '../../services/sharepoint-service';
import { imagesExtensions, csvExtension, excelExtensions, pdfExtension, svgExtension, wordExtensions, processingExtension } from '../../unit/utils';
import SvgDocumentIcon from '../../icons/documents-extension-icons/svg-document-icon';
import ImageDocumentIcon from '../../icons/documents-extension-icons/image-document-icon';
import PdfDocumentIcon from '../../icons/documents-extension-icons/pdf-document-icon';
import CsvDocumentIcon from '../../icons/documents-extension-icons/csv-document-icon';
import WordDocumentIcon from '../../icons/documents-extension-icons/word-document-icon';
import ExcelDocumentIcon from '../../icons/documents-extension-icons/excel-document-icon';
import { format , getTime} from 'date-fns';
import {isEmpty} from 'lodash';
import SharepointService from '../../services/sharepoint-service';

const useStyles = makeStyles({
    root: {
      width: 'fit-content',
    },
    first_row_cell:{
      width:40,
    },
    head_cell:{
      maxWidth:150,
      minWidth:40,
    },
    row_cell:{
      maxWidth:150,
      minWidth:40,
    },
    row:{
      height:40,
      cursor:'pointer'
    },
    visibility_off:{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: 40,
      height: 40,
      backgroundColor: '#f2f2f7',
      color: '#6264a7',
      cursor: 'pointer',
      border: '1px solid #babbe0',
      borderRadius: 5,
    }
});

interface RelatedDocumentsProps{
    documents:SharepointFile[];
    maxHeight:number;
};

export const RelatedDocuments:FunctionComponent<RelatedDocumentsProps> = (props):ReactElement=>{
    const {documents , maxHeight} = props;
    const classes = useStyles();

    const getIconByDocumentExtension = (extension:string)=>{
        const ext = processingExtension(extension.slice(1));
        if(imagesExtensions.indexOf(ext) > -1){
           return <ImageDocumentIcon/>;
        }
        else if(svgExtension === ext){
          return <SvgDocumentIcon/>;
        }else if(pdfExtension === ext){
          return <PdfDocumentIcon/>;
        }else if(wordExtensions.indexOf(ext) > -1){
          return <WordDocumentIcon/>;
        }else if(excelExtensions.indexOf(ext) > -1){
          return <ExcelDocumentIcon/>;
        }else if(csvExtension === ext){
          return <CsvDocumentIcon/>;
        }
        return <div className={classes.visibility_off} >
                  <VisibilityOff fontSize="medium"/>
               </div>
    }

    const openDocumentHandler = async (id:string)=>{
         await SharepointService.browseFile(id);
    }

    const compareDesc = (documents:SharepointFile[])=>{
      return documents.sort( (a:SharepointFile, b:SharepointFile)=>{
         return getTime(new Date(a.modifiedOn!)) > getTime(new Date(b.modifiedOn!)) ? -1 : 1;
      })
    }

    const renderTableRows = (documents:SharepointFile[]):JSX.Element | JSX.Element[]=>{
      if(isEmpty(documents)){
        return <TableRow
                className={classes.row}
                tabIndex={-1}
                key={'empty-single-row'}
               >
                <TableCell align='center' colSpan={3}>
                  {'Keine Dokumente gefunden'}
                </TableCell>
              </TableRow>
      }
        return compareDesc(documents).map( (document:SharepointFile) =>
              <TableRow
                className={classes.row}
                hover
                role="checkbox"
                tabIndex={-1}
                key={document.documentId}
                onClick={()=> openDocumentHandler(document.documentId)}
              >
                <TableCell align='center' className={classes.first_row_cell}>
                  {getIconByDocumentExtension(document.extension)}
                </TableCell>
                <TableCell align='center' className={classes.row_cell}>
                  {document.documentType ?? 'keine Daten'}
                </TableCell>
                <TableCell align='center'  className={classes.row_cell}>
                  {document.modifiedOn ? format(new Date(document.modifiedOn), `HH:mm:ss dd.MM.yyyy`) : 'keine Daten'}
                </TableCell>
              </TableRow>
        )
    }

    return(
        <Paper className={classes.root}>
          <TableContainer style={{height:maxHeight - 10}}>
            <Table stickyHeader aria-label="sticky documents table">
                <TableHead>
                <TableRow>
                    <TableCell align='center' className={classes.first_row_cell}>
                    </TableCell>
                    <TableCell align='center' className={classes.head_cell}>
                       Dokumententyp
                    </TableCell>
                    <TableCell align='center' className={classes.head_cell}>
                       Geändert am
                    </TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                  {renderTableRows(documents)}
                </TableBody>
            </Table>
          </TableContainer>
        </Paper>
    )
}