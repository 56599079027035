import {LogistikEditableParams, RampEditableParams} from '../reducers/edit-reducer';

export const SET_LOGISTIK_CHANGES = 'SET_LOGISTIK_CHANGES';
export const SET_RAMP_CHANGES = 'SET_RAMP_CHANGES';
export const SET_ACTIVE_TAB = 'SET_ACTIVE_TAB';


export const setLogistikChanges = (params:LogistikEditableParams)=>({
    type: SET_LOGISTIK_CHANGES,
    payload: params
})

export const setRampChanges = (params:RampEditableParams)=>({
    type: SET_RAMP_CHANGES,
    payload: params
})

export const setActiveTab = (tabIndex:number)=>({
    type:SET_ACTIVE_TAB,
    payload:tabIndex
})