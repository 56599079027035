import axios, { AxiosResponse } from 'axios';
import authService from './auth-service';

export interface UserInfo {
  id: string;
  fullname: string;
  email: string | null;
  logo?: string | null;
  roles: UserRole[];
}

export interface UserRole {
  id: string;
  name: string;
  reparturweg:number;
}

class UserService {
  public async fetchRoles(): Promise<UserInfo> {
    const token = await authService.acquireTokenAsync();
    const response: AxiosResponse<UserInfo> = await axios({
      method: 'GET',
      url: '/api/user',
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  }
}

const userService = new UserService();
export default userService;
