import {useState , FunctionComponent , ReactElement} from 'react';
import {
    Fab,
    useMediaQuery,
    useTheme
  } from "@material-ui/core";
import {ArrowBack , Home} from '@material-ui/icons';
import * as Labels from '../unit/labels';
import history from '../history';

export const BackBtn:FunctionComponent= ():ReactElement=>{
    const [goHomePage , setGoHomePage] = useState<boolean>(false);
    const theme = useTheme();
    const smUp = useMediaQuery(theme.breakpoints.up('sm'));
    const fabSize =  smUp ? 'medium': 'small';

    const backHandler = ()=>{
        const isEntryExist = history.location.key;
        if(isEntryExist){
            history.goBack();
        }else{
            setGoHomePage(true);
        }
    }

    const forwardToHomeHandler = ()=>{
        history.push('/');
    }

    const renderButton = ():JSX.Element=>{
        if(goHomePage){
          return (
            <Fab
              variant="extended"
              size={fabSize}
              aria-label="home"
              onClick={forwardToHomeHandler}
            >
              <Home />
              {Labels.BackHome}
            </Fab>
          )
        }

        return (
            <Fab
                variant="extended"
                size={fabSize}
                aria-label="back"
                onClick={backHandler}
            >
                  <ArrowBack />
                  {Labels.Back}
            </Fab>
            )
    }

    return (
       <>
        {renderButton()}
       </>
    )
}