import {useState , useEffect} from 'react';
import {MqttConnectionData} from '../types';
import {TrackedAssetsIdModel, DeviceIdDataModel} from '../types';
import {MqttClient} from 'mqtt';
import mqtt from 'mqtt';


interface MqttVehicleCoordinates{
  mqttLat:number | null;
  mqttLon:number | null;
  deviation:number;
};

interface AssetProperties{
  site:string | null;
  zone:string | null;
};

type MqttBrokerResponse = MqttVehicleCoordinates
&
{
  mqttClient:MqttClient | null;
  connected:boolean;
} & AssetProperties;


export const useMqttBroker = (url: string , mqttObject:MqttConnectionData | null):MqttBrokerResponse=>{
    const [mqttClient, setMqttClient] = useState<MqttClient | null>(null);
    const [messageResponse, setMessageResponse] = useState<MqttVehicleCoordinates>({mqttLat:null, mqttLon:null, deviation:0});
    const [assetProperties , setAssetProperties] = useState<AssetProperties>({site:null, zone:null});
    const [connected , setConnected] = useState<boolean>(false);

    const connect = (username:string, password:string): void => {
      const connection = mqtt.connect(url, {
        connectTimeout: 5000,
        username,
        password,
        protocol:'wss',
        port:443,
        reconnectPeriod:1000
      });
      setMqttClient(connection);
    };

    useEffect(()=>{
      if(mqttObject && !!url){
        const {login , password} = mqttObject.mqttConfig!;
        connect(login , password);

        return (): void => {
           if (mqttClient) {
            mqttClient.end();
           }
        };
      }
    },[url, mqttObject]);

    useEffect(()=>{
     if(mqttClient && mqttObject){
        const {type , id} = mqttObject;

        mqttClient.on('connect', ()=>{

          if(type === 'asset'){
            mqttClient.subscribe(`thingshub/tracked-assets/${id}`, (err)=>{});
          } else if (type === 'device'){
            mqttClient.subscribe(`thingshub/devices/${id}/data`, (err)=>{});
          }

        });

        mqttClient.on('error', (err:Error | mqtt.ErrorWithReasonCode) => {
            console.error('Error: ', err);
        });

        mqttClient.on('disconnect', (err:mqtt.IDisconnectPacket) => {
          console.log("disconnected");
          setConnected(!mqttClient.disconnected);
        });

        mqttClient.on('end', (error?: Error)=>{
          console.log('end connection');
        });

        mqttClient.on('reconnect', (error?: Error)=>{
          console.log('launch reconnect');
        });


        mqttClient.on('message', (topic:string, message:Buffer) => {
          const responseModel:TrackedAssetsIdModel | DeviceIdDataModel = JSON.parse(message.toString());

          if(topic === `thingshub/tracked-assets/${id}`){
            const {geolocation} = responseModel as TrackedAssetsIdModel;
            if(geolocation){
              const {latitude, longitude, margin, site, zone} = geolocation;
              if(latitude && longitude && margin){
                setMessageResponse(mes => ({...mes, mqttLat:latitude, mqttLon:longitude, deviation:margin}));
                setAssetProperties(prop => ({...prop, site:site ?? '', zone:zone ?? ''}));
              }
            }
          }
          else if(topic === `thingshub/devices/${id}/data`){
            const {properties} = responseModel as DeviceIdDataModel;
            const {latitude, longitude, position_accuracy} = properties;
            if(latitude && longitude && position_accuracy){
              setMessageResponse(mes => ({...mes, mqttLat:latitude, mqttLon:longitude, deviation:position_accuracy}));
            }
          }

        });

        console.log('Mqtt Client connected');
        setConnected(mqttClient.connected);

        const unsubscribe = (): void => {
          if (mqttClient) {
            mqttClient.unsubscribe(
              type === 'asset' ?
              `thingshub/tracked-assets/${id}` :
              `thingshub/devices/${id}/data`
            );
            mqttClient.end();
          }
        };

      return (): void => {
        unsubscribe();
      };
     }
    },[mqttClient]);

    return {
      ...messageResponse,
      ...assetProperties,
      mqttClient,
      connected
    };
}