import { ReactElement, FunctionComponent, SVGProps } from "react";

const Calendar: FunctionComponent<SVGProps<SVGSVGElement>> = (
  props
): ReactElement => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      {...props}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.8437 10.8742C12.7934 10.8233 12.7744 10.7494 12.792 10.68C13.2569 8.84913 12.7812 6.82397 11.369 5.39016C10.0192 4.02845 8.14635 3.52612 6.41025 3.88723C6.26062 3.91836 6.21091 4.10142 6.3183 4.21016L9.09034 7.01687C9.14008 7.06723 9.15949 7.14014 9.14138 7.20858L8.38851 10.0533C8.37022 10.1224 8.31646 10.1765 8.24749 10.1952L5.44015 10.9562C5.37056 10.975 5.2962 10.955 5.24553 10.9037L2.48261 8.10617C2.374 7.9962 2.18821 8.04615 2.15769 8.19766C1.80384 9.95392 2.29931 11.8493 3.64553 13.2141C5.14193 14.7305 7.29507 15.1799 9.18235 14.5673C9.19689 14.5626 9.21288 14.5663 9.2238 14.577V14.577L21.9419 27.4599C22.4452 27.968 23.1046 28.2261 23.7638 28.2261C24.4232 28.2261 25.0816 27.968 25.5859 27.4599C26.5918 26.4439 26.5918 24.7923 25.5859 23.7674L12.8437 10.8742Z"
        fill="#5F6466"
      />
      <path
        d="M18.0729 8.98794C20.1554 6.66826 22.1117 5.99816 24.1015 5.99817C25.8089 6.03146 27.9246 6.72432 29.9529 8.98794"
        stroke="#5F6466"
        stroke-width="1.5"
        stroke-linecap="round"
      />
      <path
        d="M20.5175 11.3631C21.7363 10.191 22.9196 9.80743 24.1174 9.80743C25.2282 9.83595 26.5465 10.3055 27.8756 11.6918"
        stroke="#5F6466"
        stroke-width="1.5"
        stroke-linecap="round"
      />
      <circle cx="24.1966" cy="14.1528" r="1.64147" fill="#5F6466" />
    </svg>
  );
};

export default Calendar;
