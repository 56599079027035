import { FunctionComponent, ReactElement } from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { Drawer, Toolbar, List, useMediaQuery } from "@material-ui/core";
import { useIsAuthenticated } from "@azure/msal-react";
import { useSelector } from "react-redux";
import { RootState } from "../../reducers";
import DrawerListItem, { DrawerListItemLink } from "./drawer-list-item";
import {Roles} from "../../roles";
import { RepairType } from "../../unit/constants";
import AirportShuttleIcon from '@material-ui/icons/AirportShuttle';
import CalendarIcon from '../../icons/menu/calendar';
import SmartRepair from '../../icons/menu/smartRepair';
import Lackierung from '../../icons/menu/lackierung';
import Werkstatt from '../../icons/menu/werkstatt';
import Aufbereitung from '../../icons/menu/aufbereitung';
import Twinner from '../../icons/menu/twinner';
import Logistic from '../../icons/menu/logistic';
import Locations from '../../icons/menu/locations';

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    drawerContainer: {
      overflow: "auto",
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
  })
);

const links: DrawerListItemLink[] = [
  {
    label: "Dekra Kalendar",
    url: "/kalendar",
    icon: <CalendarIcon height={24} width={24} />,
    authenticated: true,
    requiredRoles: [Roles.Admin, Roles.Dekra],
  },
  {
    label: "Smart Repair",
    url: `/auftrage-an/?type=${RepairType.SmartRepair}`,
    icon: <SmartRepair height={24} width={24} />,
    authenticated: true,
    requiredRoles: [Roles.Admin, Roles.SmartRepair],
  },
  {
    label: "Lackierung",
    url: `/auftrage-an/?type=${RepairType.Lackierung}`,
    icon: <Lackierung height={24} width={24} />,
    authenticated: true,
    requiredRoles: [Roles.Admin, Roles.Lackierung],
  },
  {
    label: "Werkstatt",
    url: `/auftrage-an/?type=${RepairType.Werkstatt}`,
    icon: <Werkstatt height={24} width={24} />,
    authenticated: true,
    requiredRoles: [Roles.Admin, Roles.Werkstatt],
  },
  {
    label: "Aufbereitung",
    url: `/auftrage-an/?type=${RepairType.Aufbereitung}`,
    icon: <Aufbereitung height={24} width={24} />,
    authenticated: true,
    requiredRoles: [Roles.Admin, Roles.Aufbereitung],
  },
  {
    label: "Twinner",
    url: `/auftrage-an/?type=${RepairType.Twinner}`,
    icon: <Twinner height={24} width={24} />,
    authenticated: true,
    requiredRoles: [Roles.Admin, Roles.Twinner],
  },
  {
    label: "Logistik",
    url: `/logistik`,
    icon: <Logistic height={24} width={24} />,
    authenticated: true,
    requiredRoles: [Roles.Admin, Roles.LogisticDriver, Roles.LogistikAuftragnehmer]
  },
  {
    label:'Dekra Locations',
    url:'/dekra-locations',
    icon: <Locations height={24} width={24} />,
    authenticated: true,
    requiredRoles: [Roles.Admin, Roles.DekraTeamleiter],
  }
];

interface AppDrawerProps {
  onClose(): void;
  mobileOpen: boolean;
}

const AppDrawer: FunctionComponent<AppDrawerProps> = (props): ReactElement => {
  const { mobileOpen, onClose } = props;
  const mobile = useMediaQuery<Theme>((theme) => theme.breakpoints.down("md"));
  const isAuthenticated = useIsAuthenticated();
  const roles = useSelector<RootState, number[]>(
    (state) => state.profile.roles
  );
  const classes = useStyles();
  return (
    <Drawer
      className={classes.drawer}
      variant={mobile ? "temporary" : "permanent"}
      open={mobileOpen}
      onClose={onClose}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      {!mobile && <Toolbar />}
      <div className={classes.drawerContainer}>
        <List>
          {links.map((link) => (
            <DrawerListItem
              key={link.url}
              isAuthenticated={isAuthenticated}
              link={link}
              userRoles={roles}
            />
          ))}
        </List>
      </div>
    </Drawer>
  );
};

export default AppDrawer;
