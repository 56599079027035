import { ReactElement, FunctionComponent, SVGProps } from "react";

const Werkstatt: FunctionComponent<SVGProps<SVGSVGElement>> = (
  props
): ReactElement => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      {...props}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20.8404 14.4757C20.2258 14.4057 19.5993 14.5586 19.067 14.9184L16.467 12.3259C16.8358 11.7804 16.9928 11.1371 16.9218 10.5076L23.9177 3.42072L27.8346 7.3884L20.8404 14.4757ZM6.61224 23.2926L6.86534 23.5501L11.3061 19.0487L12.4119 20.154L7.96374 24.6609L8.21764 24.922L7.14647 26.2612L3.62378 28.5242L3.05139 27.9452L5.28525 24.379L6.61224 23.2926ZM12.9496 11.0529C13.4632 9.19311 12.9972 7.11429 11.5563 5.65121C10.12 4.20225 8.09147 3.72633 6.26566 4.22832L9.35785 7.35919L8.5462 10.426L5.51477 11.2477L2.42452 8.11882C1.93037 9.9668 2.39921 12.0218 3.83277 13.4751C5.33543 14.9978 7.50034 15.4447 9.39326 14.8206L9.41104 14.838L22.1291 27.721C22.6324 28.2291 23.2918 28.4872 23.9511 28.4872C24.6105 28.4872 25.2689 28.2291 25.7732 27.721C26.7791 26.7049 26.7791 25.0533 25.7732 24.0284L12.9496 11.0529ZM24.1724 27.2831C23.6318 27.2831 23.1928 26.8366 23.1928 26.2859C23.1928 25.7345 23.6318 25.2897 24.1724 25.2897C24.7179 25.2897 25.1586 25.7345 25.1586 26.2859C25.1596 26.8366 24.7188 27.2831 24.1724 27.2831Z"
        fill="#5F6466"
      />
    </svg>
  );
};

export default Werkstatt;
