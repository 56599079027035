import { ReactElement, FunctionComponent, SVGProps } from "react";

const Lackierung: FunctionComponent<SVGProps<SVGSVGElement>> = (
  props
): ReactElement => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      {...props}
    >
      <path
        d="M6.25385 10.1675H21.3355V29.4086C21.3355 29.8464 20.9806 30.2013 20.5428 30.2013H7.04658C6.60877 30.2013 6.25385 29.8464 6.25385 29.4086V10.1675Z"
        fill="#5F6466"
      />
      <path
        d="M16.3201 5.11664L11.2972 5.11664L11.2972 2.59146C11.2972 2.15364 11.6521 1.79872 12.0899 1.79872L15.5274 1.79872C15.9652 1.79872 16.3201 2.15364 16.3201 2.59146L16.3201 5.11664Z"
        fill="#5F6466"
      />
      <path
        d="M9.38398 6.75287L6.96523 8.49579H20.6241L18.2053 6.75287H9.38398Z"
        fill="#5F6466"
      />
      <path
        d="M25.7462 2.52002L18.7389 4.29853L25.7462 6.11258"
        stroke="#5F6466"
        stroke-width="1.13248"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default Lackierung;
