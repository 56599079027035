import { FunctionComponent, ReactElement } from 'react';
import clsx from 'clsx';
import { format, isValid, isSameDay, endOfWeek, startOfWeek, isWithinInterval } from 'date-fns';
import { DatePicker } from '@material-ui/pickers';
import { IconButton, makeStyles, Theme } from '@material-ui/core';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { de } from 'date-fns/locale';

const makeJSDateObject = (date: MaterialUiPickersDate): Date => {
  if (date) {
    return new Date(date.toISOString());
  }

  return new Date();
};

const useStyles = makeStyles((theme: Theme) => ({
  dayWrapper: {
    position: 'relative'
  },
  day: {
    width: 36,
    height: 36,
    fontSize: theme.typography.caption.fontSize,
    margin: '0 2px',
    color: 'inherit'
  },
  customDayHighlight: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: '2px',
    right: '2px',
    border: `1px solid ${theme.palette.secondary.main}`,
    borderRadius: '50%'
  },
  nonCurrentMonthDay: {
    color: theme.palette.text.disabled
  },
  highlightNonCurrentMonthDay: {
    color: '#676767'
  },
  highlight: {
    background: '#eaeaf6',
    color: '#616161'
  },
  firstHighlight: {
    extend: 'highlight',
    borderTopLeftRadius: '50%',
    borderBottomLeftRadius: '50%'
  },
  endHighlight: {
    extend: 'highlight',
    borderTopRightRadius: '50%',
    borderBottomRightRadius: '50%'
  },

  pickerContainer: {
    justifyContent: 'center'
  },

  formControl: {
    '&&&:before': {
      borderBottom: 'none'
    },
    '&&:after': {
      borderBottom: 'none'
    },
    '&::before': {
      border: 'none !important'
    },

    '&:hover::before': {
      border: 'none !important'
    },

    '&:hover::after': {
      border: 'none !important'
    },

    '& input': {
      color: 'rgba(37, 36, 35, 0.75)',
      fontSize: '14px',
      cursor: 'pointer'
    }
  }
}));

interface WeekPickerProps {
  date: Date;
  onDateChange(date: Date): void;
}

const WeekPicker: FunctionComponent<WeekPickerProps> = (props): ReactElement => {
  const { onDateChange, date } = props;
  const classes = useStyles();

  const handleWeekChange = (date: MaterialUiPickersDate) => {
    onDateChange(startOfWeek(makeJSDateObject(date), { locale: de }));
  };

  const formatWeekSelectLabel = (date: MaterialUiPickersDate, invalidLabel: string) => {
    let dateClone = makeJSDateObject(date);
    // TODO: Change to german label
    return dateClone && isValid(dateClone) ? `Week of ${format(startOfWeek(dateClone, { locale: de }), 'MMM do')}` : invalidLabel;
  };

  const renderWrappedWeekDay = (date: MaterialUiPickersDate, selectedDate: MaterialUiPickersDate, dayInCurrentMonth: boolean, dayComponent: JSX.Element) => {
    let dateClone = makeJSDateObject(date);
    let selectedDateClone = makeJSDateObject(selectedDate);

    const start = startOfWeek(selectedDateClone, { locale: de });
    const end = endOfWeek(selectedDateClone, { locale: de });

    const dayIsBetween = isWithinInterval(dateClone, { start, end });
    const isFirstDay = isSameDay(dateClone, start);
    const isLastDay = isSameDay(dateClone, end);

    const wrapperClassName = clsx({
      [classes.highlight]: dayIsBetween,
      [classes.firstHighlight]: isFirstDay,
      [classes.endHighlight]: isLastDay
    });

    const dayClassName = clsx(classes.day, {
      [classes.nonCurrentMonthDay]: !dayInCurrentMonth,
      [classes.highlightNonCurrentMonthDay]: !dayInCurrentMonth && dayIsBetween
    });

    return (
      <div className={wrapperClassName}>
        <IconButton className={dayClassName}>
          <span> {format(dateClone, 'd')} </span>
        </IconButton>
      </div>
    );
  };

  return (
    <DatePicker
      disableToolbar
      className={classes.pickerContainer}
      InputProps={{
        className: classes.formControl
      }}
      variant='inline'
      value={date}
      onChange={handleWeekChange}
      renderDay={renderWrappedWeekDay}
      labelFunc={formatWeekSelectLabel}
    />
  );
};

export default WeekPicker;
