import { FunctionComponent } from 'react';
import { ReactElement } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles, Typography, Fab, useMediaQuery, useTheme } from '@material-ui/core';
import Home  from '@material-ui/icons/Home';
import {BackBtn} from '../components/BackBTn';
import NotFoundIcon from '../assets/svg/not-found-icon.svg';
import * as Labels from '../unit/labels';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'center'
  },
  box:{
    display:'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 10
  },
  actions:{
     display:'flex',
     flexDirection: 'column',
  },
  message:{
    fontSize: 22,
    fontFamily: 'Roboto, Times New Roman , Arial, sans-serif',
    fontWeight:400,
    alignSelf: 'center'
  },
  btns:{
   display: 'flex',
   justifyContent: 'space-between',
   marginTop: 20,
   gap:15
  },
  fab:{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer'
  },
  image_container:{
    width: 200,
    height:200,
    "& img":{
      width: '100%', 
      height: '100%'
    }
  }
});

const NotFoundPage: FunctionComponent = (): ReactElement => {
  const history = useHistory();
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up('sm'))
  const fabSize =  smUp ? 'medium': 'small';
  const classes = useStyles();

  const clickHandler = (): void => {
    history.push('/');
  }

  return (
    <div className={classes.container}>
      <div className={classes.box}>
        <section className={classes.actions}>
          <Typography className={classes.message}>Not Found</Typography>
          <div className={classes.btns}>
            <Fab
              className={classes.fab}
              variant="extended"
              size={fabSize}
              aria-label="home"
              onClick={clickHandler}
            >
               <Home fontSize='medium'/>
               {Labels.BackHome}
            </Fab>
            <BackBtn />
          </div>
        </section>
        <section className={classes.image_container}>
          <img src={NotFoundIcon} alt='Not found'/>
        </section>
      </div>
   </div>
  )
};

export default NotFoundPage;
