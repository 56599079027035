import { FunctionComponent, ReactElement } from "react";
import {
  createStyles,
  Grid,
  makeStyles,
  TextField,
  Theme,
} from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import InputAdornment from "@material-ui/core/InputAdornment";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Lock from "@material-ui/icons/Lock";

interface ItemProps {
  val: number | string | null;
  label: string;
}

const Item: FunctionComponent<ItemProps> = (props): ReactElement => {
  const { val, label } = props;
  return (
    <Grid item xs={12}>
      <TextField
        label={label}
        disabled
        fullWidth
        defaultValue={val}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Lock />
            </InputAdornment>
          ),
        }}
      />
    </Grid>
  );
};

interface AddressProps {
  location: string | null;
  postCode: null | string | number;
  street: null | string;
  title: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    accordion: {
      backgroundColor: "rgb(250, 248, 248)",
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: 300
    }
  })
);

const Address: FunctionComponent<AddressProps> = (props): ReactElement => {
  const { location, postCode, street, title } = props;
  const classes = useStyles();
  return (
    <Accordion className={classes.accordion}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography  className={classes.heading}>{title}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={3}>
          <Item label="Adresse 1: Straße 1" val={street || "---"} />
          <Item label="Adresse 1: Ort" val={location || "---"} />
          <Item label="Adresse 1: Postleitzahl" val={postCode || "---"} />
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default Address;
