import { FunctionComponent, ReactElement } from 'react';
import { CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  container: {
    textAlign: 'center',
    padding: '10px'
  }
})

const InfiniteScrollLoader: FunctionComponent = (): ReactElement => {
  const classes = useStyles();
  return <div className={classes.container}>
    <CircularProgress/>
  </div>;
};

export default InfiniteScrollLoader;
