import { FunctionComponent, ReactElement } from 'react';
import { Backdrop as MaterialBackdrop, CircularProgress, createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { RootState } from '../../reducers';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 5,
      color: '#fff'
    },
    message: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    }
  })
);

const Backdrop: FunctionComponent = (): ReactElement => {
  const { open, message } = useSelector((state: RootState) => state.app.backdrop);
  const classes = useStyles();
  return (
    <MaterialBackdrop className={classes.backdrop} open={open}>
      <div className={classes.message}>
        <CircularProgress color='inherit' />
        <Typography display='block'>{message}</Typography>
      </div>
    </MaterialBackdrop>
  );
};

export default Backdrop;
