import {useEffect , RefObject, useCallback} from 'react';

const useOutsideClick = (timePickerRef:RefObject<HTMLDivElement> | null, currentDay:string, visibilityCallbackHandler:(day:string, open:boolean)=>void)=>{

    const outsideClickHandler = useCallback((e:globalThis.MouseEvent)=>{
        const target = e.target as Node;
        const contain = timePickerRef?.current?.contains(target);
        if(!contain){
          visibilityCallbackHandler(currentDay, false);
        }
        // eslint-disable-next-line
    }, [currentDay, timePickerRef]);


   useEffect(() => {
    const current = timePickerRef?.current;
    if (timePickerRef && current) {
       document.addEventListener('mousedown', outsideClickHandler);
    }
    return (): void => {
      if (timePickerRef && current) {
        document.removeEventListener('mousedown', outsideClickHandler);
      }
    };
  }, [timePickerRef, outsideClickHandler]);

}

export default useOutsideClick;