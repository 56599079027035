import { FunctionComponent, ReactElement, useState } from "react";
import { makeStyles, Button, Drawer, Theme } from "@material-ui/core";
import { createStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import DamageForm from "./DamageForm";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      width: "100%",
      flexShrink: 0,
    },
    drawerPaper: {
      padding: theme.spacing(3),
      width: "100%",
      maxWidth: "500px",
    },
  })
);

interface CreateDamageProps {
  vehicleId: string;
  vin: string;
}

const CreateDamage: FunctionComponent<CreateDamageProps> = (
  props
): ReactElement => {
  const { vehicleId, vin } = props;
  const [open, setOpen] = useState<boolean>(false);
  const classes = useStyles();

  const openHandler = (): void => {
    setOpen(true);
  };

  const closeHandler = (): void => {
    setOpen(false);
  };

  return (
    <>
      <Button startIcon={<AddIcon />} onClick={openHandler}>
        Schaden erstellen
      </Button>
      <Drawer
        className={classes.drawer}
        anchor="right"
        variant={"temporary"}
        open={open}
        onClose={closeHandler}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <DamageForm vehicleId={vehicleId} folderName={vin} onCancel={closeHandler} />
      </Drawer>
    </>
  );
};

export default CreateDamage;
