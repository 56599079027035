import axios from "axios";
import { SmartTableParam , SmartTableResult} from "../models/smart-table";
import authService from "./auth-service";
import {InitialValues} from '../routes/logistic-details/logistic-details';
import {GeoLocation} from '../types';

export interface LogistikRecord {
    id: string,
    name: string,
    loadingPoint: string | null,
    unloadingPoint: string | null,
    status: string | null,
    createdOn: string,
    sapNumber: number | null,
    loadingDate: Date | null | string,
    varkauf:string
}

export interface PointInfo{
    id: string,
    location: null | string,
    name: string,
    postCode: null | string | number,
    state: null | string,
    street: null | string
}

export interface LoadingInfo {
    contactPerson: string | null,
    loadingDate: string | null,
    loadingPoint:PointInfo,
    loadingTime: string,
    loadingTo: string,
    loadingtFrom: string
}

interface ReturnInfo {
    contactPerson: string | null,
    unloadingPoint:PointInfo,
    vehicle: {
        branch: string,
        broad: number,
        curbWeight: number,
        height: number,
        id: string,
        length: number,
        manufacturer: string,
        model: string
    }
}

interface UnloadingInfo{
    contactPerson: null | string,
    unloadingDate: string | null,
    unloadingFrom: string,
    unloadingPoint: PointInfo,
    unloadingTime: string,
    unloadingTo: string
}

export interface Vehicle {
    antrieb: string | null,
    branch: string,
    broad: number,
    curbWeight: number,
    height: number,
    id: string,
    length: number
    manufacturer: string,
    model: string;
    vin: string;
    weight: number;
    varkauf:string;
}


export interface LogistikRecordData{
    geoLocation : GeoLocation | null ,
    additionalDocuments: string | null,
    contractor: string | null,
    costCentre: string | null,
    driver: string | null,
    endDate: string | null,
    id: string,
    isDeliveryReturn: boolean | null,
    isVehicleRoadworthy: boolean | null,
    loadingInfo: LoadingInfo,
    name: string | null,
    returnInfo:ReturnInfo | null,
    startDate: string | null,
    transportType: string | null,
    unloadingInfo: UnloadingInfo,
    vehicle: Vehicle,
    status: string;
}



interface Driver {
  id: string;
  fullname: string;
}

class LogisticService{

    public async getAllRecords(param: SmartTableParam): Promise<SmartTableResult<LogistikRecord>>{
      const authHeader = await authService.getAuthHeader();
      const response = await axios.post(
          'api/logistics',
          param,
          {
            headers: {
              Authorization : authHeader
            }
          }
      )
      return response.data;
    }


    public async getRecordInfoById(id:string):Promise<LogistikRecordData>{
        const authHeader = await authService.getAuthHeader();
        const response = await axios.get(`/api/logistics?Id=${id}`, {
            headers: {
              Authorization: authHeader
            }
        })

        return response.data;
    }

    public async dropDates(id:string):Promise<LogistikRecordData>{
      const authHeader = await authService.getAuthHeader();
      const result = await axios.post(
          '/api/logistics/back-logistic',
          {
            id
          },
          {
            headers: { Authorization: authHeader },
          }
      )

      return result.data;

    }

    public async triggerStartLogistic(id:string):Promise<void>{
        const authHeader = await authService.getAuthHeader();
        await axios.post(
            '/api/logistics/start-logistic',
            {
              id
            },
            {
              headers: { Authorization: authHeader },
            }
        )
    }

    public async triggerStopLogistic(id:string):Promise<void>{
        const authHeader = await authService.getAuthHeader();
        await axios.post(
            '/api/logistics/finish-logistic',
            {
              id
            },
            {
              headers: { Authorization: authHeader },
            }
        )
    }

    public async getDrivers(contractorId: string): Promise<Driver[]>{
      const authorizationHeaderValue = await authService.getAuthHeader();
      const response = await axios.get(
        '/api/logistics/drivers',
        {
          params: {
            contractorId
          },
          headers: { Authorization: authorizationHeaderValue },
        }
      );
      return response.data;
    }

    public async update(id:string, schema: InitialValues):Promise<LogistikRecordData>{
        const authHeader = await authService.getAuthHeader();
        const response = await axios.put(
            `/api/logistics/${id}`,
            schema,
            {
              headers: { Authorization: authHeader },
            }
        )

        return response.data;
    }

}

const logisticService = new LogisticService();
export default logisticService;
