import { FunctionComponent, ReactElement } from "react";
import CreateRamp from './create-ramp';
import { makeStyles } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import EmptyRow from "../../components/EmptyRow";
import RampsRow from './ramp-row';
import {Ramp} from '../../services/dekra-locations-service';
import { isEmpty } from "lodash";

const useStyles = makeStyles({
    drawer_container:{
        display:'flex',
        justifyContent:'flex-end',
        alignItems:'center'
    }
  });

interface RampsPanelProps{
   ramps:Ramp[];
   dekraLocation:string;
   onRefetch():Promise<void>;
   locationId:string;
} 

const RampsPanel:FunctionComponent<RampsPanelProps>=(props):ReactElement=>{
   const {ramps , locationId, dekraLocation, onRefetch } = props;
   const classes = useStyles();

    return (
    <>
    <div className={classes.drawer_container}>
      <CreateRamp onRefetchHandler={onRefetch} id={locationId}/>
    </div>
    <TableContainer>
        <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell>
                  Name
                </TableCell>
                <TableCell align='center'>
                  DEKRA Location
                </TableCell>
                <TableCell align="center">
                  Status
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isEmpty(ramps) && <EmptyRow colSpan={7} />}
              {ramps.map((ramp:Ramp) => (
                <RampsRow key={ramp.id} ramp={ramp} dekraLocation={dekraLocation} locationId={locationId}/>
              ))}
            </TableBody>
        </Table>
    </TableContainer>
    </>
    )
}

export default RampsPanel;