import { FunctionComponent, ReactElement } from 'react';
import { DatePicker, DatePickerProps } from '@material-ui/pickers';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  picker: {
    background: '#ffffff',

    '& div::before': {
      borderBottom: 'none !important'
    },

    '& div::after': {
      borderBottom: 'none !important'
    },
    '& input': {
      padding: '0px 12px 2px',
      height: '32px',
      textOverflow: 'ellipsis'
    }
  }
}));

const DatePickerStyled: FunctionComponent<DatePickerProps> = (props): ReactElement => {
  const classes = useStyles();
  return <DatePicker className={classes.picker} {...props} />;
};

export default DatePickerStyled;
