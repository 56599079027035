import { FunctionComponent, ReactElement } from "react";
import { useHistory } from "react-router-dom";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {Ramp} from '../../services/dekra-locations-service';

import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles({
    row:{
      cursor:'pointer',
      backgroundColor:'#fff',
      '&:hover':{
        backgroundColor:'#f2f1f0',
      }
    }
  });

interface RampRowProps{
    ramp:Ramp,
    dekraLocation:string,
    locationId:string,
}



const RampsRow: FunctionComponent<RampRowProps> = ({ramp, dekraLocation, locationId}): ReactElement => {
  const { id, name, status } = ramp;
  const history = useHistory();
  const classes = useStyles();

  const clickHandler = (): void => {
    history.push(`/ramp/${id}/?locId=${locationId}`);
  };

  return (
    <TableRow role="checkbox" tabIndex={-1} onClick={clickHandler} className={classes.row}>
      <TableCell align='left'>{name}</TableCell>
      <TableCell align="center">{dekraLocation}</TableCell>
      <TableCell align="center">{status}</TableCell>
    </TableRow>
  );
};

export default RampsRow;