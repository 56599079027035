import { ReactElement, FunctionComponent, SVGProps } from "react";

const Aufbereitung: FunctionComponent<SVGProps<SVGSVGElement>> = (
  props
): ReactElement => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      {...props}
    >
      <path
        d="M5.78181 5.10741C5.33034 5.55889 4.6975 5.93309 4.14681 6.20521C3.7392 6.40662 3.71125 7.05933 4.1074 7.28245C4.70207 7.61737 5.40164 8.0635 5.86734 8.5292C6.34986 9.01172 6.81135 9.74528 7.14993 10.3531C7.36631 10.7416 7.99017 10.7262 8.19428 10.3311C8.48967 9.75944 8.89755 9.08277 9.36663 8.6137C9.88206 8.09827 10.7169 7.58799 11.383 7.22434C11.7679 7.01422 11.7578 6.41068 11.3682 6.20958C10.7364 5.88352 9.96093 5.42568 9.4659 4.93065C8.97691 4.44166 8.52419 3.67905 8.19894 3.05155C7.99539 2.65883 7.38267 2.65345 7.17593 3.0445C6.814 3.72914 6.30407 4.58515 5.78181 5.10741Z"
        fill="#5F6466"
      />
      <path
        d="M23.0938 23.7048C22.6423 24.1563 22.0095 24.5305 21.4588 24.8026C21.0512 25.004 21.0233 25.6567 21.4194 25.8799C22.0141 26.2148 22.7137 26.6609 23.1794 27.1266C23.6619 27.6091 24.1234 28.3427 24.4619 28.9505C24.6783 29.339 25.3022 29.3236 25.5063 28.9286C25.8017 28.3568 26.2096 27.6802 26.6786 27.2111C27.1941 26.6957 28.0289 26.1854 28.695 25.8217C29.0799 25.6116 29.0698 25.0081 28.6802 24.807C28.0484 24.4809 27.2729 24.0231 26.7779 23.5281C26.2889 23.0391 25.8362 22.2765 25.511 21.649C25.3074 21.2562 24.6947 21.2509 24.4879 21.6419C24.126 22.3265 23.6161 23.1826 23.0938 23.7048Z"
        fill="#5F6466"
      />
      <path
        d="M19.3236 7.32164C18.4609 8.18433 17.164 8.86204 16.2234 9.28029C15.8079 9.46502 15.7802 10.106 16.1822 10.3184C17.1733 10.8418 18.5823 11.6685 19.4541 12.5403C20.3452 13.4314 21.1893 14.884 21.7105 15.8776C21.917 16.2714 22.5318 16.255 22.7229 15.8535C23.175 14.9028 23.9126 13.5474 24.7909 12.6692C25.724 11.7361 27.3434 10.814 28.4042 10.2639C28.7934 10.0621 28.7825 9.46547 28.3878 9.27451C27.3677 8.78102 25.8476 7.9573 24.9423 7.05205C24.0439 6.1536 23.2257 4.64951 22.731 3.62969C22.538 3.2317 21.9329 3.22574 21.7349 3.62128C21.1885 4.71265 20.2693 6.37593 19.3236 7.32164Z"
        fill="#5F6466"
      />
      <path
        d="M7.49849 17.61C6.36455 18.7439 4.61594 19.616 3.45776 20.1127C3.0399 20.2919 3.01065 20.9282 3.41456 21.1369C4.62613 21.7631 6.51809 22.8397 7.6577 23.9793C8.81775 25.1394 9.91255 27.079 10.5334 28.287C10.7366 28.6825 11.3477 28.6674 11.5343 28.2638C12.0713 27.1022 13.0227 25.2851 14.1712 24.1366C15.3791 22.9287 17.5294 21.736 18.8089 21.084C19.1996 20.8849 19.1885 20.2915 18.7921 20.104C17.5564 19.5193 15.5315 18.4565 14.356 17.281C13.1882 16.1131 12.1315 14.1068 11.5445 12.8691C11.355 12.4694 10.7529 12.4622 10.5578 12.8592C9.91067 14.176 8.72279 16.3857 7.49849 17.61Z"
        fill="#5F6466"
      />
    </svg>
  );
};

export default Aufbereitung;
