import { FunctionComponent, ReactElement } from "react";
import { RouteProps, Route, Redirect } from "react-router-dom";
import { useIsAuthenticated, useMsalAuthentication } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { intersection } from "lodash";
import { loginRequest } from "../services/auth-service";
import { useSelector } from "react-redux";
import Overlay from "./overlay";
import { RootState } from "../reducers";
import { useUserRoles } from '../hooks';

type ProtectedRouteProps = RouteProps & { requiredRoles: number[] };

const ProtectedRoute: FunctionComponent<ProtectedRouteProps> = (
  props
): ReactElement | null=> {
  useMsalAuthentication(InteractionType.Redirect, { ...loginRequest });

  const { requiredRoles, ...rest } = props;
  const isAuthenticated = useIsAuthenticated();
  const roles = useUserRoles();

  const hasAccess =
    !requiredRoles ||
    requiredRoles.length === 0 ||
    intersection(roles, requiredRoles).length > 0;
  const inProgress = useSelector<RootState, boolean>(
    (state) => state.profile.inProgress
  );


  if (!isAuthenticated || inProgress) {
    return <Overlay show />;
  }

  if (isAuthenticated && !inProgress && !hasAccess) {
    return <Redirect to="/access-denied" />;
  }

  return <Route {...rest} />;
};

export default ProtectedRoute;
