import { ReactElement, FunctionComponent } from "react";
import { CarLocation } from "./CarLocation";
import AzureLocationService from '../services/azure-location-service';
import { extractErrorMessage } from "../unit/utils";
import { useErrorPage } from "../hooks";
import { LinearProgress } from "@material-ui/core";
import { useQuery } from "react-query";

interface ConnectionTypeProps{
  vehicleId:string | null;
  errorObserver:(message:string)=> void;
  recordRoute:string;
};

export const DetermineConnectionType:FunctionComponent<ConnectionTypeProps>= (props):ReactElement=>{
    const {vehicleId , recordRoute, errorObserver} = props;
    const navigateToErrorPage = useErrorPage();

    const {isLoading:mqttLoading , isFetching:mqttFetching , data:mqttResult} = useQuery(
        ['get-mqtt-connection-response', vehicleId] ,
        async()=>{
            if(vehicleId){
                return AzureLocationService.determineMqttPresence(vehicleId);
            }
        },
        {
        onError:(error:any)=>{
          const message = extractErrorMessage(error);
          navigateToErrorPage(message.errorMessage , recordRoute, message.statusCode);
        },
        refetchOnWindowFocus:false,
    });

    const {isLoading:liberkeeLoading , isFetching:liberkeeFetching , data:liberkeeResult} = useQuery(
        ['get-liberkee-connection-response'],
        async()=>{
            if(vehicleId){
                return AzureLocationService.getVehicleCoordinates(vehicleId);
            }
        },
        {
        onError:(error:any)=>{
          const message = extractErrorMessage(error);
          navigateToErrorPage(message.errorMessage , recordRoute, message.statusCode);
        },
        refetchOnWindowFocus:false,
        enabled:mqttResult && !mqttResult.mqtt
    });

    if((mqttLoading || mqttFetching) || (liberkeeLoading || liberkeeFetching)){
      return <LinearProgress />;
    }

    const mqttConnectionAvailable = (mqttResult && mqttResult.mqtt);

    return (
    <CarLocation
      errorObserver={errorObserver}
      vehicleId={vehicleId}
      mqttConfig={mqttConnectionAvailable ? mqttResult : null}
      liberkeeConfig={liberkeeResult ?? null}
    />
   );
}