import { useState, useEffect, FunctionComponent, ReactElement, ChangeEvent } from "react";
import { Pagination, SmartTableParam, Search, Sort } from "../../models/smart-table";
import LogisticService , {LogistikRecord} from '../../services/logistic-service';
import InfiniteScroll from "react-infinite-scroll-component";
import InfiniteScrollLoader from "./InfiniteScrollLoader";
import { useErrorPage } from "../../hooks";
import {RepairOrderStatus} from '../../unit/constants';
import EmptyRow from "../../components/EmptyRow";
import Row from './Row';
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Switch from "@material-ui/core/Switch";
import {
  FormControlLabel,
  LinearProgress,
  makeStyles,
  Toolbar,
  Typography
} from "@material-ui/core";
import {getTime, parseISO, format} from 'date-fns';
import { cloneDeep, isEmpty } from "lodash";
import {ArrowUpward , ArrowDownward} from '@material-ui/icons';
import { LogisticsHeaders , LogisticsHeadersView, Position, SortBy} from './headers';
import {BackBtn} from '../../components/BackBTn';
import * as Labels from '../../unit/labels';


const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
  toolbar:{
    display: 'flex',
    justifyContent: 'space-between',
    height: '70px'
  },
  infinite_scroll: {
    position: "relative",
  },
  headerCell:{
     "& div div aside:nth-of-type(even)":{
       visibility: 'hidden',
       transform: 'scale(0)'
     },
    "&:hover div div aside:nth-of-type(even)":{
       visibility: 'visible',
       transform: 'scale(1)',
       transition: '0.3s'
    }
  },
  sortLabel:{
    display: 'flex',
    alignItems: 'center'
 },
});


const getActiveLogisticStatuses = ()=>( [
  RepairOrderStatus.Neu,
  RepairOrderStatus.PlanungErwartet,
  RepairOrderStatus.Geplant,
  RepairOrderStatus.InArbeit
] )

const getInactiveLogisticStatuses = ()=> (
  [
    RepairOrderStatus.Abgeschlossen
  ]
)

const getInitialSmartTableParam = ()=>{
   return new SmartTableParam(
     new Pagination(1, 20),
     new Search({ statuses: getActiveLogisticStatuses()}),
     new Sort()
   )
}

const getDefaultStateForLogistic = ()=>{
  return {
    items:[],
    loading: true
  }
}

const getDefaultStateForConfig = ()=>{
  return {
    totalRecord: 0,
    numberOfPages: 0
  }
}

const getHeadersCells =():LogisticsHeadersView[]=>{
  let cloneCells:LogisticsHeadersView[] = []
  
  for(let i = 0; i < LogisticsHeaders.length; i++){
    let cell = Object.assign({}, {...LogisticsHeaders[i]})
    cloneCells.push(cell)
  }
  return cloneCells
}


const LogisticList: FunctionComponent = (): ReactElement => {
  const [smartTableParam , setSmartTableParam] = useState<SmartTableParam>(getInitialSmartTableParam())
  const [records , setLogisticRecords] = useState<{items:LogistikRecord[], loading: boolean}>(getDefaultStateForLogistic())
  const [recordsConfig, setRecordsConfig] = useState<{totalRecord:number, numberOfPages:number}>(getDefaultStateForConfig())
  const [hasMore, setHasMore] = useState<boolean>(true)
  const [page , setPage] = useState<number>(1)
  const [headerCells , setHeaderCells] = useState<LogisticsHeadersView[]>(getHeadersCells())
  const classes = useStyles()
  const navigateToErrorPage = useErrorPage()


  const getActiveColumn =<Key extends keyof LogistikRecord>(headerCells: LogisticsHeadersView[])=>{
    const {isAsc , sortBy} = headerCells.find( headerCell =>headerCell.selected === true) as LogisticsHeadersView

    return {
      sortBy: sortBy as Key,
      isAsc,
    }

  }

  const splitRecords = <Key extends keyof LogistikRecord>(records: LogistikRecord[], sortBy: Key):LogistikRecord[][] =>{
    let withValue:LogistikRecord[] = []
    let withoutValue:LogistikRecord[] = []
      records.forEach( record =>{
         if(!!record[sortBy]){
          withValue.push(record)
         }else{
          withoutValue.push(record)
         }
      })

      return [withValue, withoutValue];
  }

  const comparator = <Key extends keyof LogistikRecord>(records: LogistikRecord[], sortByColumn: {sortBy:Key , isAsc:boolean}, isSplit?:boolean | undefined):LogistikRecord[]=>{
    const [withValue , withoutValue] = isSplit === false ? [records , []] : splitRecords(records, sortByColumn.sortBy)

    if(sortByColumn.sortBy !== 'loadingDate' && sortByColumn.sortBy !== 'sapNumber'){
      return [...withValue.sort((a, b): number =>(
                (sortByColumn.isAsc ? 
                                  (a[sortByColumn.sortBy] as string).toLowerCase() > (b[sortByColumn.sortBy] as string).toLowerCase()
                                  :
                                  (a[sortByColumn.sortBy] as string).toLowerCase() < (b[sortByColumn.sortBy] as string).toLowerCase()
                                ) ? 1 : -1
             )) , ...withoutValue]
    }
    if(sortByColumn.sortBy === 'loadingDate'){
      return [...withValue.sort((a, b): number =>(

              (sortByColumn.isAsc ? 
                                getTime(parseISO(a[sortByColumn.sortBy] as string)) > getTime(parseISO(b[sortByColumn.sortBy] as string)) 
                                :
                                getTime(parseISO(a[sortByColumn.sortBy] as string)) < getTime(parseISO(b[sortByColumn.sortBy] as string))
                              ) ? 1 : -1
              )), ...withoutValue]
    }
    if(sortByColumn.sortBy === 'sapNumber'){
      return [...withValue.sort((a, b): number =>(

              (sortByColumn.isAsc ? 
                                (a[sortByColumn.sortBy] as number) > (b[sortByColumn.sortBy] as number)
                                :
                                (a[sortByColumn.sortBy] as number) < (b[sortByColumn.sortBy] as number)
                              ) ? 1 : -1
              )), ...withoutValue]
    }

    return records;
  }


  const sortHandler = (data:LogisticsHeadersView[] , sortBy:SortBy )=>{
     const index = data.findIndex( each => each.sortBy === sortBy)
     let cell = Object.assign({}, {...data[index]})
     if(data[index].selected){
        cell.isAsc = !cell.isAsc
        data.splice(index, 1, cell)
        setHeaderCells([...data])
        setLogisticRecords({
          items:comparator(records.items, getActiveColumn(data)), 
          loading:false
        })
        return;
     }
      const updateData = [...data.map( headerCell => {
        if(headerCell.sortBy === sortBy){
           headerCell.selected = true
           headerCell.isAsc = !headerCell.isAsc
           return headerCell;
        }
           headerCell.selected = false
           return headerCell;
       })]
      setHeaderCells([...updateData])
      setLogisticRecords({
        items:comparator(records.items, getActiveColumn(updateData)),
        loading:false
      })
  }



  const pickByDate = (logistics: LogistikRecord[]):LogistikRecord[]=>{
    const [withValue , withoutValue] = splitRecords(logistics, LogisticsHeaders[LogisticsHeaders.length - 1].sortBy as keyof LogistikRecord)

    let index = 0;
    const aggregate = withValue.reduce((accum:LogistikRecord[][], item: LogistikRecord, currentIndex:number):LogistikRecord[][]=> {
       let compareDate = withValue[index].loadingDate as string
       let isEqual = format(parseISO(compareDate as string), 'dd/MM/yyyy') === format(parseISO(item.loadingDate as string)  , 'dd/MM/yyyy')

        if(currentIndex === 0){
          accum.push([{...item}])
        }

        if(currentIndex !== 0 && isEqual){
          accum[accum.length - 1].push({...item})
        }

        if(currentIndex !== 0 && !isEqual){
          index = currentIndex
          accum.push([{...item}])
        }

        return accum

    }, [])

    let sortedRecords:LogistikRecord[]= []

    aggregate.map( records => 
      comparator(records , {sortBy:LogisticsHeaders[2].sortBy as keyof LogistikRecord , isAsc:LogisticsHeaders[2].isAsc} )
    ).forEach( each => sortedRecords.push(...each))

    return [...sortedRecords , ...withoutValue];
  }

  const getLogisticList = async()=>{
    try{
      const records = await LogisticService.getAllRecords(smartTableParam)
      setRecordsConfig( {numberOfPages: records.numberOfPages , totalRecord: records.totalRecord})
      const columnIndex = LogisticsHeaders.length - 1
      setLogisticRecords({
        items: pickByDate(comparator(records.items, {sortBy:LogisticsHeaders[columnIndex].sortBy as keyof LogistikRecord  , isAsc:LogisticsHeaders[columnIndex].isAsc})),
        loading: false
      })
    }catch(e:unknown){
     console.log(e)
     navigateToErrorPage((e as Error).message || Labels.LogisticsError)
    }
 }

  useEffect(()=>{
    setLogisticRecords(getDefaultStateForLogistic())
    setRecordsConfig(getDefaultStateForConfig())
    setHasMore(true)
    getLogisticList()
  }, [smartTableParam])

  useEffect(()=>{
  if(page !== 1){
    const getMoreData = async()=>{
      try{
        const table = cloneDeep(smartTableParam)
        table.pagination.start = page
        const result = await LogisticService.getAllRecords(table)
        const columnIndex = LogisticsHeaders.length - 1
        setLogisticRecords({
          items: pickByDate(
              comparator([...records.items, ...result.items], {sortBy:LogisticsHeaders[columnIndex].sortBy as keyof LogistikRecord  , isAsc:LogisticsHeaders[columnIndex].isAsc})
                            ),
          loading: false
        })
        }catch(err){
          console.log(err)
        }
    }

    getMoreData()
  }
  }, [page])


  const fetchNextRecords = () =>{
    if(page < recordsConfig.numberOfPages){
      setPage( page => page + 1)
    }
    if(page === recordsConfig.numberOfPages){
      setHasMore(false)
    }
  }


  if(records.loading){
    return <LinearProgress />;
  }

  const activeFilterChangeHandler = (event:ChangeEvent<HTMLInputElement>, checked:boolean):void=>{
    setSmartTableParam((smartTableParams) => {
      const clone = cloneDeep(smartTableParams)
      const statuses = checked ? 
         getActiveLogisticStatuses()
                               :
         getInactiveLogisticStatuses()
      clone.search!.predicateObject.statuses = statuses
      return clone
    })
    setPage( page => (page = 1))
  }

  const isActiveFilter =  (smartTableParam.search as unknown as any).predicateObject.statuses.length > 1;

  return (
    <div className={classes.root}>
       <InfiniteScroll
         dataLength={records.items.length}
         loader={<InfiniteScrollLoader key={2} />}
         next={fetchNextRecords}
         hasMore={hasMore}
         endMessage={
          <Typography style={{textAlign: 'center', fontWeight: 500}} variant="subtitle1">
            {Labels.AllRecordsLoadedMessage}
          </Typography>
         }
        >
        <div />
        <TableContainer>
          <Toolbar className={classes.toolbar}>
            <FormControlLabel
              control={
                <Switch
                  checked={isActiveFilter}
                  onChange={activeFilterChangeHandler}
                />
              }
              label={isActiveFilter ? "Aktive" : "Inaktive"}
            />
            <BackBtn />
          </Toolbar>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {
                  headerCells.map( headerCell => {
                    const headerTile = headerCell.name;
                    const position = headerCell?.position as Position;
                    const selected = headerCell.selected;
                    const asc = headerCell.isAsc;

                return <TableCell 
                          align={position} 
                          style={{ minWidth: 170 , cursor: 'pointer'}}
                          className={classes.headerCell}
                          onClick={()=> sortHandler(headerCells , headerCell.sortBy as SortBy)}
                          key={headerCell.name}
                       >
                          <div className={classes.sortLabel} style={{justifyContent: position}}>
                             {headerTile}
                             { isEmpty(records.items) || records.items.length === 1
                                ? ''
                                : 
                                <div>
                                  <aside>
                                    {
                                      selected ?
                                      <div >
                                        { asc ? <ArrowDownward fontSize='medium'/> : <ArrowUpward fontSize='medium'/>}
                                      </div> : 
                                      <div>{''}</div>
                                    }
                                  </aside>
                                  <aside>
                                     {
                                       selected === false ?
                                       <div >
                                          { asc ? <ArrowDownward fontSize="small" style={{ opacity: .3}}/> : <ArrowUpward fontSize="small" style={{ opacity: .3}}/>}
                                      </div> :
                                       <div>{''}</div>
                                     }
                                  </aside>
                                </div>
                             }
                          </div>
                        </TableCell>

                  })
                }
              </TableRow>
            </TableHead>
            <TableBody>
              {(isEmpty(records.items)) && (<EmptyRow colSpan={7} />)}
              {(!isEmpty(records.items)) && 
                (records.items.map((record) => (
                  <Row key={record.id} {...record} />
                )))}
            </TableBody>
          </Table>
        </TableContainer>
        </InfiniteScroll>
    </div>
  );
};

export default LogisticList;
