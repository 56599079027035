import { FunctionComponent, ReactElement } from "react";
import {
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
} from "@material-ui/core";
import { format } from "date-fns";
import sharepointService from "../../services/sharepoint-service";
import ImageIcon from "@material-ui/icons/Image";
import { useQuery } from "react-query";
import { Skeleton } from "@material-ui/lab";
import { imagesExtensions , processingExtension} from "../../unit/utils";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import DescriptionIcon from "@material-ui/icons/Description";

interface DocumentItemProps {
  extension:string;
  modifiedOn: string;
  id: string;
  documentType:string;
};

const DocumentItem: FunctionComponent<DocumentItemProps> = (
  props
): ReactElement => {
  const { extension, id, modifiedOn, documentType } = props;
  const { isLoading, error, data } = useQuery(id, () =>
    sharepointService.fetchFilePreview(id)
  );

  const renderIcon = (): ReactElement => {
    if (isLoading) {
      return <Skeleton variant="circle" width={40} height={40} />;
    }
    if (error || !data || !data?.thumbnail) {
      const ext = processingExtension(extension.slice(1));
      if (ext === 'pdf') {
        return <PictureAsPdfIcon />;
      }
      if (imagesExtensions.indexOf(ext) > -1) {
        return <ImageIcon />;
      }

      return <DescriptionIcon />;
    }

    return <img src={`data:image/png;base64, ${data.thumbnail}`} alt="preview" />;
  };

  const clickHandler = async (): Promise<void> => {
    await sharepointService.browseFile(id);
  };

  return (
    <ListItem onClick={clickHandler} button>
      <ListItemAvatar>
        <Avatar>{renderIcon()}</Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={documentType}
        secondary={format(new Date(modifiedOn), "dd.MM.yyy")}
      />
    </ListItem>
  );
};

export default DocumentItem;
