import { makeStyles, Toolbar, Typography } from "@material-ui/core";
import { ReactNode } from 'react';
import { FunctionComponent, ReactElement } from "react";

interface FormContainerProps {
  title?: string | null;
  rightElement?: ReactNode;
}

const useStyles = makeStyles({
  root: {
    padding: "20px 20px 40px 20px",
    position: 'relative'
  },
  toolbar: {
    paddingLeft: 0,
    paddingRight: 0,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",

    "@media (min-width:600px)": {
      flexDirection: "row",
    },
  },
});

const FormContainer: FunctionComponent<FormContainerProps> = (
  props
): ReactElement => {
  const { title, children, rightElement } = props;
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Toolbar className={classes.toolbar}>
        <Typography variant="h6" gutterBottom>
          {title || "---"}
        </Typography>
        {rightElement || <div /> }
      </Toolbar>
      {children}
    </div>
  );
};

export default FormContainer;
