export const CALENDAR_API_URL: string = process.env.REACT_APP_CALENDAR_API_URL as string;
export const SHAREPOINT_API_URL: string = process.env.REACT_APP_SHAREPOINT_API_URL as string;
export const IDENTITY_SERVER_URL: string = process.env.REACT_APP_IDENTITY_SERVER_URL as string;
export const REDIRECT_URL: string = process.env.REACT_APP_REDIRECT_URL as string;
export const SIGNAL_R_CONNECTION_URL:string = process.env.REACT_APP_SIGNAL_R_CONNECTION_URL as string;
export const INSTANCE:string= process.env.REACT_APP_INSTANCE as string;
export const TENANT:string = process.env.REACT_APP_TENANT as string;
export const SIGN_IN_POLICY:string = process.env.REACT_APP_SIGN_IN_POLICY as string;
export const RESET_PASSWORD_POLICY:string = process.env.REACT_APP_RESET_PASSWORD_POLICY as string;
export const CLIENT_ID:string = process.env.REACT_APP_CLIENT_ID as string;
export const DEKRA_LOCATIONS_URL = process.env.REACT_APP_DEKRA_LOCATIONS_URL as string;
export const AZURE_MAPS_LOCATION = process.env.REACT_APP_AZURE_MAPS_LOCATION as string;
