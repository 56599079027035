import { Action } from '../types';
import { PROFILE_FETCH_SUCCEEDED, SET_PROFILE_LOADING } from '../actions/profile';

export interface Profile {
  logo: string;
  inProgress: boolean;
  roles: number[];
  fullName: string;
  id:string;
}

const INITIAL_STATE: Profile = {
  inProgress: true,
  logo: '',
  roles: [],
  fullName: '',
  id:'',
};

const profileReducer = (state: Profile = INITIAL_STATE, action: Action): Profile => {
  switch (action.type) {
    case PROFILE_FETCH_SUCCEEDED:
      return { ...state, inProgress: false, roles: action.payload.roles, logo: action.payload.logo, fullName:action.payload.name, id:action.payload.userId };
    case SET_PROFILE_LOADING:
      return { ...state, inProgress: false };
    default:
      return state;
  }
};

export default profileReducer;
