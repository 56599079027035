import { FunctionComponent, ReactElement, useState , ChangeEvent} from "react";
import { TextField, Grid } from "@material-ui/core";
import DekraLocationsService , {DekraLocation} from '../../services/dekra-locations-service';


interface LocationPanelProps{
   location:DekraLocation | null;
   onLocationNameSaveHandler(name:string):void;
} 

const LocationPanel:FunctionComponent<LocationPanelProps>=(props):ReactElement=>{ 
   const {location , onLocationNameSaveHandler} = props;
   const [peviousValue , setPreviousValue] = useState<string>('');
   const [currentValue , setCurrentValue] = useState<string>('');

   const clickOutFieldHandler = async ()=>{
     if(currentValue !== peviousValue){
        try{
          await DekraLocationsService.updateLocationName({LocationId:location!.id , Name:currentValue})
          setPreviousValue(currentValue);
          onLocationNameSaveHandler(currentValue);
        }catch(error){
            console.log(error)
        }
     }
   }

   const onChangeHandler = (e:ChangeEvent<HTMLInputElement>)=>{
      const value = e.target.value;
      setCurrentValue(value);
   }

  return (
    <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            label="Name"
            fullWidth
            value={currentValue ? currentValue : location ? location.name : ''}
            onBlur={clickOutFieldHandler}
            onChange={onChangeHandler}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="DEKRA Code"
            fullWidth
            value={location?.dekraCode ?? '---'}
            disabled
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Parent Location"
            fullWidth
            value={location?.parentLocationName ?? '---'}
            disabled
          />
        </Grid>
    </Grid>
  )
}

export default LocationPanel;