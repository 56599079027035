import {CoordsResponse} from '../CarLocation';
import * as Labels from '../../unit/labels';


export type ErrorMessage = {message:string};
export type NavigateResponse = CoordsResponse | ErrorMessage;

interface ObserverState{
    mount: boolean;
    getCoords: ()=> Promise<CoordsResponse | ErrorMessage>;
    wait: ()=>Promise<any>;
    subscribe:(callback:(coords:CoordsResponse | ErrorMessage)=>void)=> Promise<void>;
    unsubscribe: ()=> void;
};

const navigate:Geolocation = navigator.geolocation;

export async function callNavigate ():Promise<CoordsResponse | ErrorMessage>{
    return new Promise( (res:(value:CoordsResponse)=>void , rej:(reason:ErrorMessage)=>void)=> 
        navigate.getCurrentPosition(
            (position:GeolocationPosition)=>(
                res({
                 x:position.coords.latitude,
                 y:position.coords.longitude,
                 position
                })
            )
        ,
        (error:GeolocationPositionError)=>(
            rej({
                message: error.message
            })
        )
        )
    )
}

const wait = ():Promise<any>=>{
  return new Promise((res:(value?:any)=>void, rej)=> setTimeout( ()=> res(), 5000) );
}


export const observeCoordsChanges = ():ObserverState=>{
   const observer = {
       mount: true,
       getCoords: callNavigate,
       wait,
       subscribe:async function(callback:(coords:CoordsResponse | ErrorMessage)=>void){
          if(!this.mount) return
          await this.wait()
          if(!this.mount) return
          try{
            const coordinates:NavigateResponse = await this.getCoords();
            if((coordinates as CoordsResponse).x && (coordinates as CoordsResponse).y) callback((coordinates as CoordsResponse));
          }catch(error:any){
            if(error.message === Labels.DENIED_GEOLOCATION){
              this.unsubscribe();
            }
            return callback(({message:error.message} as ErrorMessage));
          }
          if(!this.mount) return
          await this.wait()
          if(!this.mount) return
            this.subscribe(callback)
       },
       unsubscribe: function(){
         this.mount = false
       }
   }
   return observer;
}