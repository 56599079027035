import { ChangeEvent, FunctionComponent, ReactElement, useState } from "react";
import { CircularProgress, IconButton, makeStyles } from "@material-ui/core";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { head } from "lodash";
import { isImgExtension , readFileAsBase64Async, removeBase64Prefix} from "../../../unit/utils";
import damageService from "../../../services/damage-service";
import {SnackbarProps} from '../../../hoc/withSnackbar';
import SharepointService , { FileModel , SharepointFile} from "../../../services/sharepoint-service";
import * as Labels from '../../../unit/labels';

const useStyles = makeStyles({
  input: {
    display: "none",
  },
});

interface IconButtonForUploadProps extends SnackbarProps{
  vehicleId?: string;
  entityName: string;
  damageId: string;
  onImageIdChange(id: string): void;
};

const IconButtonForUpload: FunctionComponent<IconButtonForUploadProps> = (
  props
): ReactElement => {
  const { damageId, onImageIdChange, vehicleId, entityName, showSnackbar } = props;
  const classes = useStyles();
  const [loading, setLoading] = useState<boolean>(false);

  const transformToUploadFileModel = async (file:File):Promise<FileModel>=>{
    const base64 = await readFileAsBase64Async(file);
    return  {
      id:null,
      folders: [{recordId:vehicleId!, entityName:entityName}],
      document: {
        documentTypeId: 'F1E0BCC8-94AE-4D14-A3FA-0849D631674E',
        comment:'',
        isVisible: true,
        isCustomersPortalVisible: false,
        isEnvelopeCreated: false,
        isSigned: false,
        isSignedWithKabema: false,
      },
      file: {
        body: removeBase64Prefix(base64.body),
        name: base64.name,
      },
      forceUpload: false,
    };
  }

  const changeHandler = async (
    event: ChangeEvent<HTMLInputElement>
  ): Promise<void> => {
    if (loading) {
      return;
    }
    const files = event.target.files;
    const file = head(files);
    if (!file || !isImgExtension(file.name) || !vehicleId) {
      return;
    }

    try {
      setLoading(true);
      const fileModel:FileModel = await transformToUploadFileModel(file);
      const {retainedFile , statusCode} = await damageService.uploadImage(fileModel);
      if(statusCode === 422 || statusCode === 500){
        return showSnackbar(statusCode === 422 ? Labels.DocumentDownloadError : Labels.ContactItSupportError, 'top', 'center');
      }
      let currentFile = retainedFile;
      if(statusCode === 409){
        const { documentId } = currentFile as SharepointFile;
        const {file, ...rest} = fileModel as FileModel;
        const response = await SharepointService.uploadDocument({...rest, id:documentId }, false);
        currentFile = response.retainedFile;
      }
      await damageService.setImageId(damageId, currentFile.documentId);
      onImageIdChange(currentFile.documentId);
      showSnackbar(Labels.FileUploadingSuccess, 'top', 'center');
    } catch (err:any) {
      showSnackbar(Labels.FileUploadingError, 'top', 'center');
    }finally{
      setLoading(false);
    }
  };


  const inputId = `icon-button-file_${damageId}`;
  return (
    <>
      <input
        accept="image/*"
        id={inputId}
        type="file"
        multiple={false}
        className={classes.input}
        onChange={changeHandler}
      />
      <label htmlFor={inputId}>
        <IconButton component="span">
          {loading && <CircularProgress size={20} />}
          {!loading && <CloudUploadIcon />}
        </IconButton>
      </label>
    </>
  );
};

export default IconButtonForUpload;
