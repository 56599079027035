import { FunctionComponent, ReactElement , ReactSVGElement} from 'react';
import { Container, makeStyles, Typography } from '@material-ui/core';
import { useLocation } from 'react-router';
import {LogoutBtn} from '../components/LogoutBtn';

interface LocationState {
  message: string;
  details: string;
}

const useStyles = makeStyles({
  header: {
    fontSize: '22px',
  },
  paper: {
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  box:{
    display: 'flex',
    justifyContent:'space-between',
    alignItems:'center',
  },
  message:{
    maxWidth: 300,
  },
  image_container:{
    width:200,
    height:200,
  },
  divider:{
    height: 10,
  }
});

const AuthenticationErrorIcon:FunctionComponent = ():ReactElement<ReactSVGElement>=>{
  return (
    <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" >
      <path d="M12 2C17.5228 2 22 6.47715 22 12C22 12.6653 21.935 13.3155 21.8111 13.9445C21.5648 13.2859 21.0937 12.7371 20.4911 12.3914C20.497 12.2617 20.5 12.1312 20.5 12C20.5 7.30558 16.6944 3.5 12 3.5C7.30558 3.5 3.5 7.30558 3.5 12C3.5 16.6944 7.30558 20.5 12 20.5C12.6839 20.5 13.349 20.4192 13.9862 20.2667V21.4999C13.9862 21.6012 13.9922 21.701 14.0039 21.7992C13.3565 21.9309 12.6863 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2ZM10.75 13.4393L15.2197 8.96967C15.5126 8.67678 15.9874 8.67678 16.2803 8.96967C16.5466 9.23594 16.5708 9.6526 16.3529 9.94621L16.2803 10.0303L11.2803 15.0303C11.0141 15.2966 10.5974 15.3208 10.3038 15.1029L10.2197 15.0303L7.71967 12.5303C7.42678 12.2374 7.42678 11.7626 7.71967 11.4697C7.98594 11.2034 8.4026 11.1792 8.69621 11.3971L8.78033 11.4697L10.75 13.4393ZM19.75 19.4999C19.75 19.9141 19.4142 20.2499 19 20.2499C18.5858 20.2499 18.25 19.9141 18.25 19.4999C18.25 19.0857 18.5858 18.7499 19 18.7499C19.4142 18.7499 19.75 19.0857 19.75 19.4999ZM17 15.9999H16.4862C15.6578 15.9999 14.9862 16.6715 14.9862 17.4999V21.4999C14.9862 22.3283 15.6578 22.9999 16.4862 22.9999H21.4862C22.3146 22.9999 22.9862 22.3283 22.9862 21.4999V17.4999C22.9862 16.6715 22.3146 15.9999 21.4862 15.9999H21.0003V14.9954C21.0003 13.8907 20.1048 12.9952 19.0001 12.9952C17.8955 12.9952 17 13.8907 17 14.9954V15.9999ZM18 14.9954C18 14.443 18.4478 13.9952 19.0001 13.9952C19.5525 13.9952 20.0003 14.443 20.0003 14.9954V15.9999H18V14.9954ZM15.9862 17.4999C15.9862 17.2237 16.2101 16.9999 16.4862 16.9999H21.4862C21.7623 16.9999 21.9862 17.2237 21.9862 17.4999V21.4999C21.9862 21.776 21.7623 21.9999 21.4862 21.9999H16.4862C16.2101 21.9999 15.9862 21.776 15.9862 21.4999V17.4999Z" fill="#f05d53"/>
    </svg>
  )
};

const AuthenticationError: FunctionComponent = (): ReactElement => {
  const classes = useStyles();
  const { state } = useLocation<LocationState>();

  return (
    <Container className={classes.paper}>
      <div className={classes.box} >
        <section className={classes.message}>
          <Typography align='center' variant='h1' className={classes.header} paragraph >
             Authentication Error
          </Typography>
          <Typography paragraph><strong>Message:</strong>{state?.message}</Typography>
          <Typography><strong>Details:</strong>{state?.details}</Typography>
          <div className={classes.divider} />
          <LogoutBtn />
        </section>
        <section className={classes.image_container}>
          <AuthenticationErrorIcon />
        </section>
      </div>
    </Container>
  );
};

export default AuthenticationError;
