import { FunctionComponent, ReactElement, useState } from 'react';
import { Link } from 'react-router-dom';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { ReactComponent as Logo } from '../../assets/svg/logo.svg';
import { IconButton, useMediaQuery, Avatar, Toolbar, AppBar, Menu, MenuItem } from '@material-ui/core';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import authService from '../../services/auth-service';
import { useBackdrop } from '../../hooks';
import MenuIcon from '@material-ui/icons/Menu';
import { head } from 'lodash';
import PersonIcon from '@material-ui/icons/Person';
import { useSelector } from 'react-redux';
import { RootState } from '../../reducers';
import SignalRHub from '../../services/signalR-service';
import * as Labels from '../../unit/labels';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      background: '#000',
      zIndex: theme.zIndex.drawer + 2
    },
    toolBar: {
      display: 'flex',
      justifyContent: 'space-between'
    },
    menuButton: {
      marginRight: theme.spacing(2)
    },
    title: {
      flexGrow: 1
    },
    logo: {
      height: 26
    }
  })
);

const LoginComponent: FunctionComponent = (): ReactElement => {
  const logo = useSelector<RootState, string>(state => state.profile.logo);
  const isAuthenticated = useIsAuthenticated();
  const { instance } = useMsal();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [showBackdrop] = useBackdrop();

  const loginHandler = async (): Promise<void> => {
    showBackdrop(`${Labels.Login}...`);
    await authService.loginRedirect();
  };

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = (): void => {
    setAnchorEl(null);
  };

  const logoutHandler = async (): Promise<void> => {
    if(SignalRHub.isConnected === 'Connected'){
      await SignalRHub.disconnect();
    }
    showBackdrop(`${Labels.Logout}...`);
    await instance.logout();
  };

  if (!isAuthenticated) {
    return (
      <div>
        <Avatar onClick={handleMenuOpen} className='pointer'>
          <PersonIcon />
        </Avatar>
        <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleMenuClose}>
          <MenuItem onClick={loginHandler}>{Labels.Login}</MenuItem>
        </Menu>
      </div>
    );
  } else {
    const account = instance.getActiveAccount();
    const [name, surname] = account?.name?.split(' ') || ['', ''];

    return (
      <>
        <div>
          <Avatar onClick={handleMenuOpen} className='pointer' src={`data:image/jpeg;base64, ${logo}`}>
            {head(name)}
            {head(surname)}
          </Avatar>
          <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleMenuClose}>
            <MenuItem onClick={logoutHandler}>{Labels.Logout}</MenuItem>
          </Menu>
        </div>
      </>
    );
  }
};

interface HeaderProps {
  onOpen(): void;
}

const Header: FunctionComponent<HeaderProps> = (props): ReactElement => {
  const { onOpen } = props;
  const classes = useStyles();
  const mobile = useMediaQuery<Theme>(theme => theme.breakpoints.down('md'));

  return (
    <AppBar position='fixed' className={classes.appBar}>
      <Toolbar className={classes.toolBar}>
        {mobile && (
          <IconButton color='inherit' aria-label='open drawer' onClick={onOpen} edge='start'>
            <MenuIcon />
          </IconButton>
        )}
        <Link to='/'>
          <Logo className={classes.logo} />
        </Link>
        <LoginComponent />
      </Toolbar>
    </AppBar>
  );
};

export default Header;
