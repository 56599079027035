import { FunctionComponent, ReactElement } from "react";
import { useQuery } from "react-query";
import { Select, MenuItem, FormControl, InputLabel } from "@material-ui/core";

import LogisticService from "../../../services/logistic-service";

interface DriverProps {
  contractorId: string | undefined;
  value: string | null;
  disabled: boolean;
  onChange(id: string): void;
}

const Driver: FunctionComponent<DriverProps> = (props): ReactElement => {
  const { contractorId, value, disabled, onChange } = props;
  const { data: drivers } = useQuery(["dirvers", contractorId], () => {
    if (contractorId && !disabled) {
      return LogisticService.getDrivers(contractorId);
    }

    return Promise.resolve([]);
  }, { refetchOnWindowFocus: false});

  return (
    <FormControl fullWidth>
      <InputLabel id="driver_label">Fahrer</InputLabel>
      <Select
        labelId="driver_label"
        id="driver-select"
        disabled={disabled}
        value={value || ""}
        label="Fahrer"
        onChange={(event:any)=> onChange(event.target.value)}
      >
        {drivers?.map(driver=><MenuItem key={driver.id} value={driver.id}>{driver.fullname}</MenuItem>)}
      </Select>
    </FormControl>
  );
};

export default Driver;
