import { FunctionComponent, ReactElement } from "react";
import { TableCell, TableRow, TableHead } from "@material-ui/core";

interface VehicleDamagesTableHeaderProps {
  showRepairType: boolean;
}

const VehicleDamagesTableHeader: FunctionComponent<VehicleDamagesTableHeaderProps> = (props): ReactElement => {
  const { showRepairType } = props;
  return (
    <TableHead>
      <TableRow>
        <TableCell>Bild</TableCell>
        <TableCell align="center">Bauteil</TableCell>
        <TableCell align="right">Schaden</TableCell>
        <TableCell align="right">Beschreibung</TableCell>
        {showRepairType && <TableCell align="right">Reparaturweg</TableCell>}
      </TableRow>
    </TableHead>
  );
};

export default VehicleDamagesTableHeader;
