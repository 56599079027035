import React from 'react';
import Loader from '../loader';
import classes from './overlay.module.scss';

interface OverlayProps {
  show: boolean;
  noScrollToTop?: boolean;
}

class Overlay extends React.Component<OverlayProps, {}> {
  toggleOverflow = (flag: boolean): void => {
    if (flag) {
      document.body.style.overflow = 'hidden';
      if (!this.props.noScrollToTop) {
        window.scrollTo(0, 0);
      }
    } else {
      document.body.style.overflow = 'auto';
    }
  };

  componentDidMount(): void {
    const { show } = this.props;
    this.toggleOverflow(show);
  }

  componentWillUnmount(): void {
    this.toggleOverflow(false);
  }

  componentDidUpdate(): void {
    const { show } = this.props;
    this.toggleOverflow(show);
  }

  render() {
    const { show } = this.props;

    return show ? (
      <div className={classes.overlay_container}>
        <Loader />
      </div>
    ) : null;
  }
}

export default Overlay;
