export type Position = "left" | "right" | "inherit" | "center" | "justify" | undefined;

export type SortBy = 'name' | 'loadingPoint' | 'unloadingPoint'| 'status' | 'loadingDate' | 'sapNumber';

export interface LogisticsHeadersView {
    sortBy:string,
    isAsc:boolean,
    selected: boolean,
    name: string,
    isNumeric: boolean,
    position?: Position
};

export const LogisticsHeaders:LogisticsHeadersView[] = [
    {
        sortBy: 'name',
        isAsc: false,
        selected: false,
        name: 'LUEG Auftrags-Nr',
        isNumeric: false,
    },
    {
        sortBy: 'sapNumber',
        isAsc: false,
        selected: false,
        name: 'SAP Nummer',
        isNumeric: true,
    },
    {
        sortBy: 'loadingPoint',
        isAsc: true,
        selected: false,
        name: 'Beladestelle',
        isNumeric: false,
    },
    {
        sortBy: 'unloadingPoint',
        isAsc: false,
        selected: false,
        name: 'Entladestelle',
        isNumeric: false,
    },
    {
        sortBy: 'varkauf',
        isAsc: false,
        selected: false,
        name: 'Verkauft',
        isNumeric: false,
    },
    {
        sortBy: 'status',
        isAsc: false,
        selected: false,
        name: 'Statusgrund',
        isNumeric: false,
        position: 'center'
    },
    {
        sortBy: 'loadingDate',
        isAsc: false,
        selected: true,
        name: 'Beladestelle Datum',
        isNumeric: true,
        position: 'right'
    },
];