import { SmartTableParam, SmartTableResult } from "../models/smart-table";
import authService from "./auth-service";
import axios from "axios";
import sharepointService , {FileModel, SharepointFileState} from './sharepoint-service';

/**
 * Entity Logical Name : lueg_schaden
 * Entity Dispalay Name: Schaden
 */
export interface Damage {
  /**
   * lueg_schadenid
   */
  id: string;
  component: string | null;
  damage: string | null;
  description: string | null;
  imageId: string | null;
  purchaseOrder: {
    id: string;
    name: string
  } | null;
  repairType: string | null;
};

export interface CreateDamageCommand {
  vehicleId: string;
  component: string | null;
  damage: string | null;
  description: string | null;
  calculationCost: string | null;
  repairType: string;
  imageId: string | null;
};

class DamageService {
  public async getDamages(
    data: SmartTableParam
  ): Promise<SmartTableResult<Damage>> {
    const authenticationHeaderValue = await authService.getAuthHeader();
    const response = await axios({
      method: "POST",
      url: "/api/damages/list",
      headers: {
        Authorization: authenticationHeaderValue,
      },
      data,
    });

    return response.data;
  }

  public async uploadImage(fileModel:FileModel): Promise<SharepointFileState> {
    const fileInfo = await sharepointService.uploadDocument(fileModel, true);
    return fileInfo;
  }

  /**
   * Set the attribute(lueg_sharepoint_image_id) with value of sharepoint file id. The file is located inside of vehicle(lueg_fahrezeug) folder
   * @param damageId - entity(lueg_schaden) id
   * @param imageId - image id in sharepoint folder of lueg_fahrzeug entity
   */
  public async setImageId(damageId: string, imageId: string): Promise<void> {
    const authenticationHeaderValue = await authService.getAuthHeader();
    await axios.post(
      "/api/damages/set-image-id",
      {
        id: damageId,
        imageId,
      },
      {
        headers: {
          Authorization: authenticationHeaderValue,
        },
      }
    );
  }

  public async create(command: CreateDamageCommand): Promise<{ id: string }> {
    const authenticationHeaderValue = await authService.getAuthHeader();

    const response = await axios.post("/api/damages", command, {
      headers: {
        Authorization: authenticationHeaderValue,
      },
    });

    return response.data;
  }
}
const damageService = new DamageService();
export default damageService;
