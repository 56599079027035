import { ReactElement, FunctionComponent, SVGProps } from "react";

const Logistic: FunctionComponent<SVGProps<SVGSVGElement>> = (
  props
): ReactElement => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      {...props}
    >
      <ellipse cx="7.35455" cy="22.9521" rx="1.8" ry="1.8" fill="#5F6466" />
      <ellipse
        cx="25.5786"
        cy="22.9547"
        rx="1.80273"
        ry="1.80273"
        fill="#5F6466"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.81909 6.6156C1.54295 6.6156 1.31909 6.83946 1.31909 7.1156V21.9677C1.31909 22.2438 1.54295 22.4677 1.81909 22.4677H3.95697C4.05599 22.4677 4.13945 22.395 4.15928 22.2979C4.46324 20.8108 5.77891 19.692 7.35588 19.692C8.93285 19.692 10.2485 20.8108 10.5525 22.2979C10.5723 22.395 10.6558 22.4677 10.7548 22.4677H18.8127C19.0889 22.4677 19.3127 22.2438 19.3127 21.9677V7.1156C19.3127 6.83946 19.0889 6.6156 18.8127 6.6156H1.81909Z"
        fill="#5F6466"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20.4762 10.2427C20.4762 10.1323 20.5658 10.0427 20.6762 10.0427H25.5186C25.5785 10.0427 25.6353 10.0696 25.6733 10.1159L30.6355 16.1658C30.6649 16.2015 30.6809 16.2463 30.6809 16.2926V22.2689C30.6809 22.3793 30.5914 22.4689 30.4809 22.4689H29.0818C28.9828 22.4689 28.8993 22.3961 28.8795 22.2991C28.576 20.8114 27.2601 19.692 25.6827 19.692C24.1053 19.692 22.7893 20.8114 22.4858 22.2991C22.466 22.3961 22.3826 22.4689 22.2835 22.4689H20.6762C20.5658 22.4689 20.4762 22.3793 20.4762 22.2689V10.2427ZM21.6694 11.3726V14.9421C21.6694 15.0526 21.7589 15.1421 21.8694 15.1421H28.0733C28.2436 15.1421 28.336 14.9429 28.226 14.813L25.2056 11.2434C25.1676 11.1985 25.1118 11.1726 25.0529 11.1726H21.8694C21.7589 11.1726 21.6694 11.2621 21.6694 11.3726Z"
        fill="#5F6466"
      />
    </svg>
  );
};

export default Logistic;
