import { useDispatch } from "react-redux";
import { hideBackdrop, showBackdrop } from "../actions/app";

const useBackdrop = (): [(message: string) => void, () => void] => {
  const dispatch = useDispatch();

  const show = (message: string): void => {
    dispatch(showBackdrop(message));
  };

  const hide = (): void => {
    dispatch(hideBackdrop());
  };

  return [show, hide];
};

export default useBackdrop;
