export const admin: string = "Admin";
export const dekra: string = "Dekra";
export const smartRepair: string = "SmartRepair";
export const painting: string = 'Lackierung';
export const workshop: string = 'Werkstatt';
export const processing: string = 'Aufbereitung';
export const twinner: string = 'Twinner';
export const logisticOperator: string = "Logistik - Auftragnehmer";
export const logisticDriver: string = "Logistik - Fahrer";
export const werkstattMechaniker:string = "Werkstatt - Mechaniker";
export const werkstattTeamleiter:string = "Werkstatt - Teamleiter";
export const dekraTeamleiter:string = 'Dekra - Teamleiter';

export enum Roles {
    SmartRepair = 528970001,
    Lackierung = 528970002,
    Werkstatt = 528970003,
    Aufbereitung = 528970004,
    Twinner = 528970005,
    Admin = 1,
    LogistikAuftragnehmer = 2,
    Dekra = 3,
    LogisticDriver = 4,
    DekraTeamleiter = 3
};
