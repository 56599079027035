import { FunctionComponent, ReactElement } from "react";
import { Router, Switch, Route, useLocation } from "react-router-dom";
import history from "../history";
import Home from "./home";
import AppFrame from "../components/AppFrame";
import AuthenticationError from "./authentication-error";
import DekraCalendar from "./dekra-calendar";
import DekraCalendarEvent from "./dekra-calendar-event";
import Orders from "./order-list";
import Order from "./order";
import ErrorPage from "./error";
import ProtectedRoute from "../components/ProtectedRoute";
import NotFoundPage from "./not-found";
import AccessDenied from "./access-denied";
import LogisticDetails from "./logistic-details";
import LogisticList from "./logistic-list";
import DekraLocations from './dekra-locations';
import LocationDetails from './dekra-locations/location-details';
import RampDetails from './dekra-locations/ramp-details';
import useBrowserBackStack from '../hooks/useBrowserStack';
import {InitialState} from '../reducers/edit-reducer';
import {useSelector} from 'react-redux';
import {RootState} from '../reducers';
import {Roles} from "../roles";


const SwitchModel:FunctionComponent = ():ReactElement=>{
    const location = useLocation();
    const backStack = useBrowserBackStack();

    const fromRoute =  backStack.length === 1  ? backStack[0] : backStack[backStack.length - 2];
    const toRoute = backStack.length === 1  ? backStack[0] : backStack[backStack.length - 1];
    const { editableLogistik , editableRamp} = useSelector<RootState, InitialState>((state) => state.edit);
    const {logistikId, changedValue:newLogistikValue} = editableLogistik;
    const {rampId , changedValue:newRampValue} = editableRamp;

    const matchingLogistikRoute = fromRoute?.pathname && /\/logistik\/[a-zA-Z0-9-]{3,}/.test(fromRoute.pathname) && !!Object.keys(newLogistikValue).length;
    const matchingRampRoute = fromRoute?.pathname && /\/ramp\/[a-zA-Z0-9-]{3,}/.test(fromRoute.pathname) && !!Object.keys(newRampValue).length;
    const requirementFulfilled = (fromRoute?.pathname && toRoute?.pathname) && backStack.length >= 2 && fromRoute.pathname !== toRoute.pathname;

    if(matchingRampRoute && fromRoute.state){
      fromRoute.state = undefined;
    }
    if(matchingLogistikRoute && fromRoute.state){
      fromRoute.state = undefined;
    }

    if(matchingLogistikRoute && requirementFulfilled){
      const {pathname , search} = backStack[backStack.length - 1];
      fromRoute.state = {
        changedValue:newLogistikValue ,
        logistikId,
        redirectTo: pathname + search
      };
    }

    if(matchingRampRoute && requirementFulfilled){
      const {pathname , search} = backStack[backStack.length - 1];
      fromRoute.state = {
        changedValue:newRampValue,
        rampId,
        redirectTo: pathname + search
      };
    }

  return(
    <Switch location={ (matchingRampRoute || matchingLogistikRoute) ? fromRoute : location}>

      <Route path="/signin-oidc">
        <div />
      </Route>


      <ProtectedRoute
        requiredRoles={[Roles.Admin, Roles.Dekra]}
        path="/kalendar"
        exact
      >
        <AppFrame>
          <DekraCalendar />
        </AppFrame>
      </ProtectedRoute>

      <ProtectedRoute
        requiredRoles={[Roles.Admin, Roles.Dekra]}
        path="/kalendar/event"
        exact
      >
        <AppFrame>
          <DekraCalendarEvent />
        </AppFrame>
      </ProtectedRoute>

      <ProtectedRoute
        requiredRoles={[]}
        path="/auftrage-an"
        exact
      >
        <AppFrame>
          <Orders />
        </AppFrame>
      </ProtectedRoute>

      <ProtectedRoute
        requiredRoles={[]}
        path="/auftrage-an/:id"
        exact
      >
        <AppFrame>
          <Order />
        </AppFrame>
      </ProtectedRoute>

      <ProtectedRoute requiredRoles={[]}  path="/" exact>
        <AppFrame>
          <Home />
        </AppFrame>
      </ProtectedRoute>


      <Route path="/access-denied" exact>
        <AppFrame>
          <AccessDenied />
        </AppFrame>
      </Route>

      <ProtectedRoute
        requiredRoles={[
          Roles.Admin,
          Roles.DekraTeamleiter
        ]}
        path="/ramp/:id"
        exact
      >
        <AppFrame>
          <RampDetails />
        </AppFrame>
      </ProtectedRoute>

      <ProtectedRoute
        requiredRoles={[
          Roles.Admin,
          Roles.LogisticDriver,
          Roles.LogistikAuftragnehmer
        ]}
        path="/logistik/:id"
        exact
      >
        <AppFrame>
          <LogisticDetails />
        </AppFrame>
      </ProtectedRoute>

      <ProtectedRoute
        requiredRoles={[
          Roles.Admin,
          Roles.DekraTeamleiter
        ]}
        path="/dekra-locations"
        exact
      >
        <AppFrame>
          <DekraLocations/>
        </AppFrame>
      </ProtectedRoute>

      <ProtectedRoute
        requiredRoles={[
          Roles.Admin,
          Roles.DekraTeamleiter
        ]}
        path="/location/:id"
        exact
      >
        <AppFrame>
          <LocationDetails />
        </AppFrame>
      </ProtectedRoute>

      <ProtectedRoute
        requiredRoles={[
          Roles.Admin,
          Roles.LogisticDriver,
          Roles.LogistikAuftragnehmer
        ]}
        path="/logistik"
        exact
      >
        <AppFrame>
          <LogisticList />
        </AppFrame>
      </ProtectedRoute>


      <Route path="/authentication-error" exact>
        <AppFrame>
          <AuthenticationError />
        </AppFrame>
      </Route>

      <Route path="/error" >
        <AppFrame>
          <ErrorPage />
        </AppFrame>
      </Route>

      <Route path="*">
        <AppFrame>
          <NotFoundPage />
        </AppFrame>
      </Route>


  </Switch>
  )
}

const Routes: FunctionComponent = (): ReactElement => {

  return (
    <Router history={history}>
      <SwitchModel />
    </Router>
  );
};

export default Routes;
