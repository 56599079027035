import { Action } from '../types';
import { Location , DefaultLocationResponse , Services} from '../services/calendar-service';
import { Task } from 'calendar/dist';

export const SET_CALENDAR_CONFIG = 'SET_CALENDAR_CONFIG';
export const SET_ACTIVE_LOCATION = 'SET_ACTIVE_LOCATION';
export const SET_DEFAULT_LOCATION = 'SET_DEFAULT_LOCATION';
export const SET_WEEK_START_DATE = 'SET_WEEK_START_DATE';
export const SET_EVENTS = 'SET_EVENTS';

export const setCalendarConfigData = (locations:Location[], activeLocation:Location, defaultLocation:DefaultLocationResponse | null, services:Services[]):Action =>({
   type:SET_CALENDAR_CONFIG,
   payload:{
    locations,
    activeLocation,
    defaultLocation,
    services
   }
})

export const setActiveLocation = (activeLocation:Location | null)=>({
    type:SET_ACTIVE_LOCATION,
    payload:{
        activeLocation
    }
})

export const setDefaultLocation = (defaultLocation:DefaultLocationResponse)=>({
    type:SET_DEFAULT_LOCATION,
    payload:{
        defaultLocation
    }
})

export const setWeekStartDate = (weekStartDate:Date)=>({
    type:SET_WEEK_START_DATE,
    payload:{
        weekStartDate
    }
})

export const setEvents = (events:Task[])=>({
    type:SET_EVENTS,
    payload:{
      events
    }
})