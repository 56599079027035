import {
  FormControlLabel,
  LinearProgress,
  makeStyles,
  Toolbar,
  Typography
} from "@material-ui/core";
import { FunctionComponent, ReactElement, useEffect, useRef } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useState } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Switch from "@material-ui/core/Switch";
import orderService, { OrderTo } from "../../services/order-service";
import { Pagination, SmartTableParam, Search } from "../../models/smart-table";
import InfiniteScrollLoader from "./InfiniteScrollLoader";
import Row from "./Row";
import { ChangeEvent } from "react";
import { cloneDeep, isEmpty } from "lodash";
import EmptyRow from "../../components/EmptyRow";
import { RepairOrderStatus } from "../../unit/constants";
import { useErrorPage } from "../../hooks";
import * as Labels from '../../unit/labels';
import {BackBtn} from '../../components/BackBTn';


const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
  tollbar:{
    display: 'flex',
    justifyContent: 'space-between',
    height: '70px'
  },
  infinite_scroll: {
    position: "relative",
  },
  plug:{},
  highlight_red:{
    backgroundColor: '#f79c9c'
  }
});

interface OrdersState {
  loading: boolean;
  items: OrderTo[];
}

interface OrdersProps {
  type: number;
}

const getActiveOrderStatuses = () => [
  RepairOrderStatus.New,
  RepairOrderStatus.InProgress,
  RepairOrderStatus.Scheduled,
  RepairOrderStatus.AuftragPlanungErwartet
];
const getInactiveOrderStatuses = () => [RepairOrderStatus.Closed];

const getSmartTableDefaultState = (type: number) =>
  new SmartTableParam(
    new Pagination(1, 500),
    new Search({ repairType: type, statuses: getActiveOrderStatuses() })
  );

const getOrdersDefaultState = () => ({
  loading: true,
  items: [],
});

const getDefaultStateForConfig = ()=>({
  totalRecords:0,
  numberOfPages:0
})

const Orders: FunctionComponent<OrdersProps> = (props): ReactElement => {
  const { type } = props;
  const classes = useStyles();
  const navigateToErrorPage = useErrorPage();
  // TODO: Create hook for smartTable
  const [smartTableParam, setSmartTableParam] = useState<SmartTableParam>(
    getSmartTableDefaultState(type)
  );
  const [orders, setOrders] = useState<OrdersState>(getOrdersDefaultState());
  const [ordersConfig, setOrdersConfig] = useState<{totalRecords:number, numberOfPages:number}>(getDefaultStateForConfig());
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [page , setPage] = useState<number>(1);
  // TODO: Create first mount hook
  const didMount = useRef(false);

  
  useEffect(() => {
    if (!didMount.current) {
      didMount.current = true;
    } else {
      setSmartTableParam((smartTableParam) => {
        const result = cloneDeep(smartTableParam);
        result.search!.predicateObject.repairType = type;
        return result;
      });
    }
  }, [type, setSmartTableParam]);

  useEffect(() => {
    setOrders(getOrdersDefaultState());
    setOrdersConfig(getDefaultStateForConfig());
    setHasMore(true);

    const load = async () => {
      try {
        const result = await orderService.getOrdersAsync(smartTableParam);
        setOrdersConfig(config => ({...config, totalRecords:result.totalRecord , numberOfPages: result.numberOfPages}))
        setOrders({
          loading: false,
          items: result.items,
        });
      } catch (err:unknown) {
        navigateToErrorPage((err as Error).message);
      }
    };

    load();
  }, [navigateToErrorPage, smartTableParam]);

  
  const fetchNextRecords = () =>{
    if(page < ordersConfig.numberOfPages){
      setPage( page => page + 1)
    }
    if(page === ordersConfig.numberOfPages){
      setHasMore(false)
    } 
  }

  useEffect(()=>{
    if(page !== 1){
        const getMoreData = async()=>{
           try{
             const table = cloneDeep(smartTableParam)
             table.pagination.start = page
             const result = await orderService.getOrdersAsync(table);
             setOrders((orders) => ({
              loading: false,
              items: [...orders.items, ...result.items],
            }));
           }catch(err){
              console.log(err)
           }
        }

        getMoreData();
    }
  }, [page])


  if (orders.loading) {
    return <LinearProgress />;
  }

  const activeFilterChangeHandler = (
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ): void => {
    setSmartTableParam((smartTableParam) => {
      const result = cloneDeep(smartTableParam);
      const statuses = checked
        ? getActiveOrderStatuses()
        : getInactiveOrderStatuses();
      result.search!.predicateObject.statuses = statuses;
      return result;
    });
    setPage( page => (page = 1))
  };

  const isActiveFilter =
    (smartTableParam.search as unknown as any).predicateObject.statuses.length >
    1;

  return (
    <div className={classes.root}>
      <InfiniteScroll
        dataLength={orders.items.length}
        loader={<InfiniteScrollLoader key={2} />}
        next={fetchNextRecords}
        hasMore={hasMore}
        endMessage={
          <Typography style={{textAlign: 'center', fontWeight: 500}} variant="subtitle1">
            {Labels.AllRecordsLoadedMessage}
          </Typography>
        }
      >
        <div />
        <TableContainer>
          <Toolbar className={classes.tollbar}>
            <FormControlLabel
              control={
                <Switch
                  checked={isActiveFilter}
                  onChange={activeFilterChangeHandler}
                />
              }
              label={isActiveFilter ? "Aktive" : "Inaktive"}
            />
            <BackBtn />
          </Toolbar>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell style={{ minWidth: 170 }}>
                  LUEG Auftrags-Nr
                </TableCell>
                <TableCell style={{ minWidth: 170 }}>
                  SAP Nummer
                </TableCell>
                <TableCell>Hersteller</TableCell>
                <TableCell>Verkaufsbezeichnung</TableCell>
                <TableCell>Verkauft</TableCell>
                <TableCell style={{ minWidth: 100 }}>Statusgrund</TableCell>
                <TableCell align="right" style={{ minWidth: 170 }}>
                  Erstellt am
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isEmpty(orders.items) && <EmptyRow colSpan={7} />}
              {orders.items.map((order) => (
                <Row key={order.id} order={order} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </InfiniteScroll>
    </div>
  );
};

export default Orders;
