import { FunctionComponent, ReactElement } from "react";
import {
  TableCell,
  TableRow,
  Typography,
  TableCellProps,
} from "@material-ui/core";
import * as Labels from "../unit/labels";

const EmptyRow: FunctionComponent<TableCellProps> = (
  props
): ReactElement | null => {
  return (
    <TableRow>
      <TableCell align="center" {...props}>
        <Typography>{Labels.NoDataAvailable}</Typography>
      </TableCell>
    </TableRow>
  );
};

export default EmptyRow;
